@use "../components/mixins";
@use "../components/variables";

//Tools
.d {
	&-flex {
		display: flex;
	}
	&-inline-flex {
		display: inline-flex;
	}
	&-block {
		display: block;
	}
	&-inline-block {
		display: inline-block;
	}
	&-inline {
		display: inline;
	}
	&-none {
		display: none;
	}
	&-mh {
		@include mixins.bbetween(xs, md) {
			&-flex {
				display: flex;
			}
			&-inline-flex {
				display: inline-flex;
			}
			&-block {
				display: block;
			}
			&-inline-block {
				display: inline-block;
			}
			&-inline {
				display: inline;
			}
			&-none {
				display: none;
			}
		}
	}
	&-mv {
		@include mixins.bdown(xs) {
			&-flex {
				display: flex;
			}
			&-inline-flex {
				display: inline-flex;
			}
			&-block {
				display: block;
			}
			&-inline-block {
				display: inline-block;
			}
			&-inline {
				display: inline;
			}
			&-none {
				display: none;
			}
		}
	}
	&-r,
	&-dt {
		@include mixins.bup(md) {
			&-flex {
				display: flex;
			}
			&-inline-flex {
				display: inline-flex;
			}
			&-block {
				display: block;
			}
			&-inline-block {
				display: inline-block;
			}
			&-inline {
				display: inline;
			}
			&-none {
				display: none;
			}
		}
	}
	&-ls {
		@include mixins.bup(ls) {
			&-flex {
				display: flex;
			}
			&-inline-flex {
				display: inline-flex;
			}
			&-block {
				display: block;
			}
			&-inline-block {
				display: inline-block;
			}
			&-inline {
				display: inline;
			}
			&-none {
				display: none;
			}
		}
	}
}
.p {
	&-static {
		position: static;
	}
	&-relative {
		position: relative;
	}
	&-absolute {
		position: absolute;
	}
	&-sticky {
		position: sticky;
	}
}
.border {
	border-width: 0;
	border-style: solid;
	border-color: variables.$black;

	&-all {
		&-width {
			@for $i from 0 through 10 {
				&-#{$i} {
					border-width: #{$i}px;
				}
			}
		}
	}
	&-top {
		&-width {
			@for $i from 0 through 10 {
				&-#{$i} {
					border-top-width: #{$i}px;
				}
			}
		}
	}
	&-bottom {
		&-width {
			@for $i from 0 through 10 {
				&-#{$i} {
					border-bottom-width: #{$i}px;
				}
			}
		}
	}
	&-left {
		&-width {
			@for $i from 0 through 10 {
				&-#{$i} {
					border-left-width: #{$i}px;
				}
			}
		}
	}
	&-right {
		&-width {
			@for $i from 0 through 10 {
				&-#{$i} {
					border-right-width: #{$i}px;
				}
			}
		}
	}
	&-color {
		&-yellow {
			border-color: variables.$yellow;
		}
		&-purple {
			border-color: variables.$purple;
		}
		&-white {
			border-color: variables.$white;
		}
		&-beige {
			border-color: variables.$beige;
		}
		&-gray {
			border-color: variables.$gray;
		}
		&-black {
			border-color: variables.$black;
		}
		&-black-alt {
			border-color: variables.$blackalt;
		}
		&-base {
			border-color: variables.$base;
		}
		&-red {
			border-color: variables.$red;
		}
		&-orange {
			border-color: variables.$orange;
		}
		&-scarlet {
			border-color: variables.$scarlet;
		}
		&-green {
			border-color: variables.$green;
		}
		&-blue {
			border-color: variables.$blue;
		}
		&-pink {
			border-color: variables.$pink;
		}
	}
	&-style {
		&-dotted {
			border-style: dotted;
		}
		&-dashed {
			border-style: dashed;
		}
		&-solid {
			border-style: solid;
		}
		&-double {
			border-top-style: double;
			border-bottom-style: double;
			border-left-style: double;
			border-right-style: double;
		}
	}
	&-radius {
		&-4 {
			border-radius: 4px;
		}
		&-5 {
			border-radius: 5px;
		}
		&-6 {
			border-radius: 6px;
		}
		&-7 {
			border-radius: 7px;
		}
		&-8 {
			border-radius: 8px;
		}
		&-9 {
			border-radius: 9px;
		}
		&-10 {
			border-radius: 10px;
		}
		&-rounded {
			border-radius: 50%;
		}
	}
}
.flex {
	&-aic {
		align-items: center;
	}
	&-ait {
		align-items: flex-start;
	}
	&-aib {
		align-items: flex-end;
	}
	&-jcs {
		justify-content: flex-start;
	}
	&-jce {
		justify-content: flex-end;
	}
	&-jcc {
		justify-content: center;
	}
	&-jcsb {
		justify-content: space-between;
	}
	&-jcsa {
		justify-content: space-around;
	}
	&-jcse {
		justify-content: space-evenly;
	}
	&-direction {
		&-column {
			flex-direction: column;
			&-reverse {
				flex-direction: column-reverse;
			}
		}
		&-row {
			flex-direction: row;
			&-reverse {
				flex-direction: row-reverse;
			}
		}
		&-dt {
			@include mixins.bup(md) {
				&-column {
					flex-direction: column;
					&-reverse {
						flex-direction: column-reverse;
					}
				}
				&-row {
					flex-direction: row;
					&-reverse {
						flex-direction: row-reverse;
					}
				}
			}
		}
		&-ls {
			@include mixins.bup(ls) {
				&-column {
					flex-direction: column;
					&-reverse {
						flex-direction: column-reverse;
					}
				}
				&-row {
					flex-direction: row;
					&-reverse {
						flex-direction: row-reverse;
					}
				}
			}
		}
		&-mh {
			@include mixins.bbetween(xs, md) {
				&-column {
					flex-direction: column;
					&-reverse {
						flex-direction: column-reverse;
					}
				}
				&-row {
					flex-direction: row;
					&-reverse {
						flex-direction: row-reverse;
					}
				}
			}
		}
		&-mv {
			@include mixins.bdown(xs) {
				&-column {
					flex-direction: column;
					&-reverse {
						flex-direction: column-reverse;
					}
				}
				&-row {
					flex-direction: row;
					&-reverse {
						flex-direction: row-reverse;
					}
				}
			}
		}
	}
	&-wrap {
		flex-wrap: wrap;
	}
	&-mh {
		@include mixins.bbetween(xs, md) {
			&-jcse {
				justify-content: space-evenly;
			}
		}
	}
	&-mv {
		@include mixins.bdown(xs) {
			&-jcse {
				justify-content: space-evenly;
			}
		}
	}
}
.fg-1 {
	flex-grow: 1;
}
.text {
	&-left {
		text-align: left;
	}
	&-center {
		text-align: center;
	}
	&-right {
		text-align: right;
	}
	&-justify {
		text-align: justify;
	}
	&-uc {
		text-transform: uppercase;
	}
	&-lc {
		text-transform: lowercase;
	}
	&-cz {
		text-transform: capitalize;
	}
	&-r,
	&-dt {
		@include mixins.bup(md) {
			&-left {
				text-align: left;
			}
			&-center {
				text-align: center;
			}
			&-right {
				text-align: right;
			}
			&-justify {
				text-align: justify;
			}
		}
	}
	&-ls {
		@include mixins.bup(ls) {
			&-left {
				text-align: left;
			}
			&-center {
				text-align: center;
			}
			&-right {
				text-align: right;
			}
			&-justify {
				text-align: justify;
			}
		}
	}
	&-mh {
		@include mixins.bbetween(xs, md) {
			&-left {
				text-align: left;
			}
			&-center {
				text-align: center;
			}
			&-right {
				text-align: right;
			}
			&-justify {
				text-align: justify;
			}
		}
	}
	&-mv {
		@include mixins.bdown(xs) {
			&-left {
				text-align: left;
			}
			&-center {
				text-align: center;
			}
			&-right {
				text-align: right;
			}
			&-justify {
				text-align: justify;
			}
		}
	}
}
.float {
	&-container {
		&:after {
			content: "";
			display: block;
			clear: both;
		}
	}
	&-left {
		float: left;
	}
	&-right {
		float: right;
	}
	&-dt {
		@include mixins.bup(md) {
			&-left {
				float: left;
			}
			&-right {
				float: right;
			}
		}
	}
	&-ls {
		@include mixins.bup(ls) {
			&-left {
				float: left;
			}
			&-right {
				float: right;
			}
		}
	}
	&-mh {
		@include mixins.bbetween(xs, md) {
			&-left {
				float: left;
			}
			&-right {
				float: right;
			}
		}
	}
	&-mv {
		@include mixins.bdown(xs) {
			&-left {
				float: left;
			}
			&-right {
				float: right;
			}
		}
	}
}
.font-size {
	&-xs {
		font-size: 60%;
	}

	&-sm {
		font-size: 80%;
	}
	&-n {
		font-size: 100%;
	}

	&-l {
		font-size: 125%;
	}

	&-xl {
		font-size: 150%;
	}

	&-xxl {
		font-size: 200%;
	}

	&-xxxl {
		font-size: 300%;
	}

	&-xxxxl {
		font-size: 400%;
	}

	&-xxxxxl {
		font-size: 500%;
	}
	&-dt {
		@include mixins.bup(md) {
			&-xs {
				font-size: 60%;
			}

			&-sm {
				font-size: 80%;
			}
			&-n {
				font-size: 100%;
			}

			&-l {
				font-size: 125%;
			}

			&-xl {
				font-size: 150%;
			}

			&-xxl {
				font-size: 200%;
			}

			&-xxxl {
				font-size: 300%;
			}

			&-xxxxl {
				font-size: 400%;
			}

			&-xxxxxl {
				font-size: 500%;
			}
		}
	}
	&-ls {
		@include mixins.bup(ls) {
			&-xs {
				font-size: 60%;
			}

			&-sm {
				font-size: 80%;
			}
			&-n {
				font-size: 100%;
			}

			&-l {
				font-size: 125%;
			}

			&-xl {
				font-size: 150%;
			}

			&-xxl {
				font-size: 200%;
			}

			&-xxxl {
				font-size: 300%;
			}

			&-xxxxl {
				font-size: 400%;
			}

			&-xxxxxl {
				font-size: 500%;
			}
		}
	}
	&-mh {
		@include mixins.bbetween(xs, md) {
			&-xs {
				font-size: 60%;
			}

			&-sm {
				font-size: 80%;
			}
			&-n {
				font-size: 100%;
			}

			&-l {
				font-size: 125%;
			}

			&-xl {
				font-size: 150%;
			}

			&-xxl {
				font-size: 200%;
			}

			&-xxxl {
				font-size: 300%;
			}

			&-xxxxl {
				font-size: 400%;
			}

			&-xxxxxl {
				font-size: 500%;
			}
		}
	}
	&-mv {
		@include mixins.bdown(xs) {
			&-xs {
				font-size: 60%;
			}

			&-sm {
				font-size: 80%;
			}
			&-n {
				font-size: 100%;
			}

			&-l {
				font-size: 125%;
			}

			&-xl {
				font-size: 150%;
			}

			&-xxl {
				font-size: 200%;
			}

			&-xxxl {
				font-size: 300%;
			}

			&-xxxxl {
				font-size: 400%;
			}

			&-xxxxxl {
				font-size: 500%;
			}
		}
	}
}
.line-height {
	&-inherit {
		line-height: inherit;
	}
	&-normal,
	&-n {
		line-height: normal;
	}
	&-60 {
		line-height: 60%;
	}
	&-80 {
		line-height: 80%;
	}
	&-100 {
		line-height: 100%;
	}
	&-125 {
		line-height: 125%;
	}
	&-150 {
		line-height: 150%;
	}
	&-200 {
		line-height: 200%;
	}
}
.ls {
	&-min {
		letter-spacing: -0.1em;
	}
	&-condenced {
		letter-spacing: -0.05em;
	}
	&-wide {
		letter-spacing: 0.05em;
	}
	&-max {
		letter-spacing: 0.1em;
	}
}
.hidden {
	display: none !important;
	&-mobile {
		@include mixins.bdown(md) {
			display: none !important;
		}
	}
	&-desktop {
		@include mixins.bup(md) {
			display: none !important;
		}
	}
}
.visibility {
	&-on {
		visibility: visible;
	}
	&-off {
		visibility: hidden;
	}
}
.overflow {
	&-visible {
		overflow: visible;
	}
	&-scroll {
		overflow: scroll;
	}
	&-hidden {
		overflow: hidden;
	}
}
.m {
	&-auto {
		margin-left: auto;
		margin-right: auto;
	}
	&l-auto {
		margin-left: auto;
	}
	&r-auto {
		margin-right: auto;
	}
	&-dt-auto {
		@include mixins.bup(md) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&l-dt-auto {
		@include mixins.bup(md) {
			margin-left: auto;
		}
	}
	&r-dt-auto {
		@include mixins.bup(md) {
			margin-right: auto;
		}
	}
	&-ls-auto {
		@include mixins.bup(ls) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&l-ls-auto {
		@include mixins.bup(ls) {
			margin-left: auto;
		}
	}
	&r-ls-auto {
		@include mixins.bup(ls) {
			margin-right: auto;
		}
	}
	&-mh-auto {
		@include mixins.bbetween(xs, md) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&l-mh-auto {
		@include mixins.bbetween(xs, md) {
			margin-left: auto;
		}
	}
	&r-mh-auto {
		@include mixins.bbetween(xs, md) {
			margin-right: auto;
		}
	}

	&-mv-auto {
		@include mixins.bdown(xs) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&l-mv-auto {
		@include mixins.bdown(xs) {
			margin-left: auto;
		}
	}
	&r-mv-auto {
		@include mixins.bdown(xs) {
			margin-right: auto;
		}
	}
}
.no-bg {
	background-image: none !important;
}
.underline {
	text-decoration: underline;
}
.shadow {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	&-small {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	}
	&-big {
		box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
	}
}
.pr {
	position: relative;
}
.bold {
	font-weight: 700;
}
.media-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	iframe {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}
}
.object-fit {
	&-cover {
		object-fit: cover;
	}
	&-contain {
		object-fit: contain;
	}
}
.clearfix {
	&:after {
		display: table;
		content: "";
		clear: both;
	}
}
.white-space {
	&-nowrap {
		white-space: nowrap;
	}
	&-normal {
		white-space: normal;
	}
	&-pre {
		white-space: pre;
	}
}
.word-break {
	&-normal {
		word-break: normal;
	}
	&-all {
		word-break: break-all;
	}
	&-word {
		word-break: break-word;
	}
}
.bfv {
	&-hidden {
		backface-visibility: hidden;
	}
	&-visible {
		backface-visibility: visible;
	}
}
.acc {
	&-content {
		display: none;
	}
	.btn-acc {
		span {
			&:first-child {
				display: inline-block;
			}
			&:last-child {
				display: none;
			}
		}
	}
	&-opened {
		.acc-content {
			display: block;
		}
		.btn-acc {
			span {
				&:first-child {
					display: none;
				}
				&:last-child {
					display: inline-block;
				}
			}
		}
	}
}
.tab {
	&-titles {
		margin-bottom: 0;
		li {
			list-style-type: none;
			margin-left: 0;
			a {
				text-decoration: none;
				padding: 0.5rem 1rem;
				display: block;
				border-width: 1px;
				border-style: solid;
				border-color: transparent;
				// border: 1px solid transparent;
				white-space: nowrap;
				&:not(.active) {
					background-color: inherit !important;
					color: inherit !important;
				}
				&.active {
					// box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
				}
				&:hover {
					opacity: 1;
				}
			}
		}
	}
	&-info {
		padding: 1rem;
		div[data-item-tab] {
			display: none;
			&.active {
				display: block;
			}
		}
	}
	&-horizontal {
		.tab-titles {
			display: flex;
			// max-width: 100%;
			overflow-x: auto;
		}
	}
	&-vertical {
		display: flex;
		.tab-info {
			flex-grow: 1;
		}
	}
}
.banner {
	&-cover {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	&-tile {
		background-repeat: repeat;
	}
	&-fixed {
		@include mixins.bup(md) {
			background-attachment: fixed;
		}
	}
}
.opacity-panel {
	position: relative;

	&-bg {
		position: absolute;
		display: block;
		content: "";
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
	}

	&-content {
		position: relative;
		z-index: 5;
	}
}
@for $i from 0 through 10 {
	$m: $i * 0.1;
	.op-#{$i} {
		opacity: $m;
	}
}
.img {
	&-cover {
		img {
			object-fit: cover;
			width: 100%;
		}
	}
	&-contain {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}
