@use "../components/mixins";
@use "../components/variables";

footer {
	margin-top: 6rem;
	@include mixins.bdown(sm) {
		margin-top: 0;
	}
	&.no-marg {
		margin-top: 0;
	}
}
.footer-top {
	display: flex;
	column-gap: 1rem;
	justify-content: space-between;
	font-family: variables.$rubik;
	margin-bottom: 2rem;
	@include mixins.bdown(lg) {
		flex-wrap: wrap;
		justify-content: space-around;
	}
	&-date {
		max-width: 285px;
		&-adress {
			font-size: mixins.rem(20);
			@include mixins.letter-spacing(10);
			border-bottom: 3px solid rgba($color: #707070, $alpha: 0.26);
		}
		&-geo {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			display: flex;
			column-gap: 0.5rem;
			font-size: mixins.rem(17);
			@include mixins.letter-spacing(10);
		}
		@include mixins.bdown(lg) {
			flex-basis: 45%;
			max-width: none;
		}
		@include mixins.bdown(sm) {
			flex-basis: 100%;
			max-width: 285px;
		}
	}

	&-menu {
		display: flex;
		column-gap: 1rem;
		justify-content: space-between;
		@include mixins.bdown(lg) {
			flex-basis: 100%;
			max-width: none;
			order: 1;
			justify-content: space-evenly;
		}
		@include mixins.bdown(sm) {
			flex-wrap: wrap;
		}
		@include mixins.bdown(xxs) {
			justify-content: flex-start;
		}
		&-main {
			ul {
				li {
					font-size: mixins.rem(14);
					font-weight: 700;
				}
			}
		}
		&-program {
			ul {
				li {
					font-size: mixins.rem(12);
					font-weight: 500;
					margin-bottom: 0.5rem;
				}
			}
		}
		&-donate {
			max-width: 300px;
		}
		.footer-top-date-geo {
			i {
				color: variables.$purple;
			}
		}
	}

	&-subscribe {
		@include mixins.bdown(lg) {
			flex-basis: 45%;
			max-width: none;
		}
		@include mixins.bdown(sm) {
			flex-basis: 100%;
			order: 2;
		}
	}
	.help-emergency {
		justify-content: center;
	}
}

.footer-bottom {
	display: flex;
	&-wrapper {
		padding-bottom: 2.5rem;
		padding-left: 2rem;
		padding-right: 2rem;
		border-top-right-radius: 50px;
		// max-width: 1785px;
		// max-width: 92.97%;
		margin-right: auto;
		flex-grow: 1;
		@include mixins.bdown(md) {
			padding-bottom: 1rem;
			padding-left: 0;
			padding-right: 0;
		}
	}
	.container {
		display: flex;
		justify-content: space-between;
		font-family: variables.$rubik;
		font-size: mixins.rem(16);
		@include mixins.bdown(lg) {
			flex-wrap: wrap;
		}
		@include mixins.bdown(md) {
			display: block;
			text-align: center;
		}
		@include mixins.bdown(xs) {
			padding-right: 4rem;
			font-size: mixins.rem(14);
		}
	}
	&-social {
		transform: translateY(-35%);
		@include mixins.bdown(lg) {
			flex-basis: 100%;
			transform: translateY(0);
			margin-top: 1rem;
			text-align: center;
		}

		a {
			margin-left: 0.5rem;
			margin-right: 0.5rem;
			@include mixins.bdown(md) {
				max-width: mixins.rem(40);
			}
			@include mixins.bdown(xs) {
				max-width: mixins.rem(35);
				margin-left: 0.3rem;
				margin-right: 0.3rem;
			}
		}
	}
	&-copyright,
	&-terms {
		margin-top: 2.5rem;
		@include mixins.bdown(lg) {
			margin-top: 1rem;
		}
		ul {
			display: flex;
			column-gap: 0.5rem;
			margin-bottom: 0;
			@include mixins.bdown(md) {
				justify-content: center;
			}
			@include mixins.bdown(xs) {
				column-gap: 0.2rem;
			}
			li {
				&::after {
					content: "|";
					margin-left: 0.5rem;
					@include mixins.bdown(xs) {
						margin-left: 0.2rem;
					}
				}
				&:last-child {
					&::after {
						content: none;
					}
				}
			}
		}
	}
	&-arrow {
		position: relative;
		flex-basis: 7rem;
		@include mixins.bdown(sm) {
			flex-basis: 4rem;
		}
		@include mixins.bdown(xs) {
			flex-basis: 0;
		}
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			bottom: 0;
			top: 50%;
			background-color: variables.$beige;
		}
		a.smooth-to-top {
			position: fixed;
			right: 1.3rem;
			bottom: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: variables.$green;
			border-radius: 10px;
			height: mixins.rem(72);
			width: mixins.rem(72);
			pointer-events: none;
			text-decoration: none;
			transform: translateY(100px);
			transition: all 0.3s ease-in-out;
			opacity: 0;
			z-index: 15;
			@include mixins.bdown(sm) {
				height: mixins.rem(60);
				width: mixins.rem(60);
				right: 0.5rem;
			}

			&::after {
				content: url(../../images/icons/corner-right-up.svg);
				color: variables.$white;
			}

			&.active {
				opacity: 1;
				pointer-events: auto;
				transform: translateY(0);
			}
		}
	}
}
