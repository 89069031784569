@use "sass:meta";
//Imports
@import "vendor/slick.css";
@import "vendor/all.min.css";
@import "vendor/fancybox.css";
@import "vendor/toastr.css";
@import "vendor/justifiedGallery.min.css";
@import "vendor/icomoon.css";
@include meta.load-css("components/components.scss");
@include meta.load-css("base/layout.scss");
@include meta.load-css("parts/parts.scss");





