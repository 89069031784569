@use "../components/mixins";
@use "../components/variables";

.text {
	&-yellow {
		color: variables.$yellow;
	}

	&-olive {
		color: variables.$olive;
	}

	&-purple {
		color: variables.$purple;
	}

	&-silver {
		color: variables.$silver;
	}

	&-lightpurple {
		color: variables.$lightpurple;
	}

	&-white {
		color: variables.$white;
	}

	&-beige {
		color: variables.$beige;
	}

	&-gray {
		color: variables.$gray;
	}

	&-black {
		color: variables.$black;
	}

	&-black-alt {
		color: variables.$blackalt;
	}

	&-base {
		color: variables.$base;
	}

	&-darkgray {
		color: variables.$darkgray;
	}

	&-orange {
		color: variables.$orange;
	}

	&-scarlet {
		color: variables.$scarlet;
	}

	&-red {
		color: variables.$red;
	}

	&-pink {
		color: variables.$pink;
	}

	&-blue {
		color: variables.$blue;
	}

	&-green {
		color: variables.$green;
	}

	&-lightgreen {
		color: variables.$lightgreen;
	}
}

.bg {
	&-yellow {
		background-color: variables.$yellow;
	}

	&-crystal {
		background-color: variables.$crystal;
	}

	&-sober {
		background-color: variables.$sober;
	}

	&-bronze {
		background-color: variables.$bronze;
	}

	&-olive {
		background-color: variables.$olive;
	}

	&-purple {
		background-color: variables.$purple;
	}

	&-silver {
		background-color: variables.$silver;
	}

	&-lightpurple {
		background-color: variables.$lightpurple;
	}

	&-white {
		background-color: variables.$white;
	}

	&-beige {
		background-color: variables.$beige;
	}

	&-gray {
		background-color: variables.$gray;
	}

	&-darkgray {
		background-color: variables.$darkgray;
	}

	&-black {
		background-color: variables.$black;
	}

	&-black-alt {
		background-color: variables.$blackalt;
	}

	&-base {
		background-color: variables.$base;
	}

	&-orange {
		background-color: variables.$orange;
	}

	&-scarlet {
		background-color: variables.$scarlet;
	}

	&-red {
		background-color: variables.$red;
	}

	&-pink {
		background-color: variables.$pink;
	}

	&-blue {
		background-color: variables.$blue;
	}

	&-green {
		background-color: variables.$green;
	}

	&-lightgreen {
		background-color: variables.$lightgreen;
	}
}

body.loaded {
	a,
	button {
		transition: all 0.4s ease-in-out;
	}
}

a,
button {
	&:not(.disabled):not(:disabled) {
		cursor: pointer;
	}

	&.disabled,
	&:disabled {
		cursor: default;
		pointer-events: none;
	}

	transition: none;
}

a {
	text-decoration: underline;

	&:not(.btn) {
		&:hover {
			opacity: 0.75;
		}
	}
}

html {
	font-size: 18px;
	font-weight: 500;
	@include mixins.bdown(md) {
		font-size: 16px;
	}
}

body {
	line-height: 1.4;
	color: variables.$base;
	font-family: variables.$krub;
	background-color: variables.$beige;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1-style,
.h2-style,
.h3-style,
.h4-style,
.h5-style,
.h6-style {
	line-height: normal;
	font-family: variables.$jost;
}

h1,
.h1-style {
	font-size: mixins.em(70);
	font-weight: 600;
	line-height: 1;
	@include mixins.bdown(xs) {
		font-size: mixins.em(60);
	}
}

h2,
.h2-style {
	font-size: mixins.em(43);
	font-weight: 600;
	line-height: 1.1;
	@include mixins.letter-spacing(10);
	@include mixins.bdown(xs) {
		font-size: mixins.em(38);
	}
}

h3,
.h3-style {
	font-family: variables.$rubik;
	font-size: mixins.em(32);
	font-weight: 700;
	line-height: 1;
	@include mixins.letter-spacing(10);
	@include mixins.bdown(xs) {
		font-size: mixins.em(28);
	}
}

h4,
.h4-style {
	font-size: mixins.em(30);
	font-family: variables.$rubik;
	line-height: 1.1;
	@include mixins.letter-spacing(10);
	@include mixins.bdown(xs) {
		font-size: mixins.em(27);
	}
}

h5,
.h5-style {
	font-size: mixins.em(25);
	font-family: variables.$rubik;
	line-height: 1.3;
	@include mixins.letter-spacing(10);
	@include mixins.bdown(xs) {
		font-size: mixins.em(23);
	}
}

h6,
.h6-style {
	font-size: mixins.em(23);
	line-height: 1.1;
	font-weight: 700;
}

p,
ul,
ol {
	margin-bottom: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.6em;
}

blockquote {
	border-left: mixins.rem(10) solid currentColor;
	padding: 2rem;
	margin-left: 2rem;
	margin-bottom: 1rem;
	font-style: italic;

	& > *:last-child {
		margin-bottom: 0;
	}
}

section {
	ul {
		li {
			list-style-type: disc;
			margin-left: 1em;
		}
	}

	ol {
		li {
			margin-left: 1em;
		}
	}
}
ul {
	&.list-style-none {
		li {
			list-style-type: none !important;
		}
	}
	&.list-style-dots {
		li {
			list-style-type: disc !important;
		}
	}
	&.list-style-triangle {
		li {
			list-style-type: disclosure-closed !important;
		}
	}
}

header a,
footer a {
	display: inline-block;
}

.design-block {
	a:not(.default) {
		text-decoration: none;

		&:not(.btn) {
			&:hover {
				opacity: 1;
			}
		}
	}

	ul:not(.default),
	ol:not(.default) {
		li {
			list-style-type: none;
		}
	}
}

img {
	&.img-fw {
		width: 100%;
	}
}

a {
	obj {
		pointer-events: none;
	}
}

.btn {
	padding: 0.85rem 1rem;
	text-align: center;
	text-decoration: none;
	border: 1px solid variables.$black;
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	border-bottom-left-radius: 24px;
	color: variables.$black;
	background-color: transparent;
	display: inline-block;
	font-family: variables.$rubik;
	line-height: 1.4;
	@include mixins.transition;
	@include mixins.letter-spacing(50);
	&.active,
	&:hover {
		background-color: variables.$black;
		color: variables.$white;
	}
	@include mixins.bdown(md) {
		font-size: 1rem;
	}
	@include mixins.bup(md) {
		min-width: 165px;
	}

	&.bold {
		font-weight: 700;
	}

	&-small {
		// @include fluid-font(12px);
		font-size: 0.7rem;
		@include mixins.bdown(md) {
			font-size: 0.9rem;
		}
		@include mixins.bup(md) {
			min-width: 125px;
		}
	}

	&-large {
		// @include fluid-font(20px);
		font-size: 1rem;
		@include mixins.bdown(md) {
			font-size: 1rem;
		}
		@include mixins.bup(md) {
			min-width: 272px;
		}
	}

	&-inverted {
		background-color: variables.$black;
		color: #fff;

		&:hover {
			background-color: variables.$white;
			color: variables.$black;
		}
	}

	&-white {
		background-color: variables.$white;

		&:hover {
			background-color: variables.$black;
			color: variables.$white;
		}
		&-dimm {
			background-color: variables.$white;
			color: variables.$black;
			border: 1px solid variables.$white;

			&:hover {
				background-color: variables.$white;
				color: variables.$black;
				filter: brightness(90%);
			}
		}
	}

	&-gray {
		border: 1px solid variables.$gray;
		color: variables.$gray;

		&:hover {
			background-color: variables.$gray;
			color: variables.$black;
		}

		&-inverted {
			border: 1px solid variables.$gray;
			background-color: variables.$gray;
			color: variables.$black;

			&:hover {
				border: 1px solid variables.$gray;

				// background-color: $black;
				background-color: transparent;
				color: variables.$gray;
			}
		}
		&-dimm {
			background-color: variables.$gray;
			color: variables.$black;
			border: 1px solid variables.$gray;

			&:hover {
				background-color: variables.$gray;
				color: variables.$black;
				filter: brightness(90%);
			}
		}
	}

	&-darkgray {
		border: 1px solid variables.$darkgray;
		color: variables.$darkgray;

		&:hover {
			background-color: variables.$darkgray;
			color: variables.$white;
		}

		&-inverted {
			border: 1px solid variables.$darkgray;
			background-color: variables.$darkgray;
			color: variables.$white;

			&:hover {
				border: 1px solid variables.$darkgray;
				background-color: transparent;
				color: variables.$darkgray;
			}
		}
		&-dimm {
			background-color: variables.$darkgray;
			color: variables.$white;
			border: 1px solid variables.$darkgray;

			&:hover {
				background-color: variables.$darkgray;
				color: variables.$white;
				filter: brightness(90%);
			}
		}
	}

	&-yellow {
		border: 1px solid variables.$yellow;
		color: variables.$yellow;

		&:hover {
			background-color: variables.$yellow;
			color: variables.$base;
		}

		&-inverted {
			border: 1px solid variables.$yellow;
			background-color: variables.$yellow;
			color: variables.$base;

			&:hover {
				border: 1px solid variables.$yellow;

				// background-color: $black;
				background-color: transparent;
				color: variables.$yellow;
			}
		}
		&-dimm {
			background-color: variables.$yellow;
			color: variables.$base;
			border: 1px solid variables.$yellow;

			&:hover {
				background-color: variables.$yellow;
				color: variables.$base;
				filter: brightness(90%);
			}
		}
	}

	&-purple {
		border: 1px solid variables.$purple;
		color: variables.$purple;

		&:hover {
			background-color: variables.$purple;
			color: variables.$white;
		}

		&-inverted {
			border: 1px solid variables.$purple;
			background-color: variables.$purple;
			color: variables.$white;

			&:hover {
				border: 1px solid variables.$purple;

				// background-color: $white;
				background-color: transparent;
				color: variables.$purple;
			}
		}
		&-dimm {
			background-color: variables.$purple;
			color: variables.$white;
			border: 1px solid variables.$purple;

			&:hover {
				background-color: variables.$purple;
				color: variables.$white;
				filter: brightness(90%);
			}
		}
	}

	&-lightpurple {
		border: 1px solid variables.$lightpurple;
		color: variables.$lightpurple;

		&:hover {
			background-color: variables.$lightpurple;
			color: variables.$blue;
		}

		&-inverted {
			border: 1px solid variables.$lightpurple;
			background-color: variables.$lightpurple;
			color: variables.$blue;

			&:hover {
				border: 1px solid variables.$lightpurple;
				background-color: transparent;
				color: variables.$lightpurple;
			}
		}
		&-dimm {
			background-color: variables.$lightpurple;
			color: variables.$blue;
			border: 1px solid variables.$lightpurple;

			&:hover {
				background-color: variables.$lightpurple;
				color: variables.$blue;
				filter: brightness(90%);
			}
		}
	}

	&-pink {
		border: 1px solid variables.$pink;
		color: variables.$pink;

		&:hover {
			background-color: variables.$pink;
			color: variables.$base;
		}

		&-inverted {
			// border: 1px solid $white;
			border: 1px solid variables.$pink;
			background-color: variables.$pink;
			color: variables.$base;

			&:hover {
				border: 1px solid variables.$pink;

				// background-color: $white;
				background-color: transparent;
				color: variables.$pink;
			}
		}
		&-dimm {
			background-color: variables.$pink;
			color: variables.$base;
			border: 1px solid variables.$pink;

			&:hover {
				background-color: variables.$pink;
				color: variables.$base;
				filter: brightness(90%);
			}
		}
	}

	&-blue {
		border: 1px solid variables.$blue;
		color: variables.$blue;

		&:hover {
			background-color: variables.$blue;
			color: variables.$base;
		}

		&-inverted {
			// border: 1px solid $white;
			border: 1px solid variables.$blue;
			background-color: variables.$blue;
			color: variables.$base;

			&:hover {
				border: 1px solid variables.$blue;

				// background-color: $white;
				background-color: transparent;
				color: variables.$blue;
			}
		}
		&-dimm {
			background-color: variables.$blue;
			color: variables.$base;
			border: 1px solid variables.$blue;

			&:hover {
				background-color: variables.$blue;
				color: variables.$base;
				filter: brightness(90%);
			}
		}
	}

	&-green {
		border: 1px solid variables.$green;
		color: variables.$green;

		&:hover {
			background-color: variables.$green;
			color: variables.$base;
		}

		&-inverted {
			// border: 1px solid $white;
			border: 1px solid variables.$green;
			background-color: variables.$green;
			color: variables.$white;

			&:hover {
				border: 1px solid variables.$green;

				// background-color: $white;
				background-color: transparent;
				color: variables.$green;
			}
		}
		&-dimm {
			background-color: variables.$green;
			color: variables.$pink;
			border: 1px solid variables.$green;

			&:hover {
				background-color: variables.$green;
				color: variables.$pink;
				filter: brightness(90%);
			}
		}
	}

	&-lightgreen {
		border: 1px solid variables.$lightgreen;
		color: variables.$lightgreen;

		&:hover {
			background-color: variables.$lightgreen;
			color: variables.$blue;
		}

		&-inverted {
			// border: 1px solid $white;
			border: 1px solid variables.$lightgreen;
			background-color: variables.$lightgreen;
			color: variables.$blue;

			&:hover {
				border: 1px solid variables.$lightgreen;

				// background-color: $white;
				background-color: transparent;
				color: variables.$lightgreen;
			}
		}
		&-dimm {
			background-color: variables.$lightgreen;
			color: variables.$blue;
			border: 1px solid variables.$lightgreen;

			&:hover {
				background-color: variables.$lightgreen;
				color: variables.$blue;
				filter: brightness(90%);
			}
		}
	}

	&-scarlet {
		border: 1px solid variables.$scarlet;
		color: variables.$scarlet;

		&:hover {
			background-color: variables.$scarlet;
			color: variables.$white;
		}

		&-inverted {
			background-color: variables.$scarlet;
			border: 1px solid variables.$scarlet;
			color: variables.$white;

			&:hover {
				border: 1px solid variables.$scarlet;

				// background-color: $white;
				background-color: transparent;
				color: variables.$scarlet;
			}
		}
		&-dimm {
			background-color: variables.$scarlet;
			color: variables.$white;
			border: 1px solid variables.$scarlet;

			&:hover {
				background-color: variables.$scarlet;
				color: variables.$white;
				filter: brightness(90%);
			}
		}
	}

	&-orange {
		border: 1px solid variables.$orange;
		color: variables.$orange;

		&:hover {
			background-color: variables.$orange;
			color: variables.$white;
		}

		&-inverted {
			background-color: variables.$orange;
			color: variables.$white;
			border: 1px solid variables.$orange;

			&:hover {
				border: 1px solid variables.$orange;

				// background-color: $white;
				background-color: transparent;
				color: variables.$orange;
			}
		}
		&-dimm {
			background-color: variables.$orange;
			color: variables.$white;
			border: 1px solid variables.$orange;

			&:hover {
				background-color: variables.$orange;
				color: variables.$white;
				filter: brightness(90%);
			}
		}
	}

	&-red {
		border: 1px solid variables.$red;
		color: variables.$red;

		&:hover {
			background-color: variables.$red;
			color: variables.$white;
		}

		&-inverted {
			background-color: variables.$red;
			border: 1px solid variables.$red;
			color: variables.$white;

			&:hover {
				border: 1px solid variables.$red;

				// background-color: $white;
				background-color: transparent;
				color: variables.$red;
			}
		}
		&-dimm {
			background-color: variables.$red;
			color: variables.$white;
			border: 1px solid variables.$red;

			&:hover {
				background-color: variables.$red;
				color: variables.$white;
				filter: brightness(90%);
			}
		}
	}

	&-rounded {
		border-radius: 1rem;
	}

	&[class^="btn-animated"],
	&[class*=" btn-animated"] {
		border: none !important;
		position: relative;
		overflow: hidden;

		span {
			z-index: 5;
			position: relative;
		}

		&:hover {
			&:before {
				right: -50%;
				top: -50%;
			}
		}

		// overflow: hidden;
		&:before {
			content: "";
			display: block;
			position: absolute;
			z-index: 1;
			border-radius: 50%;
			top: -5px;
			transition: all 0.7s;
			width: 200%;
			height: 300%;
			right: 100%;
		}

		&[class*="-from-white"] {
			background-color: variables.$white;
			color: variables.$black;
		}

		&[class*="-from-black"] {
			background-color: variables.$black;
			color: variables.$white;
		}

		&[class*="-from-gray"] {
			background-color: variables.$gray;
			color: variables.$black;
		}

		&[class*="-from-darkgray"] {
			background-color: variables.$darkgray;
			color: variables.$white;
		}

		&[class*="-from-blue"] {
			background-color: variables.$blue;
			color: variables.$black;
		}

		&[class*="-from-green"] {
			background-color: variables.$green;
			color: variables.$black;
		}

		&[class*="-from-pink"] {
			background-color: variables.$pink;
			color: variables.$black;
		}

		&[class*="-from-yellow"] {
			background-color: variables.$yellow;
			color: variables.$white;
		}

		&[class*="-from-red"] {
			background-color: variables.$red;
			color: variables.$white;
		}

		&[class*="-from-orange"] {
			background-color: variables.$orange;
			color: variables.$white;
		}

		&[class*="-from-scarlet"] {
			background-color: variables.$scarlet;
			color: variables.$white;
		}

		&[class*="-from-purple"] {
			background-color: variables.$purple;
			color: variables.$white;
		}

		&[class*="-to-pink"] {
			&:hover {
				color: variables.$black;
			}

			&:before {
				background-color: variables.$pink;
			}
		}

		&[class*="-to-blue"] {
			&:hover {
				color: variables.$black;
			}

			&:before {
				background-color: variables.$blue;
			}
		}

		&[class*="-to-green"] {
			&:hover {
				color: variables.$black;
			}

			&:before {
				background-color: variables.$green;
			}
		}

		&[class*="-to-yellow"] {
			&:hover {
				color: variables.$black;
			}

			&:before {
				background-color: variables.$yellow;
			}
		}

		&[class*="-to-red"] {
			&:hover {
				color: variables.$white;
			}

			&:before {
				background-color: variables.$red;
			}
		}

		&[class*="-to-purple"] {
			&:hover {
				color: variables.$white;
			}

			&:before {
				background-color: variables.$purple;
			}
		}

		&[class*="-to-scarlet"] {
			&:hover {
				color: variables.$white;
			}

			&:before {
				background-color: variables.$scarlet;
			}
		}

		&[class*="-to-orange"] {
			&:hover {
				color: variables.$white;
			}

			&:before {
				background-color: variables.$orange;
			}
		}

		&[class*="-to-white"] {
			&:hover {
				color: variables.$black;
			}

			&:before {
				background-color: variables.$white;
			}
		}

		&[class*="-to-black"] {
			&:hover {
				color: variables.$white;
			}

			&:before {
				background-color: variables.$black;
			}
		}

		&[class*="-to-gray"] {
			&:hover {
				color: variables.$black;
			}

			&:before {
				background-color: variables.$gray;
			}
		}

		&[class*="-to-darkgray"] {
			&:hover {
				color: variables.$white;
			}

			&:before {
				background-color: variables.$gray;
			}
		}
	}
}

.table {
	&-container {
		overflow-x: auto;
	}

	&-fw {
		width: 100%;
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	font-size: inherit;

	td,
	th {
		vertical-align: top;
		border: 1px solid variables.$gray;
		padding: 5px;
	}

	th {
		font-weight: 700;
		text-align: center;
	}

	&.text {
		&-left {
			td,
			th {
				text-align: left;
			}
		}

		&-right {
			td,
			th {
				text-align: right;
			}
		}

		&-center {
			td,
			th {
				text-align: center;
			}
		}
	}

	&.table-inverted {
		color: variables.$beige;
	}

	&.table-valign {
		&-top {
			td,
			th {
				vertical-align: top;
			}
		}

		&-middle {
			td,
			th {
				vertical-align: middle;
			}
		}

		&-bottom {
			td,
			th {
				vertical-align: bottom;
			}
		}
	}
}

xmp {
	font-size: 0.7rem;
	font-family: monospace;
}

.code-container {
	overflow-x: auto;
	// background-color: $gray;
}

@keyframes moveInBottom {
	0% {
		opacity: 0;
		transform: translateY(30px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}
