$black: #000000;
$blackalt: #2b2b33;
$white: #fff;
$yellow: #ffdb3c;

$sober: #BD8FC6;
$crystal: #D0C5D3;
$bronze: #ADADAD;
$silver: #B2B2B2;
$base: #3c3c3c;
$darkgray: #2C2731;
$gray: #505050;
$blue: #443b5f;
$purple: #7f87ac;
$lightpurple: #f2f5fd;
$red: #d0685c;
$scarlet: #db8443;
$orange: #ffe2be;
$pink: #fff5ec;
$beige: #F9FAF0;
$green: #7eb2a3;
$lightgreen: #e1f4ee;
$olive: #879676;


$rubik: "Rubik", sans-serif;
$jost: "Jost", sans-serif;
$krub: "Krub", sans-serif;
$icomoon: "icomoon" !important;
$fontawesome: "Font Awesome 6 Free";

$box: 1230px;

//breakpoints
$breakpoints: (
	"ls": 2430px,
	"hd": 1919px,
	"hg": 1600px,
	"lg": 1400px,
	"nm": 1250px,
	"md": 991px,
	"sm": 768px,
	"xs": 480px,
	"xxs": 385px,
) !default;

$bfs: 18;
