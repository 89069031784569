@use "../components/mixins";
@use "../components/variables";

.header-container {
	max-width: 1100px;
	margin-bottom: 2rem;
}

.mixed-header {
	span {
		color: variables.$green;

		&:last-child {
			color: variables.$blue;
		}
	}
}

.jost-header {
	font-size: mixins.rem(35);
	font-family: variables.$jost;
	font-weight: 600;
	@include mixins.letter-spacing(10);
}

.no-pad {
	padding-top: 0;
	padding-bottom: 0;
}

.no-pad-bot {
	padding-bottom: 0;
}

.no-pad-top {
	padding-top: 0;
}

.back-link {
	font-family: variables.$rubik;
	font-size: mixins.rem(15);
	display: inline-flex;
	align-items: center;
	column-gap: 0.5rem;
	text-decoration: none;

	&::before {
		content: "\e90e";
		font-family: variables.$icomoon;
		color: variables.$green;
	}
}

.next-link {
	font-family: variables.$rubik;
	font-size: mixins.rem(15);
	display: inline-flex;
	align-items: center;
	column-gap: 0.5rem;
	text-decoration: none;

	&::after {
		content: "\e90f";
		font-family: variables.$icomoon;
		color: variables.$green;
	}
}

.more-link {
	font-family: variables.$rubik;
	font-size: mixins.rem(15);
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	column-gap: 1rem;

	&::before {
		content: "";
		// flex-grow: 1;
		height: mixins.rem(2);
		width: mixins.rem(40);
		display: block;
		background-color: variables.$purple;
	}
}

.top-row {
	position: relative;
	padding-top: 2rem;

	&::after {
		content: "";
		position: absolute;
		height: mixins.rem(4);
		left: 1rem;
		right: 1rem;
		top: 0;
		background-color: rgba($color: variables.$purple, $alpha: 0.27);
	}
}

.bottom-row {
	position: relative;
	padding-bottom: 1rem;

	&::after {
		content: "";
		position: absolute;
		height: mixins.rem(4);
		left: 1rem;
		right: 1rem;
		bottom: 0;
		background-color: rgba($color: variables.$purple, $alpha: 0.27);
	}
}

.main-slider {
	padding-top: 0;
	padding-bottom: 0;

	.num-wrapper {
		display: flex;
		justify-content: flex-end;
		column-gap: 2rem;
		margin-bottom: -3rem;

		@include mixins.bdown(md) {
			column-gap: 1rem;
		}

		@include mixins.bdown(xs) {
			display: block;
		}

		.num-item {
			max-width: mixins.rem(420);
			position: relative;
			transform: translateY(-50%);
			color: variables.$white;
			text-align: center;
			background-color: variables.$green;
			padding: 1rem 2rem;
			border-radius: 20px;
			box-shadow: 0 0 57px rgba($color: variables.$white, $alpha: 0.32);

			@include mixins.bdown(md) {
				transform: translateY(-30%);
				padding: 1rem 1rem;
			}

			@include mixins.bdown(xs) {
				transform: translateY(0);
				margin-bottom: 1rem;
				margin-top: 1rem;
				max-width: 350px;
				margin-left: auto;
				margin-right: auto;
			}

			&-text {
				font-size: mixins.rem(20);
			}

			.h2-style {
				font-weight: 700;
			}

			&-subtext {
				font-size: mixins.rem(17);
			}

			&-mini {
				font-size: mixins.rem(13);
				margin-top: 0.5rem;
			}

			&-image {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&.heart-item {
				flex-grow: 1;
				padding-top: 2rem;

				@include mixins.bdown(xs) {
					margin-top: 3rem;
				}
			}
		}
	}
}

.addiction {
	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;

		@include mixins.bdown(nm) {
			flex-basis: auto;
		}
	}

	&-program {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
		border-top-left-radius: 10px;
		overflow: hidden;
		max-width: 260px;
		display: flex;
		flex-direction: column;
		box-shadow: 1px 1px 40px rgba($color: variables.$black, $alpha: 0.15);

		&-info {
			background-color: variables.$lightgreen;
			padding: 1rem;
			@include mixins.transition;
			flex-grow: 1;
		}

		&-header {
			position: relative;
			padding-bottom: 0.7rem;
			margin-bottom: 0.5rem;
			color: variables.$blue;
			@include mixins.transition;

			&::after {
				content: "";
				position: absolute;
				height: mixins.rem(7);
				width: mixins.rem(100);
				background-color: variables.$green;
				opacity: 0.28;
				bottom: 0;
				left: 0;
				@include mixins.transition;
			}
		}

		ul {
			margin-bottom: 0;
			display: inline;
			font-family: variables.$rubik;
			font-size: mixins.rem(17);
			@include mixins.letter-spacing(10);
			@include mixins.transition;
			color: variables.$gray;

			li {
				margin-left: 0;
				display: inline;
				margin-right: 0.5rem;
				margin-left: 1rem;
				position: relative;

				&::before {
					content: "";
					display: block;
					width: mixins.rem(7);
					height: mixins.rem(7);
					background-color: variables.$green;
					border-radius: 50%;
					position: absolute;
					top: mixins.rem(7);
					left: -1rem;
				}

				&:first-child {
					margin-left: 0;

					&::before {
						content: none;
					}
				}
			}
		}

		&-image {
			display: flex;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: variables.$purple;
				opacity: 0;
				@include mixins.transition;
			}

			img {
				width: 100%;
				object-fit: cover;
			}

			.btn {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				font-family: variables.$jost;
				font-size: mixins.rem(15);
				@include mixins.letter-spacing(50);
				opacity: 0;

				&:hover {
					filter: brightness(100%);
				}
			}
		}

		&:hover {
			box-shadow: 0 0 14px rgba($color: variables.$black, $alpha: 0.15);

			.addiction-program-info {
				background-color: variables.$blue;
			}

			.addiction-program-header,
			ul {
				color: variables.$white;

				&::after {
					opacity: 1;
				}
			}

			.addiction-program-image {
				&::before {
					opacity: 0.6;
				}

				.btn {
					opacity: 1;
				}
			}
		}
	}
}

.hotlink {
	border-bottom-right-radius: 152px;
	padding-bottom: 2rem;
	padding-top: 3rem;

	@include mixins.bdown(xs) {
		border-bottom-right-radius: 100px;
	}

	&-wrapper {
		display: flex;
		justify-content: space-between;
		column-gap: 1rem;
		align-items: center;

		@include mixins.bdown(lg) {
			flex-wrap: wrap;
			justify-content: flex-start;
		}
	}

	&-link {
		display: flex;
		column-gap: 1rem;
		max-width: 390px;
		margin-bottom: 1rem;
		flex-basis: auto;

		img {
			min-width: mixins.rem(78);

			@include mixins.bdown(xs) {
				min-width: mixins.rem(50);
				width: mixins.rem(50);
			}
		}

		p {
			margin-bottom: 0;
		}

		&-header {
			font-family: variables.$jost;
			font-size: mixins.rem(35);
			@include mixins.letter-spacing(10);
		}

		&-text {
			font-size: mixins.rem(20);
		}
	}
}

.featured {
	padding-top: 5rem;
	padding-bottom: 5rem;

	@include mixins.bdown(xs) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	&-line {
		display: flex;
		align-items: center;
		position: relative;
		column-gap: 1rem;

		p {
			margin-bottom: 0;
		}

		&::after {
			content: "";
			height: mixins.rem(4);
			flex-grow: 1;
			display: block;
			background-color: variables.$green;
			opacity: 0.39;
		}
	}

	&-underline {
		display: flex;
		align-items: center;
		position: relative;
		column-gap: 1rem;

		&::before {
			content: "";
			height: mixins.rem(4);
			flex-grow: 1;
			display: block;
			background-color: variables.$green;
			opacity: 0.39;
		}
	}

	&.bg-lightpurple {
		.featured-line {
			&::after {
				background-color: variables.$purple;
				opacity: 0.27;
			}
		}

		.featured-underline {
			&::before {
				background-color: variables.$purple;
				opacity: 0.27;
			}
		}
	}

	iframe {
		box-shadow: 0 3px 6px rgba($color: variables.$black, $alpha: 0.16);
	}

	&-image {
		display: flex;

		img {
			flex-grow: 1;
			width: 100%;
			height: 100%;
			object-fit: cover;
			box-shadow: 0 3px 6px rgba($color: variables.$black, $alpha: 0.16);
		}
	}

	&-header {
		font-family: variables.$jost;
		font-size: mixins.rem(35);
		font-weight: 600;
		@include mixins.letter-spacing(10);
	}

	&-subcol {
		display: flex;
		column-gap: 1rem;

		@include mixins.bdown(lg) {
			display: block;

			& > div {
				max-width: 100%;
			}
		}

		@include mixins.bdown(md) {
			display: flex;
		}

		@include mixins.bdown(sm) {
			display: block;
		}

		&-left {
			flex-basis: 70%;
			flex-grow: 1;
		}
	}

	ul {
		li {
			margin-bottom: 0.5rem;

			&::marker {
				color: variables.$green;
			}

			a {
				font-family: variables.$rubik;
				font-size: mixins.rem(15);
				font-weight: 400;
				@include mixins.letter-spacing(10);
				text-decoration: none;
			}
		}
	}
}

.heart-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-around;
	column-gap: 1rem;

	@include mixins.bdown(nm) {
		display: block;
		text-align: center;
	}

	&-inside {
		display: flex;
		align-items: center;
		column-gap: 1rem;

		@include mixins.bdown(nm) {
			justify-content: center;
			margin-bottom: 1rem;
		}

		@include mixins.bdown(sm) {
			display: block;
		}
	}
}

.featured-events {
	border-top-right-radius: 152px;

	@include mixins.bdown(xs) {
		border-top-right-radius: 100px;
	}

	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;
		flex-grow: 1;
	}

	&-link {
		border-top-right-radius: 40px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		max-width: 505px;
		box-shadow: 0 0 30px rgba($color: variables.$black, $alpha: 0.15);

		&-image {
			display: flex;

			img {
				width: 100%;
				object-fit: cover;
			}
		}

		&-info {
			background-color: variables.$white;
			flex-grow: 1;
			display: flex;
			flex-direction: column;

			&-geo {
				background-color: variables.$purple;
				font-size: mixins.rem(12);
				display: inline-flex;
				align-items: center;
				column-gap: 0.5rem;
				justify-content: flex-end;
				margin-left: auto;
				padding: 0.5rem;
				color: variables.$white;
				border-top-left-radius: 25px;
				border-bottom-left-radius: 25px;
				border-bottom-right-radius: 25px;

				i {
					color: variables.$scarlet;
				}
			}

			&-date {
				padding: 0.5rem;
				margin-left: 2rem;
				transform: translateY(-50%);
				border-top-left-radius: 25px;
				border-top-right-radius: 25px;
				border-bottom-left-radius: 25px;
				width: mixins.rem(102);

				@include mixins.bdown(sm) {
					margin-left: 1rem;
				}

				@include mixins.bdown(xs) {
					margin-left: 0.5rem;
				}

				.h3-style {
					font-family: variables.$krub;
				}

				span {
					font-size: mixins.rem(14);
					font-weight: 700;
					font-family: variables.$rubik;
					@include mixins.letter-spacing(50);
				}
			}

			&-text {
				font-size: mixins.rem(15);
			}
		}
	}
}

.featured-news {
	border-bottom-right-radius: 152px;

	@include mixins.bdown(xs) {
		border-bottom-right-radius: 100px;
	}

	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;
		flex-grow: 1;
	}

	&-link {
		display: flex;
		flex-direction: column;
		max-width: 483px;

		&-image {
			display: flex;

			img {
				width: 100%;
				object-fit: cover;
			}
		}

		&-info {
			padding: 1rem 0;
			flex-grow: 1;
			display: flex;
			flex-direction: column;

			&-date {
				font-family: variables.$rubik;
			}
		}
	}
}

.history {
	&-wrapper {
		display: grid;
		grid-template-columns: 3fr 2fr;
		column-gap: 2rem;
		row-gap: 1rem;

		@include mixins.bdown(md) {
			grid-template-columns: 1fr;
		}
	}

	&-hope {
		&-mission {
			position: relative;
			display: grid;
			grid-template-columns: 1fr 4fr;
			grid-gap: 1rem;
			padding-top: 1rem;
			padding-bottom: 1rem;

			@include mixins.bdown(nm) {
				grid-template-columns: 1fr;
			}

			@include mixins.bdown(md) {
				grid-template-columns: 1fr 4fr;
			}

			@include mixins.bdown(sm) {
				grid-template-columns: 1fr;
			}

			&::after {
				content: "";
				position: absolute;
				height: 6px;
				width: 100%;
				background-color: rgba($color: variables.$green, $alpha: 0.39);
				top: 0;
			}

			&-header {
				font-family: variables.$jost;
				font-size: mixins.rem(35);
				font-weight: 600;
				@include mixins.letter-spacing(10);

				@include mixins.bdown(xs) {
					font-size: mixins.em(28);
				}
			}
		}
	}

	&-board {
		&.support-inside {
			@include mixins.bdown(md) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-evenly;
				align-items: center;
				gap: 1rem;

				.h2-style {
					flex-basis: 100%;
					text-align: center;
					margin-bottom: 2rem;
				}
			}
		}

		&-image {
			display: flex;
			justify-content: center;

			img {
				width: 100%;
				object-fit: cover;
			}
		}

		&-calendar {
			display: flex;
			align-items: center;

			@include mixins.bdown(md) {
				flex-basis: 100%;
			}

			&-image {
				z-index: 5;
				display: flex;
				border-radius: 50%;
				box-shadow: 0 3px 6px rgba($color: variables.$black, $alpha: 0.16);

				img {
					border-radius: 50%;
				}
			}

			&-info {
				background-color: variables.$purple;
				color: variables.$white;
				max-width: mixins.rem(350);
				padding-left: 3rem;
				padding-right: 2rem;
				padding-bottom: 1rem;
				padding-top: 1rem;
				display: flex;
				align-items: center;
				column-gap: 1rem;
				font-size: mixins.rem(16);
				font-family: variables.$rubik;
				border-top-right-radius: 25px;
				border-bottom-left-radius: 25px;
				border-top-left-radius: 25px;
				margin-left: -2rem;

				i {
					font-size: mixins.rem(32);
				}

				@include mixins.bdown(lg) {
					padding-left: 2.5rem;
					column-gap: 0.7rem;
					flex-basis: 85%;
				}

				@include mixins.bdown(md) {
					padding-left: 3rem;
					column-gap: 1rem;
					flex-basis: auto;
				}

				@include mixins.bdown(xs) {
					padding-right: 0.5rem;
					padding-left: 2.2rem;
					font-size: mixins.rem(14);
					column-gap: 0.7rem;
				}
			}
		}

		&-desc {
			position: relative;
			border-top-right-radius: 70px;
			padding: 2rem;
			max-width: mixins.rem(450);
			box-shadow: 0 0 13px rgba($color: variables.$black, $alpha: 0.07);
			margin-bottom: 3rem;

			p {
				margin-bottom: 0;
			}

			&-type,
			&-loc {
				background-color: rgba($color: variables.$white, $alpha: 0.54);
				border-radius: 20px;
				font-size: mixins.rem(12);
				font-family: variables.$jost;
				font-weight: 400;
				padding: 0.2rem 0.5rem;
			}

			&-loc {
				background-color: rgba($color: variables.$purple, $alpha: 0.32);
			}

			&-header {
				font-size: mixins.rem(20);
				font-family: variables.$jost;
				margin-bottom: 0.5rem;
			}

			&-time {
				display: flex;
				column-gap: 0.5rem;
				font-family: variables.$rubik;
				font-size: mixins.rem(17);
				color: variables.$gray;
				border-top: 2px solid rgba($color: variables.$purple, $alpha: 0.21);
				padding-top: 0.5rem;
				margin-bottom: 0.5rem;

				i {
					color: variables.$purple;
					font-size: mixins.rem(18);
				}
			}
			&-border {
				border-top: 2px solid rgba($color: variables.$purple, $alpha: 0.21);
				padding-top: 0.5rem;
			}
			&-geo {
				display: flex;
				column-gap: 0.5rem;
				font-size: mixins.rem(15);
				color: variables.$gray;
				margin-bottom: 0.5rem;

				i {
					color: variables.$purple;
					font-size: mixins.rem(18);
				}

				a {
					color: variables.$red;
				}
			}

			&-btn {
				position: absolute;
				bottom: 0;
				right: 1rem;
				transform: translateY(50%);
			}
		}

		&-contact {
			max-width: mixins.rem(450);
			padding: 2rem;

			p {
				margin-bottom: 0;
			}

			&-date {
				display: flex;
				column-gap: 0.5rem;
				font-family: variables.$rubik;
				font-size: mixins.rem(17);
				color: variables.$gray;
				border-top: 2px solid rgba($color: variables.$purple, $alpha: 0.21);
				border-bottom: 2px solid rgba($color: variables.$purple, $alpha: 0.21);
				padding-top: 0.5rem;
				margin-bottom: 0.5rem;

				i {
					color: variables.$green;
					font-size: mixins.rem(18);
				}
			}

			&-mail {
				display: flex;
				column-gap: 0.5rem;
				font-size: mixins.rem(15);
				color: variables.$gray;
				// border-top: 2px solid rgba($color: $purple, $alpha: 0.21);
				padding-top: 0.5rem;
				margin-bottom: 0.5rem;

				i {
					color: variables.$green;
					font-size: mixins.rem(18);
				}

				a {
					color: variables.$red;
				}
			}
		}
	}
}

.team {
	&.team-support {
		margin-bottom: -6rem;

		@include mixins.bdown(sm) {
			margin-bottom: 0;
		}
	}

	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;
		margin-bottom: 2rem;

		@include mixins.bdown(nm) {
			flex-basis: 50%;
		}

		@include mixins.bdown(sm) {
			flex-basis: 100%;
		}
	}

	&-card {
		width: 100%;
		max-width: 370px;
		position: relative;
		display: flex;
		flex-direction: column;

		&-image {
			overflow: hidden;
			display: flex;
			border-top-left-radius: 42px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.h3-style {
			margin-bottom: 0.5rem;
		}

		&-info {
			flex-grow: 1;
			padding: 1rem 2rem;
			border-bottom-right-radius: 42px;
			overflow: hidden;
			box-shadow: 0 10px 30px rgba($color: variables.$black, $alpha: 0.07);

			&-subheader {
				position: relative;
				font-family: variables.$rubik;
				font-size: mixins.rem(17);
				padding-bottom: 1rem;
				margin-bottom: 1rem;
				@include mixins.letter-spacing(10);

				&::after {
					content: "";
					position: absolute;
					height: mixins.rem(7);
					width: mixins.rem(103);
					background-color: variables.$green;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}

			h3,
			.h3-style {
				font-size: mixins.em(27);
				@include mixins.bdown(xs) {
					font-size: mixins.em(23);
				}
			}

			&-text {
				font-size: mixins.rem(15);
			}
		}

		&-arrow {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%);
			width: mixins.rem(50);
			height: mixins.rem(50);
			background-color: variables.$red;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			&::after {
				content: "\e902";
				font-family: variables.$icomoon;
				color: variables.$white;
			}
		}
	}
}

.mailSign {
	.fg-1 {
		flex-grow: 1;
	}

	&-wrapper {
		display: flex;

		@include mixins.bdown(md) {
			display: block;
		}

		&-left {
			flex-basis: 50%;
			display: flex;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-right {
			flex-basis: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			@include mixins.bdown(md) {
				padding-top: 3rem;
				padding-bottom: 1rem;
			}
		}
	}

	&-container {
		max-width: mixins.rem(670);
		flex-grow: 1;
		position: relative;

		&::before {
			content: url(../../images/icons/plane-2.svg);
			position: absolute;
			left: 3.5rem;
			top: 1rem;

			@include mixins.bdown(nm) {
				left: 1rem;
			}
		}
	}
}

.inside {
	&.congrat {
		.inside-card {
			&-info {
				color: variables.$blue;
				background-color: variables.$lightgreen;
			}

			&:hover {
				.inside-card-info {
					background-color: variables.$blue;
					color: variables.$white;
				}
			}
		}
	}

	&.resources {
		.inside-card {
			.inside-card-info-header {
				&::after {
					content: none;
				}
			}

			&:hover {
				.inside-card-info {
					background-color: variables.$green;
				}
			}
		}
	}

	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;

		@include mixins.bdown(nm) {
			flex-basis: 50%;
		}

		@include mixins.bdown(sm) {
			flex-basis: 100%;
		}
	}

	&-card {
		position: relative;
		max-width: 372px;
		margin-bottom: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		&:hover {
			.btn {
				opacity: 1;
			}
		}

		.h3-style {
			@include mixins.bup(md) {
				font-size: mixins.rem(29);
			}
		}

		&-info {
			flex-grow: 1;
			align-self: stretch;
			padding-top: 1rem;
			padding-left: 1rem;
			padding-right: 1rem;
			padding-bottom: 1rem;
			border-top-right-radius: 30px;
			display: flex;
			align-items: center;
			@include mixins.transition;

			&-header {
				position: relative;
				padding-bottom: 1rem;

				&::after {
					content: "";
					position: absolute;
					height: mixins.rem(7);
					width: mixins.rem(103);
					background-color: variables.$green;
					bottom: 0;
					left: 0;
				}
			}
		}

		&-image {
			flex-grow: 1;
			display: flex;
			box-shadow: 0 3px 6px rgba($color: variables.$black, $alpha: 0.16);

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.btn {
			transform: translateY(-50%);
			margin-bottom: -3rem;
			font-size: mixins.rem(15);
			font-family: variables.$jost;
			@include mixins.letter-spacing(50);
			opacity: 0;

			&:hover {
				filter: brightness(100%);
			}
		}
	}
}

.asset {
	&-wrapper {
		max-width: 1300px;
		margin-left: auto;
		margin-right: auto;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;

		@include mixins.bdown(sm) {
			grid-template-columns: 1fr;
		}
	}
}

.champion {
	&-form-wrapper {
		max-width: 850px;
		margin-left: auto;
		margin-right: auto;
	}

	&-filter {
		max-width: 1400px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-around;
		column-gap: 1rem;
		flex-wrap: wrap;

		// @include bdown () {

		// }
		.btn {
			position: relative;
			margin-bottom: 2rem;
			outline: none;

			@include mixins.bdown(md) {
				min-width: 140px;
			}

			&::after {
				content: "";
				position: absolute;
				bottom: -1rem;
				right: -1px;
				width: 0px;
				height: 0px;
				border-style: solid;
				border-width: 0 26px 26px 0;
				border-color: transparent variables.$purple transparent transparent;
				transform: rotate(0deg);
				opacity: 0;
				transition: all 0.5s ease-in-out;
			}

			&.inUse {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;
		margin-bottom: 2rem;

		@include mixins.bdown(nm) {
			flex-basis: 50%;
		}

		@include mixins.bdown(sm) {
			flex-basis: 100%;
		}
	}

	&-card {
		background-color: variables.$blue;
		max-width: 330px;
		position: relative;
		border-top-right-radius: 70px;
		padding-top: 1rem;
		padding-left: 2rem;
		padding-right: 2rem;
		padding-bottom: 3rem;
		box-shadow: 0 0 13px rgba($color: variables.$black, $alpha: 0.07);
		margin-bottom: 2rem;
		margin-left: 1rem;

		&:hover {
			background-color: variables.$green;

			.champion-card-geo {
				background-color: variables.$purple;
			}

			.champion-card-state {
				background-color: variables.$lightgreen;
				color: variables.$blue;
			}

			.champion-card-city {
				color: variables.$blue;
			}

			.champion-card-text {
				color: variables.$darkgray;
			}

			.champion-card-btn {
				background-color: variables.$red;
				border-color: variables.$red;
			}
		}

		&-geo {
			position: absolute;
			left: 0;
			top: 1rem;
			background-color: variables.$red;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			width: mixins.rem(60);
			height: mixins.rem(60);
			transform: translateX(-50%);

			i {
				color: variables.$white;
				font-size: mixins.rem(30);
			}
		}

		&-state {
			color: variables.$lightgreen;
			font-size: mixins.rem(10);
			font-family: variables.$rubik;
			background-color: rgba($color: variables.$purple, $alpha: 0.32);
			border-radius: 12.5px;
			display: inline-flex;
			padding: 0.2rem 0.5rem;
			margin-bottom: 0.5rem;
			@include mixins.transition;
		}

		&-city {
			color: variables.$lightgreen;
			font-size: mixins.rem(22);
			font-family: variables.$rubik;
			@include mixins.letter-spacing(10);
			margin-bottom: 0.5rem;
			@include mixins.transition;
		}

		&-adress {
			color: variables.$pink;
			font-size: mixins.rem(20);
			font-family: variables.$rubik;
			@include mixins.letter-spacing(10);
			margin-bottom: 0.5rem;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 1px;
				width: 100%;
				background-color: variables.$purple;
				opacity: 0.63;
			}
		}

		&-text {
			color: variables.$white;
			font-size: mixins.rem(17);
			font-family: variables.$rubik;
			@include mixins.letter-spacing(10);
			@include mixins.transition;
		}

		&-btn {
			position: absolute;
			right: 1rem;
			bottom: 0;
			font-size: mixins.rem(15);
			transform: translateY(50%);
			color: variables.$pink;

			&:hover {
				filter: brightness(100%);
			}
		}
	}
}

.location-line {
	font-family: variables.$jost;
	font-size: mixins.rem(35);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	column-gap: 1rem;

	&::after {
		content: "";
		flex-grow: 1;
		height: mixins.rem(2);
		display: block;
		background-color: variables.$green;
		opacity: 0.39;
	}
}

.programs {
	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;
		margin-bottom: 2rem;

		@include mixins.bdown(nm) {
			flex-basis: 50%;
		}

		@include mixins.bdown(sm) {
			flex-basis: 100%;
		}
	}

	&-card {
		max-width: 500px;
		display: flex;
		flex-direction: column;

		&:hover {
			box-shadow: 7px 7px 14px rgba($color: variables.$black, $alpha: 0.16);

			.programs-card-image {
				&::before,
				&::after {
					opacity: 1;
				}
			}

			.programs-card-info {
				background-color: variables.$green;
			}

			.programs-card-header {
				color: variables.$blue;
			}

			.programs-card-btn {
				opacity: 1;
			}
		}

		&-image {
			position: relative;
			margin-bottom: -2.5rem;
			flex-grow: 1;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				opacity: 0;
				background-color: rgba($color: variables.$blue, $alpha: 0.83);
				@include mixins.transition;
			}

			&::after {
				content: url(../../images/icons/FAN.svg);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
				@include mixins.transition;
			}
		}

		&-info {
			flex-grow: 1;
			padding: 2rem;
			position: relative;
			z-index: 5;
			background-color: variables.$blue;
			border-top-right-radius: 50px;
			box-shadow: 0 0 14px rgba($color: variables.$black, $alpha: 0.15);
			@include mixins.transition;
			display: flex;
			flex-direction: column;
		}

		&-subheader {
			font-size: mixins.rem(15);
			font-family: variables.$rubik;
			@include mixins.letter-spacing(50);
			color: variables.$pink;
		}

		&-header {
			position: relative;
			color: variables.$lightgreen;
			padding-bottom: 1rem;
			@include mixins.transition;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				height: mixins.rem(7);
				width: mixins.rem(103);
				background-color: variables.$purple;
			}
		}

		&-text {
			color: variables.$white;
			font-size: mixins.rem(15);
			margin-bottom: 1rem;
		}

		&-hero {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}

		&-btn {
			margin-top: 2rem;
			align-self: center;
			margin-bottom: -3rem;
			opacity: 0;

			@include mixins.bdown(md) {
				font-size: mixins.rem(15);
			}

			&:hover {
				filter: brightness(100%);
			}
		}
	}
}

.events {
	&.event-inside {
		.events-item {
			padding: 3rem 2rem;
			margin-bottom: 2rem;

			&.no-bg {
				margin-bottom: 0;
				padding-bottom: 1rem;
			}

			@include mixins.bdown(md) {
				padding-top: 2rem;
				padding-bottom: 2rem;
			}

			@include mixins.bdown(xs) {
				padding-top: 1rem;
				padding-left: 1rem;
				padding-right: 1rem;
			}

			&-info {
				padding: 1rem 0 0 2rem;

				@include mixins.bdown(md) {
					padding-top: 3rem;
					padding-left: 0;
				}

				&-geo {
					top: -3rem;
					right: -2rem;

					@include mixins.bdown(md) {
						top: 0;
						right: 0;
					}
				}
			}
		}
	}

	&-item {
		&.no-bg {
			background-color: transparent;
			box-shadow: none;
		}

		display: flex;
		align-items: flex-start;
		background-color: variables.$white;
		margin-bottom: 4rem;
		box-shadow: 0 0 30px rgba($color: variables.$black, $alpha: 0.15);

		@include mixins.bdown(md) {
			border-top-right-radius: 40px;
			display: block;
		}

		&-image {
			flex-basis: 50%;
			display: flex;
			border-top-right-radius: 40px;
			overflow: hidden;
		}

		&-info {
			flex-basis: 50%;
			padding: 3rem 2rem;
			position: relative;

			&-geo {
				position: absolute;
				top: 0;
				right: 0;
				background-color: variables.$purple;
				font-size: mixins.rem(14);
				padding: 0.5rem 0.8rem;
				color: variables.$white;
				border-top-left-radius: 25px;
				border-bottom-left-radius: 25px;
				border-bottom-right-radius: 25px;
				@include mixins.letter-spacing(10);

				i {
					color: variables.$orange;
					margin-right: 0.5rem;
				}
			}

			&-day {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				position: relative;
				column-gap: 1rem;
				margin-bottom: 0.5rem;

				&::after {
					content: "";
					flex-grow: 1;
					height: mixins.rem(4);
					display: block;
					background-color: variables.$purple;
					opacity: 0.29;
				}
			}

			&-header {
				span {
					position: relative;
					margin-right: 1rem;
					padding-right: 0.5rem;

					&::after {
						content: "";
						position: absolute;
						height: 70%;
						width: 2px;
						background-color: variables.$purple;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
					}

					&:last-child {
						&::after {
							content: none;
						}
					}
				}
			}

			&-btn {
				color: variables.$pink;
				position: absolute;
				right: 2rem;
				bottom: 0;
				transform: translateY(50%);
			}

			.btn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.contact {
	.masonry > div {
		align-items: center;
	}

	&-item {
		width: mixins.rem(370);
		border: 2px solid variables.$white;
		background-color: variables.$white;
		flex-grow: 1;
		border-radius: 20px;
		padding: 1rem 2rem 1rem 2rem;
		box-shadow: 20px 20px 50px rgba($color: #000001, $alpha: 0.05);

		@include mixins.bdown(xxs) {
			width: 100%;
		}

		&-image {
			box-shadow: 0 10px 40px rgba($color: #3944f7, $alpha: 0.2);
			width: mixins.rem(97);
			height: mixins.rem(97);
			border-radius: 50%;
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 1.5rem;
			font-size: mixins.rem(30);
			color: variables.$white;
			@include mixins.transition;
		}

		&-text {
			a,
			p {
				margin-bottom: 0.5rem;
				display: block;
			}
		}
	}
}

.touch {
	position: relative;
	padding-top: 5rem;

	&::before {
		content: "";
		background-color: variables.$beige;
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		bottom: 0;
	}

	&-wrapper {
		position: relative;
		z-index: 5;
		box-shadow: 25px 25px 60px rgba($color: #000001, $alpha: 0.1);
		padding: 2rem 4rem 4rem 4rem;

		@include mixins.bdown(sm) {
			padding: 2rem;
		}

		@include mixins.bdown(xs) {
			padding: 2rem 1rem;
		}
	}

	&-container {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
}

.help {
	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;
		margin-bottom: 2rem;

		@include mixins.bdown(nm) {
			flex-basis: 50%;
		}

		@include mixins.bdown(sm) {
			flex-basis: 100%;
		}
	}

	&-container {
		max-width: 1100px;
	}

	&-item {
		position: relative;
		background-color: variables.$lightgreen;
		box-shadow: 0 0 14px rgba($color: variables.$black, $alpha: 0.15);
		max-width: 500px;
		display: flex;
		flex-direction: column;
		border-top-right-radius: 40px;
		align-items: center;

		&-info {
			padding: 1rem;
			flex-grow: 1;

			&-option {
				font-family: variables.$jost;
				font-size: mixins.rem(20);
				font-weight: 700;
				@include mixins.letter-spacing(10);
				margin-bottom: 0.5rem;
			}

			&-header {
				position: relative;
				padding-bottom: 1rem;

				&::after {
					content: "";
					position: absolute;
					height: mixins.rem(7);
					width: mixins.rem(100);
					background-color: variables.$green;
					opacity: 0.28;
					bottom: 0;
					left: 0;
					@include mixins.transition;
				}
			}
		}

		&-image {
			display: flex;
		}

		.btn {
			transform: translateY(-50%);
			margin-bottom: -3.2rem;
		}
	}

	&-emergency {
		display: flex;
		column-gap: 1rem;
		align-items: center;

		&-icon {
			border-radius: 50%;
			background-color: variables.$lightgreen;
			width: mixins.rem(78);
			height: mixins.rem(78);
			display: flex;
			justify-content: center;
			align-items: center;

			@include mixins.bdown(sm) {
				width: mixins.rem(60);
				height: mixins.rem(60);
			}

			i {
				color: variables.$green;
				font-size: mixins.rem(28);

				@include mixins.bdown(sm) {
					font-size: mixins.rem(22);
				}
			}
		}

		&-header {
			font-family: variables.$jost;
			font-size: mixins.rem(35);
			font-weight: 600;
			@include mixins.letter-spacing(10);
		}
	}
}

.present {
	&-wrapper {
		margin: 2rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 3rem;

		@include mixins.bdown(sm) {
			grid-template-columns: 1fr;
			margin-left: 1rem;
			margin-right: 1rem;
		}

		@include mixins.bdown(xs) {
			margin-left: 0;
			margin-right: 0;
		}
		&.one-sponsor {
			@include mixins.bup(md){
				max-width: 50% !important;
			}
		}
		.img-cover {
			display: flex;
			align-items: center;
		}
	}

	.platinum-wrapper {
		margin: 2rem;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 3rem;
		&.one-sponsor {
			@include mixins.bup(md){
				max-width: 33.3% !important;
			}
			@include mixins.bdown(sm){
				max-width: 50% !important;
			}
		}

		@include mixins.bdown(sm) {
			grid-template-columns: 1fr 1fr;
			margin-left: 1rem;
			margin-right: 1rem;
		}

		@include mixins.bdown(xs) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.gold-wrapper {
		margin: 2rem;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 3rem;
		&.one-sponsor {
			@include mixins.bup(md){
				max-width: 25% !important;
			}
			@include mixins.bdown(md){
				max-width: 33.3% !important;
			}
			@include mixins.bdown(sm){
				max-width: 50% !important;
			}
		}

		@include mixins.bdown(md) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include mixins.bdown(sm) {
			grid-template-columns: repeat(2, 1fr);
			margin-left: 1rem;
			margin-right: 1rem;
		}

		@include mixins.bdown(xs) {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.one-sponsor {
		margin-left: auto !important;
		margin-right: auto !important;
		grid-template-columns: 1fr !important;
	}
	.sponsor-text-wrapper {
		margin: 2rem;
		ul {
			li {
				margin-left: 0;
				margin-bottom: 1rem;
			}
		}
	}
	.img-cover {
		display: flex;
		align-items: center;
	}
}

.contact-social {
	display: flex;
	justify-content: center;
	margin-top: 1rem;

	a {
		display: block;
		margin-left: 0.5rem;
		margin-right: 0.5rem;

		@include mixins.bdown(md) {
			max-width: mixins.rem(40);
		}

		@include mixins.bdown(xs) {
			max-width: mixins.rem(35);
			margin-left: 0.3rem;
			margin-right: 0.3rem;
		}
	}
}

.news {
	&-wrapper {
		display: flex;
		column-gap: 1rem;

		@include mixins.bdown(nm) {
			flex-direction: column-reverse;
		}

		&-left {
			flex-basis: 65%;
		}

		&-right {
			flex-grow: 1;

			@include mixins.bdown(nm) {
				display: flex;
				justify-content: space-between;
				column-gap: 1rem;
				align-items: flex-start;
			}

			@include mixins.bdown(sm) {
				display: block;
			}
		}
	}

	&-item {
		margin-bottom: 2rem;

		&-info {
			padding: 0 1rem 0 2rem;

			@include mixins.bdown(nm) {
				padding-left: 1rem;
			}

			&-date {
				font-family: variables.$rubik;
			}

			.more-link {
				justify-content: flex-start;
			}
		}
	}

	&-archive {
		flex-grow: 1;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2rem;
		max-width: mixins.rem(370);
		padding: 2rem;
		font-size: mixins.rem(16);
		font-family: variables.$rubik;
		color: variables.$gray;
		box-shadow: 10px 10px 40px rgba($color: #000001, $alpha: 0.07);

		@include mixins.bdown(sm) {
			margin-left: 0;
		}

		@include mixins.bdown(xs) {
			margin-left: auto;
		}

		ul {
			li {
				margin-bottom: 1rem;
				margin-left: 0;
				display: flex;
				align-items: center;
				column-gap: 0.5rem;

				&::before {
					content: "\e90d";
					font-family: variables.$icomoon;
					font-size: mixins.rem(9);
					color: variables.$red;
				}

				a.active {
					color: variables.$red;
				}
			}
		}
	}

	&-letter {
		flex-grow: 1;
		margin-bottom: 2rem;
		margin-left: auto;
		margin-right: auto;
		max-width: mixins.rem(370);
		padding: 2rem 1rem;
		box-shadow: 10px 10px 40px rgba($color: #000001, $alpha: 0.07);

		@include mixins.bdown(sm) {
			margin-left: 0;
		}

		@include mixins.bdown(xs) {
			margin-left: auto;
		}

		&-get {
			margin-bottom: 0.5rem;
			font-size: mixins.rem(15);
			font-family: variables.$rubik;
		}
	}
}

.pagination {
	display: inline-flex;
	align-items: center;
	background-color: variables.$white;
	padding: 1rem;
	font-size: mixins.rem(18);
	box-shadow: 20px 30px 70px rgba($color: #000001, $alpha: 0.1);

	&-item {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 2rem;
		position: relative;

		&::before {
			content: "";
			width: 1px;
			height: 5px;
			right: 0;
			top: 49%;
			transform: translateY(-50%);
			position: absolute;
			background-color: variables.$gray;
		}
	}

	&-back {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 2rem;
		position: relative;

		&::before {
			content: "\e910";
			font-family: variables.$icomoon;
			font-size: mixins.rem(11);
		}

		&::after {
			content: "";
			width: 1px;
			height: 5px;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			background-color: variables.$gray;
		}
	}

	&-forward {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 2rem;

		&::before {
			content: "\e90f";
			font-family: variables.$icomoon;
			font-size: mixins.rem(11);
		}
	}

	&-active {
		color: #f15b26;
	}

	.disp-none {
		display: none !important;
	}
}

.faq {
	border-top-right-radius: 152px;

	@include mixins.bdown(xs) {
		border-top-right-radius: 100px;
	}

	&-item {
		border-bottom: 1px solid rgba($color: variables.$black, $alpha: 0.12);
		padding-top: 1rem;

		&:last-child {
			border-bottom: none;

			@include mixins.bdown(md) {
				border-bottom: 1px solid rgba($color: variables.$black, $alpha: 0.12);
			}
		}

		&-question {
			position: relative;
			color: variables.$purple;
			font-family: variables.$rubik;
			font-size: mixins.rem(22);
			padding-right: 2rem;
			cursor: pointer;

			&::after {
				content: "+";
				width: mixins.rem(33);
				height: mixins.rem(33);
				border: 1px solid variables.$gray;
				color: variables.$purple;
				border-radius: 50%;
				position: absolute;
				right: 0;
				top: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 300;
				@include mixins.transition;
			}

			&.active {
				&::after {
					content: "-";
					border-color: variables.$green;
					color: variables.$green;
				}
			}
		}

		&-answer {
			font-size: mixins.rem(15);
			font-weight: 400;
			font-family: variables.$rubik;
			display: none;
		}
	}
}

.description {
	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;
		margin-bottom: 2rem;

		@include mixins.bdown(nm) {
			flex-basis: 50%;
		}

		@include mixins.bdown(sm) {
			flex-basis: 100%;
		}
	}
}

.meetings {
	.masonry {
		justify-content: center;
	}

	.masonry > div {
		align-items: center;
		margin-bottom: 2rem;

		@include mixins.bdown(nm) {
			flex-basis: 50%;
		}

		@include mixins.bdown(sm) {
			flex-basis: 100%;
		}
	}

	.history-board-desc {
		max-width: mixins.rem(350);
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		.btn {
			font-size: mixins.rem(16);
		}
	}
}

.hope {
	&-header {
		align-items: center;
		column-gap: 1rem;
		display: flex;

		p {
			margin-bottom: 0;
		}

		&::after {
			content: "";
			height: mixins.rem(6);
			flex-grow: 1;
			display: block;
			background-color: variables.$green;
			opacity: 0.39;
		}
	}
}

.drop-wrapper {
	display: flex;
	column-gap: 2rem;

	.agent {
		max-width: none;
	}

	@include mixins.bdown(sm) {
		display: block;
		margin-bottom: 4rem;
		margin-left: 1rem;
	}
}

.agent {
	max-width: mixins.rem(500);
	position: relative;
	flex-grow: 1;
	@include mixins.transition;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		top: 2.5rem;
		background-color: rgba($color: variables.$purple, $alpha: 0.27);
		width: 0;
	}

	&::after {
		content: "";
		position: absolute;
		left: 1.5rem;
		right: 2.5rem;
		top: 1rem;
		flex-grow: 1;
		background-color: rgba($color: variables.$purple, $alpha: 0.27);
		height: mixins.rem(4);
		display: block;
	}

	&-circles {
		cursor: pointer;

		&:before {
			content: "\e902";
			position: absolute;
			top: 0;
			left: -1rem;
			width: mixins.rem(38);
			height: mixins.rem(38);
			font-size: mixins.rem(16);
			font-family: variables.$icomoon;
			border-radius: 50%;
			background-color: variables.$red;
			color: variables.$white;
			display: flex;
			align-items: center;
			justify-content: center;
			@include mixins.transition;
		}

		&::after {
			content: "-";
			position: absolute;
			font-size: mixins.rem(25);
			line-height: normal;
			top: 0;
			right: 0;
			width: mixins.rem(38);
			height: mixins.rem(38);
			font-weight: 700;
			border-radius: 50%;
			background-color: rgba($color: variables.$base, $alpha: 0.34);
			color: variables.$white;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 0;
			@include mixins.transition;
		}
	}

	&-info {
		display: none;

		ul {
			margin-left: 1rem !important;
		}
	}

	&.active {
		padding: 3rem 1rem 2rem 2rem;

		&::before {
			width: mixins.rem(4);
		}

		.agent-circles {
			&:before {
				transform: rotate(90deg);
				background-color: variables.$green;
			}

			&::after {
				opacity: 1;
			}
		}
	}

	&-name {
		cursor: pointer;
		flex-basis: 30%;

		@include mixins.bdown(sm) {
			margin-bottom: 0.5rem;
		}
	}
}

.lost {
	&-one {
		display: flex;
		align-items: flex-start;
		margin-bottom: 2rem;

		@include mixins.bdown(sm) {
			display: block;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
		}

		&-image {
			display: flex;
			flex-basis: 31%;

			img {
				border-top-right-radius: 7px;
				border-top-left-radius: 7px;
				border-bottom-left-radius: 7px;

				@include mixins.bdown(sm) {
					border-bottom-left-radius: 0;
				}
			}
		}

		&-info {
			flex-basis: 69%;
			flex-grow: 1;
			background-color: variables.$white;
			margin-top: 1rem;
			margin-left: -2rem;
			padding: 2rem;
			font-family: variables.$rubik;
			font-size: mixins.rem(16);
			font-weight: 400;
			border-top-right-radius: 7px;
			border-top-left-radius: 7px;
			border-bottom-left-radius: 7px;
			box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.15);

			@include mixins.bdown(sm) {
				margin: auto;
				border-top-right-radius: 0;
				border-top-left-radius: 0;
				border-bottom-right-radius: 7px;
			}
		}
	}
}


.builders {
    &-image {
        @include mixins.bdown(md) {
            max-width: mixins.rem(500);
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.architects {
    .masonry {
        @include mixins.bdown(sm) {
            display: block;
        }
    }
    &-item {
        margin-bottom: 1rem;
        @include mixins.bdown(lg) {
            flex-basis: 33%;
        }
		@include mixins.bdown(nm) {
            flex-basis: 50%;
        }
        .masonry-inner {
            display: flex;
            flex-direction: column;

            @include mixins.bdown(sm) {
                max-width: mixins.rem(500);
                margin-left: auto;
                margin-right: auto;
            }
        }
        &-header {
            padding: 1rem 2rem;
            border-top-right-radius: 30px;
            @include mixins.bdown(xs) {
                padding: 1rem;
            }
            &-text {
                position: relative;
                font-size: mixins.rem(22);
                font-family: variables.$rubik;
                color: variables.$pink;
                padding-bottom: 0.5rem;

                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    height: mixins.rem(7);
                    width: mixins.rem(103);
                    background-color: rgba($color: variables.$white, $alpha: 0.28);
                }
            }
        }
        &-info {
            padding-top: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            background-color: variables.$white;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid #f5f5f5;
            border-left: 2px solid #f5f5f5;
            border-right: 2px solid #f5f5f5;
            ul {
                flex-grow: 1;
                li {
                    border-bottom: 2px solid #efedef;
                    margin-bottom: 1rem;
                    padding-bottom: 1rem;
                }
            }
        }
        &-btn {
            transform: translateY(35%);
        }
    }
    &-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
        @include mixins.bdown(xs) {
            column-gap: 0.5rem;
        }
        &-info {
            padding-left: 1rem;
            border-left: 4px solid rgba($color: variables.$purple, $alpha: 0.27);
            @include mixins.bdown(xs) {
                padding-left: 0.5rem;
            }
            &-text {
                font-weight: 600;
                font-size: mixins.rem(23);
                @include mixins.bdown(xs) {
                    font-size: mixins.rem(18);
                }
            }
        }
        &-phone {
            font-family: variables.$jost;
            font-size: mixins.rem(35);
            @include mixins.letter-spacing(10);
            @include mixins.bdown(xs) {
                font-size: mixins.rem(25);
            }
        }
    }
}

.wysiwyg-base {
	a {
		text-decoration: none;
	}

	ul {
		margin-left: 2rem;

		@include mixins.bdown(xs) {
			margin-left: 1rem;
		}

		li {
			margin-bottom: 0.5rem;

			&::marker {
				color: variables.$purple;
			}
		}
	}
}

.wysiwyg {
	a {
		color: variables.$green;
	}

	ul {
		margin-left: 2rem;

		@include mixins.bdown(xs) {
			margin-left: 1rem;
		}

		li {
			margin-bottom: 0.5rem;

			&::marker {
				color: variables.$purple;
			}
		}
	}
}

.wysiwyg-options {
	a {
		color: variables.$red;
	}

	ul {
		margin-left: 2rem;

		@include mixins.bdown(xs) {
			margin-left: 1rem;
		}

		li {
			margin-bottom: 0.5rem;

			&::marker {
				color: variables.$purple;
			}
		}
	}
}

.wysiwyg-red {
	a {
		color: variables.$red;
	}
}

.wysiwyg-small {
	font-size: mixins.rem(15);
}

.dis-btn {
	opacity: 0;
	pointer-events: none;
}

.fit-content {
	img {
		max-width: fit-content;
	}
}

.media-wrapper {
	&.donate-wrapper {
		padding-bottom: 195%;

		@include mixins.bdown(nm) {
			padding-bottom: 311%;
		}

		@include mixins.bdown(md) {
			padding-bottom: 210%;
		}

		@include mixins.bdown(sm) {
			padding-bottom: 300%;
		}

		@include mixins.bdown(xs) {
			padding-bottom: 570%;
		}
	}
}

.media-wrapper {
	&.resque-wrapper {
		padding-bottom: 120%;

		@include mixins.bdown(nm) {
			padding-bottom: 155%;
		}

		@include mixins.bdown(md) {
			padding-bottom: 110%;
		}
	}
}
