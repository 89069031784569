@use "../components/mixins";
@use "../components/variables";

body {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	main {
		flex: 1 1 auto;
	}
	&.menu-opened {
		main {
			display: none;
		}
	}
}
.header-wrapper {
	position: relative;
	border-top: 9px solid variables.$green;
	z-index: 5;

	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: mixins.rem(263);
		top: 0;
		opacity: 0.84;
		background: linear-gradient(
			to bottom,
			#2e2958,
			rgba($color: variables.$black, $alpha: 0)
		);
		background-repeat: no-repeat;
	}
	a {
		@include mixins.transition;
		text-decoration: none;
	}
	&-top {
		position: relative;
		z-index: 10;
		display: flex;
		justify-content: space-between;
		align-items: center;
		column-gap: 1rem;
		padding-top: 1rem;
		@include mixins.bdown(nm) {
			flex-wrap: wrap;
		}
		&-container {
			display: flex;
			align-items: center;
			column-gap: 1rem;
			@include mixins.bdown(nm) {
				flex-basis: 90%;
				padding: 0.5rem 0;
				justify-content: center;
			}
			@include mixins.bdown(sm) {
				flex-basis: 84%;
				column-gap: 0.5rem;
				justify-content: flex-end;
			}

			@include mixins.bdown(xs) {
				display: none;
			}
		}

		.btn {
			@include mixins.bdown(sm) {
				font-size: mixins.rem(14);
				padding-left: 0.8rem;
				padding-right: 0.8rem;
			}
			@include mixins.bdown(xs) {
				margin-top: 0.5rem;
			}
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			height: 1px;
			background-color: rgba($color: variables.$white, $alpha: 0.36);
			width: mixins.rem(90);
			@include mixins.bdown(hg) {
				width: mixins.rem(25);
			}
			@include mixins.bdown(nm) {
				width: 0;
			}
		}
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			height: 1px;
			background-color: rgba($color: variables.$white, $alpha: 0.36);
			width: calc(100% - 393px);
			@include mixins.bdown(hg) {
				width: calc(100% - 320px);
			}
			@include mixins.bdown(nm) {
				width: calc(100%);
			}
		}
	}

	&-bottom {
		position: relative;
		z-index: 10;
		padding-top: 1rem;
		padding-bottom: 0.5rem;
	}

	&-social {
		display: flex;
		@include mixins.bdown(xs) {
			display: none;
		}
		a {
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			color: variables.$white;
			font-size: mixins.rem(14);
			@include mixins.bdown(xxs) {
				padding-right: 0.3rem;
				padding-left: 0.3rem;
			}
		}
	}

	&-phone {
		color: variables.$white;
		font-size: mixins.rem(14);
		font-weight: 300;
		text-align: right;
		margin-right: 1rem;
		@include mixins.letter-spacing(10);
		@include mixins.bdown(sm) {
			margin-right: 0;
		}
		i {
			font-size: mixins.rem(26);
			color: variables.$green;
			@include mixins.bdown(sm) {
				font-size: mixins.rem(30);
			}
		}
		span {
			font-size: mixins.rem(26);
			font-family: variables.$rubik;
			font-weight: 500;
			@include mixins.bdown(lg) {
				display: none;
			}
			@include mixins.bdown(nm) {
				display: inline;
				font-size: mixins.rem(23);
			}
			@include mixins.bdown(sm) {
				display: none;
			}
		}
		p {
			margin-bottom: 0;
			@include mixins.bdown(lg) {
				display: none;
			}
			@include mixins.bdown(nm) {
				display: block;
			}
			@include mixins.bdown(sm) {
				display: none;
			}
		}
	}

	&-logo {
		padding-left: 6rem;
		@include mixins.bdown(hg) {
			padding-left: 2rem;
		}
		@include mixins.bdown(nm) {
			padding-left: 0;
		}
		a {
			display: block;
			img {
				height: mixins.rem(68);
				@include mixins.bdown(xs) {
					height: mixins.rem(45);
				}
			}
		}
	}

	nav {
		color: variables.$white;
		font-family: variables.$rubik;
		font-size: mixins.rem(19);
		@include mixins.bdown(hg) {
			font-size: mixins.rem(16);
		}
		@include mixins.bdown(lg) {
			font-size: mixins.rem(15);
		}
		ul {
			display: flex;
			justify-content: center;
			margin: 0;
			@include mixins.bup(hd) {
				justify-content: space-between;
			}
			a {
				margin: 0.5rem;
				text-shadow: 0px 3px 6px rgba($color: variables.$black, $alpha: 0.16);
				border-bottom: 3px solid transparent;
				border-radius: 1px;
				&:hover,
				&.active {
					color: variables.$red;
					border-color: variables.$lightgreen;
				}
				@include mixins.bdown(lg) {
					margin-left: 0.3rem;
					margin-right: 0.3rem;
				}
			}
		}
		@include mixins.bdown(nm) {
			flex: 1 1 100%;
			display: none;
			margin-left: calc(-15px - 1%);
			margin-right: calc(-15px - 1%);
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			z-index: 20;
			color: variables.$base;
			ul {
				min-height: calc(100vh - 141px);
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;
				@include mixins.bdown(sm) {
					min-height: calc(100vh - 155px);
				}
				a {
					width: 100%;
					font-size: 18px;
					font-weight: 700;
					text-align: center;
				}
			}
		}
	}

	&-mobile {
		display: none;
		column-gap: 0.5rem;
		justify-content: space-between;
		margin-bottom: 1rem;
		.btn {
			font-size: mixins.rem(12);
		}
		@include mixins.bdown(xs) {
			display: flex;
		}
	}

	&-hamburger {
		backface-visibility: hidden;
		background-color: transparent;
		border: none;
		cursor: pointer;
		display: flex;
		padding: 0;
		margin-left: auto;
		&:focus {
			outline: none;
		}
		@include mixins.bup(nm) {
			display: none;
		}
		svg {
			height: 52px;
			width: auto;
		}
		.line {
			fill: none;
			stroke: variables.$lightgreen;
			stroke-width: 6;
			transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
				stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
			&1 {
				stroke-dasharray: 60 207;
				stroke-width: 6;
			}
			&2 {
				stroke-dasharray: 60 60;
				stroke-width: 6;
			}
			&3 {
				stroke-dasharray: 60 207;
				stroke-width: 6;
			}
		}
		&.opened {
			.line {
				&1 {
					stroke-dasharray: 90 207;
					stroke-dashoffset: -134;
					stroke-width: 6;
				}
				&2 {
					stroke-dasharray: 1 60;
					stroke-dashoffset: -30;
					stroke-width: 6;
				}
				&3 {
					stroke-dasharray: 90 207;
					stroke-dashoffset: -134;
					stroke-width: 6;
				}
			}
		}
	}
}
