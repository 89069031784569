@use "../components/mixins";
@use "../components/variables";

form {
	position: relative;

	input,
	select,
	textarea {
		&.has-danger {
			border-bottom: 1px solid #f00 !important;
			// border-color: #f00 !important;
		}
	}

	.input-wrapper {
		&.has-danger {
			border-color: #f00 !important;

			&:before,
			&:after {
				background-color: #f00 !important;
			}
		}
	}

	input[type="radio"].has-danger,
	input[type="checkbox"].has-danger {
		&+label {
			&:before {
				border-color: #f00 !important;
			}
		}
	}

	::placeholder {
		color: variables.$gray;
	}

	input,
	select,
	textarea {
		font-size: 1rem;
		color: variables.$base;
		font-family: inherit;
		padding: 1em 2em;
	}

	input,
	textarea,
	select,
	.select-wrapper {
		width: 100%;
		border: 1px solid variables.$gray;
	}

	input {
		min-height: 3rem;
	}

	.select-wrapper {
		min-height: 3rem;
		position: relative;

		&:before {
			position: absolute;
			right: 1em;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.5em;
			z-index: 10;
			color: variables.$purple;
			pointer-events: none;
		}

		&:after {
			position: absolute;
			display: block;
			content: "";
			right: 0;
			top: 0;
			width: 1.6em;
			height: 100%;
			font-size: 2em;
			z-index: 5;
			pointer-events: none;
		}
	}

	select {
		min-height: 3rem;
		width: 100%;
		border-radius: 0;

		&:disabled {
			color: variables.$gray;
		}

		option [disabled="disabled"] {
			color: variables.$gray;
		}
	}

	p {
		&.question {
			@include mixins.font-count(24, 33);
		}

		&.c-info {
			@include mixins.letter-spacing(100);
			@include mixins.font-count(16, 24);
		}
	}

	label {
		text-align: left;
		display: block;
		padding-bottom: 0.2em;

		&.file_upload {
			display: inline-flex;
			align-items: flex-start;

			input[type="file"] {
				position: absolute;
				visibility: hidden;
				opacity: 0;
				display: none;
			}

			mark {
				background-color: transparent;
				color: #fff;
				opacity: 1;
				font-size: 1rem;
				margin-left: 0.5em;
				@include mixins.transition;
			}

			.btn {
				cursor: pointer;

				&.empty {
					color: #fff;
				}

				&:not(.empty):hover {
					&+mark {
						opacity: 0;
					}
				}
			}
		}
	}

	.row {
		padding-top: 0px;
		padding-bottom: 0px;

		.col {
			&.file-upload-container {
				text-align: left;

				&.has-danger {
					border: 1px solid #f00;

					mark {
						color: #f00;
					}
				}
			}

			@include mixins.bdown(md) {
				margin-bottom: 15px;
			}
		}
	}

	.checkbox input {
		display: none;
	}

	.checkbox label {
		position: relative;
		padding-left: 4em;
		line-height: 3em;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			width: 3em;
			height: 3em;
			background-color: #fff;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			padding-left: 0.4em;
			color: variables.$purple;
			@include mixins.font-count(30, 48);
		}
	}

	.checkbox input:checked+label {
		&:after {
			content: "V";
		}
	}

	&.newsletter-form {
		overflow: hidden;
		margin-left: auto;
		margin-right: auto;
		max-width: 420px;
		position: relative;
		padding: 3rem 2rem;
		border-top-left-radius: 70px;
		background-color: variables.$lightgreen;
		box-shadow: 0 0 23px rgba($color: variables.$black, $alpha: 0.15);
		transform: translateY(-30%);

		&::before {
			content: url(../../images/icons/plane.svg);
			position: absolute;
			left: 0.5rem;
			top: 2.5rem;
		}

		@include mixins.bdown(sm) {
			transform: translateY(0);
			margin-bottom: 2rem;
			margin-top: 1rem;
		}

		input {
			position: relative;
			z-index: 5;
			border-radius: 5px;
			border-color: white;
			height: mixins.rem(53);
		}

		::placeholder {
			font-size: mixins.rem(16);
		}
	}

	&.mailSign-form {
		position: relative;
		padding: 2rem;
		max-width: 530px;
		margin-left: auto;

		@include mixins.bdown(sm) {
			transform: translateY(0);
			margin-bottom: 2rem;
			margin-top: 1rem;
		}

		input {
			position: relative;
			z-index: 5;
			border-radius: 5px;
			border-color: white;
			height: mixins.rem(53);
		}

		::placeholder {
			font-size: mixins.rem(16);
		}
	}

	&.champion-form {
		display: flex;
		column-gap: 1rem;

		@include mixins.bdown(sm) {
			flex-direction: column;
		}

		.champion-form-container {
			display: flex;
			flex-basis: 60%;

			@include mixins.bdown(sm) {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}

			@include mixins.bdown(xs) {
				display: block;
			}

			.champion-btn {
				font-family: variables.$jost;
				font-size: mixins.rem(28);
				font-weight: 700;
				background-color: variables.$green;
				color: variables.$white;
				border: none;
				outline: none;
				flex-basis: 50%;
				border-top-right-radius: 20px;
				border-bottom-right-radius: 20px;
				@include mixins.letter-spacing(50);

				@include mixins.bdown(sm) {
					font-size: mixins.rem(20);
				}

				@include mixins.bdown(xs) {
					width: 100%;
					min-height: 3rem;
					border-radius: 20px;
				}
			}
		}

		::placeholder {
			font-family: variables.$jost;
			font-size: mixins.rem(28);
			font-weight: 700;
			@include mixins.letter-spacing(50);

			@include mixins.bdown(sm) {
				font-size: mixins.rem(20);
			}
		}

		select {
			position: relative;
			font-family: variables.$jost;
			font-size: mixins.rem(28);
			font-weight: 700;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			padding: 1rem;
			border: 1px solid variables.$green;
			appearance: none;
			height: 100%;
			@include mixins.letter-spacing(50);

			@include mixins.bdown(sm) {
				font-size: mixins.rem(20);
			}

			@include mixins.bdown(xs) {
				margin-bottom: 1rem;
				border-radius: 20px;
			}

			&:focus {
				outline: none;
			}
		}

		.select-wrapper {
			border: none;
			min-height: auto;

			&::after {
				content: "\e913";
				font-family: variables.$icomoon;
				position: absolute;
				top: 50%;
				right: 1rem;
				transform: translateY(-15%);
				pointer-events: none;
				font-size: mixins.rem(20);

				@include mixins.bdown(xs) {
					font-size: mixins.rem(16);
					right: 1.5rem;
					transform: translateY(-20%);
				}
			}
		}

		input {
			flex-basis: 40%;
			padding: 1rem;
			border: 1px solid variables.$green;
			border-radius: 20px;
			font-family: variables.$jost;
			font-size: mixins.rem(28);
			font-weight: 700;
			@include mixins.letter-spacing(50);

			@include mixins.bdown(sm) {
				font-size: mixins.rem(20);
			}
		}
	}

	&.contact-form {
		textarea {
			padding: 1.5rem 3rem 1.5rem 1.5rem;
			border-color: #c6c6c6;

			&:focus {
				border-color: variables.$red;
			}
		}

		.contact-form-wrapper {
			position: relative;
		}

		input {
			min-height: mixins.rem(70);
			margin-bottom: 0;
			padding-right: 2.5rem;
			border-color: #c6c6c6;

			&:focus {
				border-color: variables.$red;
			}
		}

		.contact-form-icon {
			position: absolute;
			right: 1.5rem;
			top: 50%;
			transform: translateY(-50%);
		}

		.contact-form-area {
			position: absolute;
			right: 2.5rem;
			top: 1.5rem;
		}
	}

	&.news-form {
		display: flex;

		input {
			border: none;
			padding: 1rem;

		}

		::placeholder {
			color: rgba($color: #474747, $alpha: 0.3);
		}

		.news-form-btn {
			width: mixins.rem(66);
			height: mixins.rem(61);
			background-color: #F15B26;
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;

			&::after {
				content: "\e906";
				font-family: variables.$icomoon;
				color: variables.$white;
			}
		}
	}

	&.meeting-form {
		display: flex;
		max-width: mixins.rem(835);
		margin-left: auto;
		margin-right: auto;

		input {
			border-color: variables.$green;
			padding: 1rem;
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			font-family: variables.$jost;
			font-weight: 700;
			font-size: mixins.rem(28);
			@include mixins.letter-spacing(50);

			@include mixins.bdown(xs) {
				font-size: mixins.rem(20);
				height: mixins.rem(60);
			}
		}

		.meeting-form-btn {
			flex-shrink: 0;
			width: mixins.rem(219);
			height: mixins.rem(84);
			background-color: variables.$green;
			color: variables.$white;
			font-family: variables.$jost;
			font-weight: 700;
			font-size: mixins.rem(28);
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;
			@include mixins.letter-spacing(50);

			@include mixins.bdown(sm) {
				flex-shrink: 1;
			}

			@include mixins.bdown(xs) {
				font-size: mixins.rem(20);
				height: mixins.rem(60);
			}
		}
	}
}

.thankyou-url {
	display: none;

	&.submitted {
		display: block;
		max-height: 0.1px;
		overflow: hidden;
	}
}