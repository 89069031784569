@import "vendor/slick.css";
@import "vendor/all.min.css";
@import "vendor/fancybox.css";
@import "vendor/toastr.css";
@import "vendor/justifiedGallery.min.css";
@import "vendor/icomoon.css";
.basis-5 {
  flex-basis: 5%;
}

@media screen and (min-width: 991px) {
  .sized-5 {
    max-width: 5%;
  }
  .basis-5 {
    max-width: 5%;
  }
  .sized-dt-5,
  .basis-dt-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-5,
  .basis-ls-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-5,
  .sized-mh-5 {
    max-width: 5%;
  }
  .basis-mobile-h-5,
  .basis-mh-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-5,
  .sized-mv-5 {
    max-width: 5%;
  }
  .basis-mobile-v-5,
  .basis-mv-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
.basis-10 {
  flex-basis: 10%;
}

@media screen and (min-width: 991px) {
  .sized-10 {
    max-width: 10%;
  }
  .basis-10 {
    max-width: 10%;
  }
  .sized-dt-10,
  .basis-dt-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-10,
  .basis-ls-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-10,
  .sized-mh-10 {
    max-width: 10%;
  }
  .basis-mobile-h-10,
  .basis-mh-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-10,
  .sized-mv-10 {
    max-width: 10%;
  }
  .basis-mobile-v-10,
  .basis-mv-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
.basis-15 {
  flex-basis: 15%;
}

@media screen and (min-width: 991px) {
  .sized-15 {
    max-width: 15%;
  }
  .basis-15 {
    max-width: 15%;
  }
  .sized-dt-15,
  .basis-dt-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-15,
  .basis-ls-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-15,
  .sized-mh-15 {
    max-width: 15%;
  }
  .basis-mobile-h-15,
  .basis-mh-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-15,
  .sized-mv-15 {
    max-width: 15%;
  }
  .basis-mobile-v-15,
  .basis-mv-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
.basis-20 {
  flex-basis: 20%;
}

@media screen and (min-width: 991px) {
  .sized-20 {
    max-width: 20%;
  }
  .basis-20 {
    max-width: 20%;
  }
  .sized-dt-20,
  .basis-dt-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-20,
  .basis-ls-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-20,
  .sized-mh-20 {
    max-width: 20%;
  }
  .basis-mobile-h-20,
  .basis-mh-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-20,
  .sized-mv-20 {
    max-width: 20%;
  }
  .basis-mobile-v-20,
  .basis-mv-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
.basis-25 {
  flex-basis: 25%;
}

@media screen and (min-width: 991px) {
  .sized-25 {
    max-width: 25%;
  }
  .basis-25 {
    max-width: 25%;
  }
  .sized-dt-25,
  .basis-dt-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-25,
  .basis-ls-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-25,
  .sized-mh-25 {
    max-width: 25%;
  }
  .basis-mobile-h-25,
  .basis-mh-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-25,
  .sized-mv-25 {
    max-width: 25%;
  }
  .basis-mobile-v-25,
  .basis-mv-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
.basis-30 {
  flex-basis: 30%;
}

@media screen and (min-width: 991px) {
  .sized-30 {
    max-width: 30%;
  }
  .basis-30 {
    max-width: 30%;
  }
  .sized-dt-30,
  .basis-dt-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-30,
  .basis-ls-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-30,
  .sized-mh-30 {
    max-width: 30%;
  }
  .basis-mobile-h-30,
  .basis-mh-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-30,
  .sized-mv-30 {
    max-width: 30%;
  }
  .basis-mobile-v-30,
  .basis-mv-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
.basis-35 {
  flex-basis: 35%;
}

@media screen and (min-width: 991px) {
  .sized-35 {
    max-width: 35%;
  }
  .basis-35 {
    max-width: 35%;
  }
  .sized-dt-35,
  .basis-dt-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-35,
  .basis-ls-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-35,
  .sized-mh-35 {
    max-width: 35%;
  }
  .basis-mobile-h-35,
  .basis-mh-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-35,
  .sized-mv-35 {
    max-width: 35%;
  }
  .basis-mobile-v-35,
  .basis-mv-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
.basis-40 {
  flex-basis: 40%;
}

@media screen and (min-width: 991px) {
  .sized-40 {
    max-width: 40%;
  }
  .basis-40 {
    max-width: 40%;
  }
  .sized-dt-40,
  .basis-dt-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-40,
  .basis-ls-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-40,
  .sized-mh-40 {
    max-width: 40%;
  }
  .basis-mobile-h-40,
  .basis-mh-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-40,
  .sized-mv-40 {
    max-width: 40%;
  }
  .basis-mobile-v-40,
  .basis-mv-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
.basis-45 {
  flex-basis: 45%;
}

@media screen and (min-width: 991px) {
  .sized-45 {
    max-width: 45%;
  }
  .basis-45 {
    max-width: 45%;
  }
  .sized-dt-45,
  .basis-dt-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-45,
  .basis-ls-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-45,
  .sized-mh-45 {
    max-width: 45%;
  }
  .basis-mobile-h-45,
  .basis-mh-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-45,
  .sized-mv-45 {
    max-width: 45%;
  }
  .basis-mobile-v-45,
  .basis-mv-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
.basis-50 {
  flex-basis: 50%;
}

@media screen and (min-width: 991px) {
  .sized-50 {
    max-width: 50%;
  }
  .basis-50 {
    max-width: 50%;
  }
  .sized-dt-50,
  .basis-dt-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-50,
  .basis-ls-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-50,
  .sized-mh-50 {
    max-width: 50%;
  }
  .basis-mobile-h-50,
  .basis-mh-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-50,
  .sized-mv-50 {
    max-width: 50%;
  }
  .basis-mobile-v-50,
  .basis-mv-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
.basis-55 {
  flex-basis: 55%;
}

@media screen and (min-width: 991px) {
  .sized-55 {
    max-width: 55%;
  }
  .basis-55 {
    max-width: 55%;
  }
  .sized-dt-55,
  .basis-dt-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-55,
  .basis-ls-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-55,
  .sized-mh-55 {
    max-width: 55%;
  }
  .basis-mobile-h-55,
  .basis-mh-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-55,
  .sized-mv-55 {
    max-width: 55%;
  }
  .basis-mobile-v-55,
  .basis-mv-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
.basis-60 {
  flex-basis: 60%;
}

@media screen and (min-width: 991px) {
  .sized-60 {
    max-width: 60%;
  }
  .basis-60 {
    max-width: 60%;
  }
  .sized-dt-60,
  .basis-dt-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-60,
  .basis-ls-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-60,
  .sized-mh-60 {
    max-width: 60%;
  }
  .basis-mobile-h-60,
  .basis-mh-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-60,
  .sized-mv-60 {
    max-width: 60%;
  }
  .basis-mobile-v-60,
  .basis-mv-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
.basis-65 {
  flex-basis: 65%;
}

@media screen and (min-width: 991px) {
  .sized-65 {
    max-width: 65%;
  }
  .basis-65 {
    max-width: 65%;
  }
  .sized-dt-65,
  .basis-dt-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-65,
  .basis-ls-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-65,
  .sized-mh-65 {
    max-width: 65%;
  }
  .basis-mobile-h-65,
  .basis-mh-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-65,
  .sized-mv-65 {
    max-width: 65%;
  }
  .basis-mobile-v-65,
  .basis-mv-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
.basis-70 {
  flex-basis: 70%;
}

@media screen and (min-width: 991px) {
  .sized-70 {
    max-width: 70%;
  }
  .basis-70 {
    max-width: 70%;
  }
  .sized-dt-70,
  .basis-dt-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-70,
  .basis-ls-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-70,
  .sized-mh-70 {
    max-width: 70%;
  }
  .basis-mobile-h-70,
  .basis-mh-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-70,
  .sized-mv-70 {
    max-width: 70%;
  }
  .basis-mobile-v-70,
  .basis-mv-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
.basis-75 {
  flex-basis: 75%;
}

@media screen and (min-width: 991px) {
  .sized-75 {
    max-width: 75%;
  }
  .basis-75 {
    max-width: 75%;
  }
  .sized-dt-75,
  .basis-dt-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-75,
  .basis-ls-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-75,
  .sized-mh-75 {
    max-width: 75%;
  }
  .basis-mobile-h-75,
  .basis-mh-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-75,
  .sized-mv-75 {
    max-width: 75%;
  }
  .basis-mobile-v-75,
  .basis-mv-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
.basis-80 {
  flex-basis: 80%;
}

@media screen and (min-width: 991px) {
  .sized-80 {
    max-width: 80%;
  }
  .basis-80 {
    max-width: 80%;
  }
  .sized-dt-80,
  .basis-dt-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-80,
  .basis-ls-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-80,
  .sized-mh-80 {
    max-width: 80%;
  }
  .basis-mobile-h-80,
  .basis-mh-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-80,
  .sized-mv-80 {
    max-width: 80%;
  }
  .basis-mobile-v-80,
  .basis-mv-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
.basis-85 {
  flex-basis: 85%;
}

@media screen and (min-width: 991px) {
  .sized-85 {
    max-width: 85%;
  }
  .basis-85 {
    max-width: 85%;
  }
  .sized-dt-85,
  .basis-dt-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-85,
  .basis-ls-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-85,
  .sized-mh-85 {
    max-width: 85%;
  }
  .basis-mobile-h-85,
  .basis-mh-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-85,
  .sized-mv-85 {
    max-width: 85%;
  }
  .basis-mobile-v-85,
  .basis-mv-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
.basis-90 {
  flex-basis: 90%;
}

@media screen and (min-width: 991px) {
  .sized-90 {
    max-width: 90%;
  }
  .basis-90 {
    max-width: 90%;
  }
  .sized-dt-90,
  .basis-dt-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-90,
  .basis-ls-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-90,
  .sized-mh-90 {
    max-width: 90%;
  }
  .basis-mobile-h-90,
  .basis-mh-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-90,
  .sized-mv-90 {
    max-width: 90%;
  }
  .basis-mobile-v-90,
  .basis-mv-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
.basis-95 {
  flex-basis: 95%;
}

@media screen and (min-width: 991px) {
  .sized-95 {
    max-width: 95%;
  }
  .basis-95 {
    max-width: 95%;
  }
  .sized-dt-95,
  .basis-dt-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-95,
  .basis-ls-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-95,
  .sized-mh-95 {
    max-width: 95%;
  }
  .basis-mobile-h-95,
  .basis-mh-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-95,
  .sized-mv-95 {
    max-width: 95%;
  }
  .basis-mobile-v-95,
  .basis-mv-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
.basis-100 {
  flex-basis: 100%;
}

@media screen and (min-width: 991px) {
  .sized-100 {
    max-width: 100%;
  }
  .basis-100 {
    max-width: 100%;
  }
  .sized-dt-100,
  .basis-dt-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-100,
  .basis-ls-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-100,
  .sized-mh-100 {
    max-width: 100%;
  }
  .basis-mobile-h-100,
  .basis-mh-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-100,
  .sized-mv-100 {
    max-width: 100%;
  }
  .basis-mobile-v-100,
  .basis-mv-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.wrap-2 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-2 > * {
  flex: 0 0 calc(100% / 2);
  padding: 15px;
}

.wrap-3 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-3 > * {
  flex: 0 0 calc(100% / 3);
  padding: 15px;
}

.wrap-4 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-4 > * {
  flex: 0 0 calc(100% / 4);
  padding: 15px;
}

.wrap-5 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-5 > * {
  flex: 0 0 calc(100% / 5);
  padding: 15px;
}

.wrap-6 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-6 > * {
  flex: 0 0 calc(100% / 6);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-2 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-2 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-3 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-3 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-4 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-4 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-5 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-5 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-6 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-6 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-1 > *,
  .wrap-mh-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-1 > *,
  .wrap-mv-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-2 > *,
  .wrap-mh-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-2 > *,
  .wrap-mv-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-3 > *,
  .wrap-mh-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-3 > *,
  .wrap-mv-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-4 > *,
  .wrap-mh-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-4 > *,
  .wrap-mv-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-5 > *,
  .wrap-mh-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-5 > *,
  .wrap-mv-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-6 > *,
  .wrap-mh-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-6 > *,
  .wrap-mv-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
.col-gap-0 {
  column-gap: 0rem;
}

.col-gap-1 {
  column-gap: 1rem;
}

.col-gap-2 {
  column-gap: 2rem;
}

.col-gap-3 {
  column-gap: 3rem;
}

.col-gap-4 {
  column-gap: 4rem;
}

.col-gap-5 {
  column-gap: 5rem;
}

.m-0 {
  margin: 0rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.ms-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.mv-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.pt-0 {
  padding-top: 0rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.pr-0 {
  padding-right: 0rem;
}

.ps-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.pv-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.p-0 {
  padding: 0rem;
}

.row.gap-0 {
  gap: 0rem;
}

.column-count-0 {
  column-count: 0;
}

.m-1 {
  margin: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ms-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mv-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.ps-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pv-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-1 {
  padding: 1rem;
}

.row.gap-1 {
  gap: 1rem;
}

.column-count-1 {
  column-count: 1;
}

.m-2 {
  margin: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.ms-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mv-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.ps-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pv-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-2 {
  padding: 2rem;
}

.row.gap-2 {
  gap: 2rem;
}

.column-count-2 {
  column-count: 2;
}

.m-3 {
  margin: 3rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mr-3 {
  margin-right: 3rem;
}

.ms-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mv-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: 3rem;
}

.ps-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pv-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-3 {
  padding: 3rem;
}

.row.gap-3 {
  gap: 3rem;
}

.column-count-3 {
  column-count: 3;
}

.m-4 {
  margin: 4rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.ml-4 {
  margin-left: 4rem;
}

.mr-4 {
  margin-right: 4rem;
}

.ms-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mv-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pr-4 {
  padding-right: 4rem;
}

.ps-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pv-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-4 {
  padding: 4rem;
}

.row.gap-4 {
  gap: 4rem;
}

.column-count-4 {
  column-count: 4;
}

.m-5 {
  margin: 5rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-5 {
  margin-right: 5rem;
}

.ms-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mv-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pl-5 {
  padding-left: 5rem;
}

.pr-5 {
  padding-right: 5rem;
}

.ps-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.pv-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.p-5 {
  padding: 5rem;
}

.row.gap-5 {
  gap: 5rem;
}

.column-count-5 {
  column-count: 5;
}

.m-6 {
  margin: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.ml-6 {
  margin-left: 6rem;
}

.mr-6 {
  margin-right: 6rem;
}

.ms-6 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mv-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.pt-6 {
  padding-top: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.pl-6 {
  padding-left: 6rem;
}

.pr-6 {
  padding-right: 6rem;
}

.ps-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.pv-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.p-6 {
  padding: 6rem;
}

.row.gap-6 {
  gap: 6rem;
}

.column-count-6 {
  column-count: 6;
}

.m-7 {
  margin: 7rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mb-7 {
  margin-bottom: 7rem;
}

.ml-7 {
  margin-left: 7rem;
}

.mr-7 {
  margin-right: 7rem;
}

.ms-7 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.mv-7 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.pt-7 {
  padding-top: 7rem;
}

.pb-7 {
  padding-bottom: 7rem;
}

.pl-7 {
  padding-left: 7rem;
}

.pr-7 {
  padding-right: 7rem;
}

.ps-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.pv-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.p-7 {
  padding: 7rem;
}

.row.gap-7 {
  gap: 7rem;
}

.column-count-7 {
  column-count: 7;
}

.m-8 {
  margin: 8rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

.ml-8 {
  margin-left: 8rem;
}

.mr-8 {
  margin-right: 8rem;
}

.ms-8 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mv-8 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.pt-8 {
  padding-top: 8rem;
}

.pb-8 {
  padding-bottom: 8rem;
}

.pl-8 {
  padding-left: 8rem;
}

.pr-8 {
  padding-right: 8rem;
}

.ps-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.pv-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.p-8 {
  padding: 8rem;
}

.row.gap-8 {
  gap: 8rem;
}

.column-count-8 {
  column-count: 8;
}

.m-9 {
  margin: 9rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mb-9 {
  margin-bottom: 9rem;
}

.ml-9 {
  margin-left: 9rem;
}

.mr-9 {
  margin-right: 9rem;
}

.ms-9 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.mv-9 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.pt-9 {
  padding-top: 9rem;
}

.pb-9 {
  padding-bottom: 9rem;
}

.pl-9 {
  padding-left: 9rem;
}

.pr-9 {
  padding-right: 9rem;
}

.ps-9 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.pv-9 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.p-9 {
  padding: 9rem;
}

.row.gap-9 {
  gap: 9rem;
}

.column-count-9 {
  column-count: 9;
}

.m-10 {
  margin: 10rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.ml-10 {
  margin-left: 10rem;
}

.mr-10 {
  margin-right: 10rem;
}

.ms-10 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.mv-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.pt-10 {
  padding-top: 10rem;
}

.pb-10 {
  padding-bottom: 10rem;
}

.pl-10 {
  padding-left: 10rem;
}

.pr-10 {
  padding-right: 10rem;
}

.ps-10 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.pv-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.p-10 {
  padding: 10rem;
}

.row.gap-10 {
  gap: 10rem;
}

.column-count-10 {
  column-count: 10;
}

.mb--0 {
  margin-bottom: -0rem;
}

.mb--1 {
  margin-bottom: -1rem;
}

.mb--2 {
  margin-bottom: -2rem;
}

.mb--3 {
  margin-bottom: -3rem;
}

.mb--4 {
  margin-bottom: -4rem;
}

.mb--5 {
  margin-bottom: -5rem;
}

.mb--6 {
  margin-bottom: -6rem;
}

.mb--7 {
  margin-bottom: -7rem;
}

.mb--8 {
  margin-bottom: -8rem;
}

.mb--9 {
  margin-bottom: -9rem;
}

.mb--10 {
  margin-bottom: -10rem;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-0 {
    margin: 0rem;
  }
  .mt-mh-0 {
    margin-top: 0rem;
  }
  .mb-mh-0 {
    margin-bottom: 0rem;
  }
  .ml-mh-0 {
    margin-left: 0rem;
  }
  .mr-mh-0 {
    margin-right: 0rem;
  }
  .ms-mh-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mv-mh-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .pt-mh-0 {
    padding-top: 0rem;
  }
  .pb-mh-0 {
    padding-bottom: 0rem;
  }
  .pl-mh-0 {
    padding-left: 0rem;
  }
  .pr-mh-0 {
    padding-right: 0rem;
  }
  .ps-mh-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pv-mh-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .p-mh-0 {
    padding: 0rem;
  }
  .row.gap-mh-0 {
    gap: 0rem;
  }
  .column-count-mh-0 {
    column-count: 0;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-0 {
    margin: 0rem;
  }
  .mt-mv-0 {
    margin-top: 0rem;
  }
  .mb-mv-0 {
    margin-bottom: 0rem;
  }
  .ml-mv-0 {
    margin-left: 0rem;
  }
  .mr-mv-0 {
    margin-right: 0rem;
  }
  .ms-mv-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mv-mv-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .pt-mv-0 {
    padding-top: 0rem;
  }
  .pb-mv-0 {
    padding-bottom: 0rem;
  }
  .pl-mv-0 {
    padding-left: 0rem;
  }
  .pr-mv-0 {
    padding-right: 0rem;
  }
  .ps-mv-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pv-mv-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .p-mv-0 {
    padding: 0rem;
  }
  .row.gap-mv-0 {
    gap: 0rem;
  }
  .column-count-mv-0 {
    column-count: 0;
  }
}
@media screen and (min-width: 991px) {
  .rm-0,
  .m-dt-0 {
    margin: 0rem;
  }
  .rml-0,
  .ml-dt-0 {
    margin-left: 0rem;
  }
  .rmr-0,
  .mr-dt-0 {
    margin-right: 0rem;
  }
  .rmt-0,
  .mt-dt-0 {
    margin-top: 0rem;
  }
  .rmb-0,
  .mb-dt-0 {
    margin-bottom: 0rem;
  }
  .rmv-0,
  .mv-dt-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .rms-0,
  .ms-dt-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .rp-0,
  .p-dt-0 {
    padding: 0rem;
  }
  .rpl-0,
  .pl-dt-0 {
    padding-left: 0rem;
  }
  .rpr-0,
  .pr-dt-0 {
    padding-right: 0rem;
  }
  .rpt-0,
  .pt-dt-0 {
    padding-top: 0rem;
  }
  .rpb-0,
  .pb-dt-0 {
    padding-bottom: 0rem;
  }
  .rpv-0,
  .pv-dt-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .rps-0,
  .ps-dt-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .rcolumn-count-0,
  .column-count-dt-0 {
    column-count: 0;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-0 {
    margin: 0rem;
  }
  .mt-ls-0 {
    margin-top: 0rem;
  }
  .mb-ls-0 {
    margin-bottom: 0rem;
  }
  .ml-ls-0 {
    margin-left: 0rem;
  }
  .mr-ls-0 {
    margin-right: 0rem;
  }
  .ms-ls-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mv-ls-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .pt-ls-0 {
    padding-top: 0rem;
  }
  .pb-ls-0 {
    padding-bottom: 0rem;
  }
  .pl-ls-0 {
    padding-left: 0rem;
  }
  .pr-ls-0 {
    padding-right: 0rem;
  }
  .ps-ls-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pv-ls-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .p-ls-0 {
    padding: 0rem;
  }
  .row.gap-ls-0 {
    gap: 0rem;
  }
  .column-count-ls-0 {
    column-count: 0;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-1 {
    margin: 1rem;
  }
  .mt-mh-1 {
    margin-top: 1rem;
  }
  .mb-mh-1 {
    margin-bottom: 1rem;
  }
  .ml-mh-1 {
    margin-left: 1rem;
  }
  .mr-mh-1 {
    margin-right: 1rem;
  }
  .ms-mh-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mv-mh-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pt-mh-1 {
    padding-top: 1rem;
  }
  .pb-mh-1 {
    padding-bottom: 1rem;
  }
  .pl-mh-1 {
    padding-left: 1rem;
  }
  .pr-mh-1 {
    padding-right: 1rem;
  }
  .ps-mh-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pv-mh-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p-mh-1 {
    padding: 1rem;
  }
  .row.gap-mh-1 {
    gap: 1rem;
  }
  .column-count-mh-1 {
    column-count: 1;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-1 {
    margin: 1rem;
  }
  .mt-mv-1 {
    margin-top: 1rem;
  }
  .mb-mv-1 {
    margin-bottom: 1rem;
  }
  .ml-mv-1 {
    margin-left: 1rem;
  }
  .mr-mv-1 {
    margin-right: 1rem;
  }
  .ms-mv-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mv-mv-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pt-mv-1 {
    padding-top: 1rem;
  }
  .pb-mv-1 {
    padding-bottom: 1rem;
  }
  .pl-mv-1 {
    padding-left: 1rem;
  }
  .pr-mv-1 {
    padding-right: 1rem;
  }
  .ps-mv-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pv-mv-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p-mv-1 {
    padding: 1rem;
  }
  .row.gap-mv-1 {
    gap: 1rem;
  }
  .column-count-mv-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 991px) {
  .rm-1,
  .m-dt-1 {
    margin: 1rem;
  }
  .rml-1,
  .ml-dt-1 {
    margin-left: 1rem;
  }
  .rmr-1,
  .mr-dt-1 {
    margin-right: 1rem;
  }
  .rmt-1,
  .mt-dt-1 {
    margin-top: 1rem;
  }
  .rmb-1,
  .mb-dt-1 {
    margin-bottom: 1rem;
  }
  .rmv-1,
  .mv-dt-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .rms-1,
  .ms-dt-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .rp-1,
  .p-dt-1 {
    padding: 1rem;
  }
  .rpl-1,
  .pl-dt-1 {
    padding-left: 1rem;
  }
  .rpr-1,
  .pr-dt-1 {
    padding-right: 1rem;
  }
  .rpt-1,
  .pt-dt-1 {
    padding-top: 1rem;
  }
  .rpb-1,
  .pb-dt-1 {
    padding-bottom: 1rem;
  }
  .rpv-1,
  .pv-dt-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .rps-1,
  .ps-dt-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .rcolumn-count-1,
  .column-count-dt-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-1 {
    margin: 1rem;
  }
  .mt-ls-1 {
    margin-top: 1rem;
  }
  .mb-ls-1 {
    margin-bottom: 1rem;
  }
  .ml-ls-1 {
    margin-left: 1rem;
  }
  .mr-ls-1 {
    margin-right: 1rem;
  }
  .ms-ls-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mv-ls-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pt-ls-1 {
    padding-top: 1rem;
  }
  .pb-ls-1 {
    padding-bottom: 1rem;
  }
  .pl-ls-1 {
    padding-left: 1rem;
  }
  .pr-ls-1 {
    padding-right: 1rem;
  }
  .ps-ls-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pv-ls-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p-ls-1 {
    padding: 1rem;
  }
  .row.gap-ls-1 {
    gap: 1rem;
  }
  .column-count-ls-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-2 {
    margin: 2rem;
  }
  .mt-mh-2 {
    margin-top: 2rem;
  }
  .mb-mh-2 {
    margin-bottom: 2rem;
  }
  .ml-mh-2 {
    margin-left: 2rem;
  }
  .mr-mh-2 {
    margin-right: 2rem;
  }
  .ms-mh-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mv-mh-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pt-mh-2 {
    padding-top: 2rem;
  }
  .pb-mh-2 {
    padding-bottom: 2rem;
  }
  .pl-mh-2 {
    padding-left: 2rem;
  }
  .pr-mh-2 {
    padding-right: 2rem;
  }
  .ps-mh-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pv-mh-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p-mh-2 {
    padding: 2rem;
  }
  .row.gap-mh-2 {
    gap: 2rem;
  }
  .column-count-mh-2 {
    column-count: 2;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-2 {
    margin: 2rem;
  }
  .mt-mv-2 {
    margin-top: 2rem;
  }
  .mb-mv-2 {
    margin-bottom: 2rem;
  }
  .ml-mv-2 {
    margin-left: 2rem;
  }
  .mr-mv-2 {
    margin-right: 2rem;
  }
  .ms-mv-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mv-mv-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pt-mv-2 {
    padding-top: 2rem;
  }
  .pb-mv-2 {
    padding-bottom: 2rem;
  }
  .pl-mv-2 {
    padding-left: 2rem;
  }
  .pr-mv-2 {
    padding-right: 2rem;
  }
  .ps-mv-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pv-mv-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p-mv-2 {
    padding: 2rem;
  }
  .row.gap-mv-2 {
    gap: 2rem;
  }
  .column-count-mv-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 991px) {
  .rm-2,
  .m-dt-2 {
    margin: 2rem;
  }
  .rml-2,
  .ml-dt-2 {
    margin-left: 2rem;
  }
  .rmr-2,
  .mr-dt-2 {
    margin-right: 2rem;
  }
  .rmt-2,
  .mt-dt-2 {
    margin-top: 2rem;
  }
  .rmb-2,
  .mb-dt-2 {
    margin-bottom: 2rem;
  }
  .rmv-2,
  .mv-dt-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .rms-2,
  .ms-dt-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .rp-2,
  .p-dt-2 {
    padding: 2rem;
  }
  .rpl-2,
  .pl-dt-2 {
    padding-left: 2rem;
  }
  .rpr-2,
  .pr-dt-2 {
    padding-right: 2rem;
  }
  .rpt-2,
  .pt-dt-2 {
    padding-top: 2rem;
  }
  .rpb-2,
  .pb-dt-2 {
    padding-bottom: 2rem;
  }
  .rpv-2,
  .pv-dt-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .rps-2,
  .ps-dt-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .rcolumn-count-2,
  .column-count-dt-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-2 {
    margin: 2rem;
  }
  .mt-ls-2 {
    margin-top: 2rem;
  }
  .mb-ls-2 {
    margin-bottom: 2rem;
  }
  .ml-ls-2 {
    margin-left: 2rem;
  }
  .mr-ls-2 {
    margin-right: 2rem;
  }
  .ms-ls-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mv-ls-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pt-ls-2 {
    padding-top: 2rem;
  }
  .pb-ls-2 {
    padding-bottom: 2rem;
  }
  .pl-ls-2 {
    padding-left: 2rem;
  }
  .pr-ls-2 {
    padding-right: 2rem;
  }
  .ps-ls-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pv-ls-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p-ls-2 {
    padding: 2rem;
  }
  .row.gap-ls-2 {
    gap: 2rem;
  }
  .column-count-ls-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-3 {
    margin: 3rem;
  }
  .mt-mh-3 {
    margin-top: 3rem;
  }
  .mb-mh-3 {
    margin-bottom: 3rem;
  }
  .ml-mh-3 {
    margin-left: 3rem;
  }
  .mr-mh-3 {
    margin-right: 3rem;
  }
  .ms-mh-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mv-mh-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pt-mh-3 {
    padding-top: 3rem;
  }
  .pb-mh-3 {
    padding-bottom: 3rem;
  }
  .pl-mh-3 {
    padding-left: 3rem;
  }
  .pr-mh-3 {
    padding-right: 3rem;
  }
  .ps-mh-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pv-mh-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .p-mh-3 {
    padding: 3rem;
  }
  .row.gap-mh-3 {
    gap: 3rem;
  }
  .column-count-mh-3 {
    column-count: 3;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-3 {
    margin: 3rem;
  }
  .mt-mv-3 {
    margin-top: 3rem;
  }
  .mb-mv-3 {
    margin-bottom: 3rem;
  }
  .ml-mv-3 {
    margin-left: 3rem;
  }
  .mr-mv-3 {
    margin-right: 3rem;
  }
  .ms-mv-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mv-mv-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pt-mv-3 {
    padding-top: 3rem;
  }
  .pb-mv-3 {
    padding-bottom: 3rem;
  }
  .pl-mv-3 {
    padding-left: 3rem;
  }
  .pr-mv-3 {
    padding-right: 3rem;
  }
  .ps-mv-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pv-mv-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .p-mv-3 {
    padding: 3rem;
  }
  .row.gap-mv-3 {
    gap: 3rem;
  }
  .column-count-mv-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 991px) {
  .rm-3,
  .m-dt-3 {
    margin: 3rem;
  }
  .rml-3,
  .ml-dt-3 {
    margin-left: 3rem;
  }
  .rmr-3,
  .mr-dt-3 {
    margin-right: 3rem;
  }
  .rmt-3,
  .mt-dt-3 {
    margin-top: 3rem;
  }
  .rmb-3,
  .mb-dt-3 {
    margin-bottom: 3rem;
  }
  .rmv-3,
  .mv-dt-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .rms-3,
  .ms-dt-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .rp-3,
  .p-dt-3 {
    padding: 3rem;
  }
  .rpl-3,
  .pl-dt-3 {
    padding-left: 3rem;
  }
  .rpr-3,
  .pr-dt-3 {
    padding-right: 3rem;
  }
  .rpt-3,
  .pt-dt-3 {
    padding-top: 3rem;
  }
  .rpb-3,
  .pb-dt-3 {
    padding-bottom: 3rem;
  }
  .rpv-3,
  .pv-dt-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .rps-3,
  .ps-dt-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .rcolumn-count-3,
  .column-count-dt-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-3 {
    margin: 3rem;
  }
  .mt-ls-3 {
    margin-top: 3rem;
  }
  .mb-ls-3 {
    margin-bottom: 3rem;
  }
  .ml-ls-3 {
    margin-left: 3rem;
  }
  .mr-ls-3 {
    margin-right: 3rem;
  }
  .ms-ls-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mv-ls-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pt-ls-3 {
    padding-top: 3rem;
  }
  .pb-ls-3 {
    padding-bottom: 3rem;
  }
  .pl-ls-3 {
    padding-left: 3rem;
  }
  .pr-ls-3 {
    padding-right: 3rem;
  }
  .ps-ls-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pv-ls-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .p-ls-3 {
    padding: 3rem;
  }
  .row.gap-ls-3 {
    gap: 3rem;
  }
  .column-count-ls-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-4 {
    margin: 4rem;
  }
  .mt-mh-4 {
    margin-top: 4rem;
  }
  .mb-mh-4 {
    margin-bottom: 4rem;
  }
  .ml-mh-4 {
    margin-left: 4rem;
  }
  .mr-mh-4 {
    margin-right: 4rem;
  }
  .ms-mh-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv-mh-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pt-mh-4 {
    padding-top: 4rem;
  }
  .pb-mh-4 {
    padding-bottom: 4rem;
  }
  .pl-mh-4 {
    padding-left: 4rem;
  }
  .pr-mh-4 {
    padding-right: 4rem;
  }
  .ps-mh-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv-mh-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .p-mh-4 {
    padding: 4rem;
  }
  .row.gap-mh-4 {
    gap: 4rem;
  }
  .column-count-mh-4 {
    column-count: 4;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-4 {
    margin: 4rem;
  }
  .mt-mv-4 {
    margin-top: 4rem;
  }
  .mb-mv-4 {
    margin-bottom: 4rem;
  }
  .ml-mv-4 {
    margin-left: 4rem;
  }
  .mr-mv-4 {
    margin-right: 4rem;
  }
  .ms-mv-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv-mv-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pt-mv-4 {
    padding-top: 4rem;
  }
  .pb-mv-4 {
    padding-bottom: 4rem;
  }
  .pl-mv-4 {
    padding-left: 4rem;
  }
  .pr-mv-4 {
    padding-right: 4rem;
  }
  .ps-mv-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv-mv-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .p-mv-4 {
    padding: 4rem;
  }
  .row.gap-mv-4 {
    gap: 4rem;
  }
  .column-count-mv-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 991px) {
  .rm-4,
  .m-dt-4 {
    margin: 4rem;
  }
  .rml-4,
  .ml-dt-4 {
    margin-left: 4rem;
  }
  .rmr-4,
  .mr-dt-4 {
    margin-right: 4rem;
  }
  .rmt-4,
  .mt-dt-4 {
    margin-top: 4rem;
  }
  .rmb-4,
  .mb-dt-4 {
    margin-bottom: 4rem;
  }
  .rmv-4,
  .mv-dt-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .rms-4,
  .ms-dt-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .rp-4,
  .p-dt-4 {
    padding: 4rem;
  }
  .rpl-4,
  .pl-dt-4 {
    padding-left: 4rem;
  }
  .rpr-4,
  .pr-dt-4 {
    padding-right: 4rem;
  }
  .rpt-4,
  .pt-dt-4 {
    padding-top: 4rem;
  }
  .rpb-4,
  .pb-dt-4 {
    padding-bottom: 4rem;
  }
  .rpv-4,
  .pv-dt-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .rps-4,
  .ps-dt-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .rcolumn-count-4,
  .column-count-dt-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-4 {
    margin: 4rem;
  }
  .mt-ls-4 {
    margin-top: 4rem;
  }
  .mb-ls-4 {
    margin-bottom: 4rem;
  }
  .ml-ls-4 {
    margin-left: 4rem;
  }
  .mr-ls-4 {
    margin-right: 4rem;
  }
  .ms-ls-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv-ls-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pt-ls-4 {
    padding-top: 4rem;
  }
  .pb-ls-4 {
    padding-bottom: 4rem;
  }
  .pl-ls-4 {
    padding-left: 4rem;
  }
  .pr-ls-4 {
    padding-right: 4rem;
  }
  .ps-ls-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv-ls-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .p-ls-4 {
    padding: 4rem;
  }
  .row.gap-ls-4 {
    gap: 4rem;
  }
  .column-count-ls-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-5 {
    margin: 5rem;
  }
  .mt-mh-5 {
    margin-top: 5rem;
  }
  .mb-mh-5 {
    margin-bottom: 5rem;
  }
  .ml-mh-5 {
    margin-left: 5rem;
  }
  .mr-mh-5 {
    margin-right: 5rem;
  }
  .ms-mh-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mv-mh-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pt-mh-5 {
    padding-top: 5rem;
  }
  .pb-mh-5 {
    padding-bottom: 5rem;
  }
  .pl-mh-5 {
    padding-left: 5rem;
  }
  .pr-mh-5 {
    padding-right: 5rem;
  }
  .ps-mh-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .pv-mh-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .p-mh-5 {
    padding: 5rem;
  }
  .row.gap-mh-5 {
    gap: 5rem;
  }
  .column-count-mh-5 {
    column-count: 5;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-5 {
    margin: 5rem;
  }
  .mt-mv-5 {
    margin-top: 5rem;
  }
  .mb-mv-5 {
    margin-bottom: 5rem;
  }
  .ml-mv-5 {
    margin-left: 5rem;
  }
  .mr-mv-5 {
    margin-right: 5rem;
  }
  .ms-mv-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mv-mv-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pt-mv-5 {
    padding-top: 5rem;
  }
  .pb-mv-5 {
    padding-bottom: 5rem;
  }
  .pl-mv-5 {
    padding-left: 5rem;
  }
  .pr-mv-5 {
    padding-right: 5rem;
  }
  .ps-mv-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .pv-mv-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .p-mv-5 {
    padding: 5rem;
  }
  .row.gap-mv-5 {
    gap: 5rem;
  }
  .column-count-mv-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 991px) {
  .rm-5,
  .m-dt-5 {
    margin: 5rem;
  }
  .rml-5,
  .ml-dt-5 {
    margin-left: 5rem;
  }
  .rmr-5,
  .mr-dt-5 {
    margin-right: 5rem;
  }
  .rmt-5,
  .mt-dt-5 {
    margin-top: 5rem;
  }
  .rmb-5,
  .mb-dt-5 {
    margin-bottom: 5rem;
  }
  .rmv-5,
  .mv-dt-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .rms-5,
  .ms-dt-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .rp-5,
  .p-dt-5 {
    padding: 5rem;
  }
  .rpl-5,
  .pl-dt-5 {
    padding-left: 5rem;
  }
  .rpr-5,
  .pr-dt-5 {
    padding-right: 5rem;
  }
  .rpt-5,
  .pt-dt-5 {
    padding-top: 5rem;
  }
  .rpb-5,
  .pb-dt-5 {
    padding-bottom: 5rem;
  }
  .rpv-5,
  .pv-dt-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .rps-5,
  .ps-dt-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .rcolumn-count-5,
  .column-count-dt-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-5 {
    margin: 5rem;
  }
  .mt-ls-5 {
    margin-top: 5rem;
  }
  .mb-ls-5 {
    margin-bottom: 5rem;
  }
  .ml-ls-5 {
    margin-left: 5rem;
  }
  .mr-ls-5 {
    margin-right: 5rem;
  }
  .ms-ls-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mv-ls-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pt-ls-5 {
    padding-top: 5rem;
  }
  .pb-ls-5 {
    padding-bottom: 5rem;
  }
  .pl-ls-5 {
    padding-left: 5rem;
  }
  .pr-ls-5 {
    padding-right: 5rem;
  }
  .ps-ls-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .pv-ls-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .p-ls-5 {
    padding: 5rem;
  }
  .row.gap-ls-5 {
    gap: 5rem;
  }
  .column-count-ls-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-6 {
    margin: 6rem;
  }
  .mt-mh-6 {
    margin-top: 6rem;
  }
  .mb-mh-6 {
    margin-bottom: 6rem;
  }
  .ml-mh-6 {
    margin-left: 6rem;
  }
  .mr-mh-6 {
    margin-right: 6rem;
  }
  .ms-mh-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .mv-mh-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pt-mh-6 {
    padding-top: 6rem;
  }
  .pb-mh-6 {
    padding-bottom: 6rem;
  }
  .pl-mh-6 {
    padding-left: 6rem;
  }
  .pr-mh-6 {
    padding-right: 6rem;
  }
  .ps-mh-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .pv-mh-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .p-mh-6 {
    padding: 6rem;
  }
  .row.gap-mh-6 {
    gap: 6rem;
  }
  .column-count-mh-6 {
    column-count: 6;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-6 {
    margin: 6rem;
  }
  .mt-mv-6 {
    margin-top: 6rem;
  }
  .mb-mv-6 {
    margin-bottom: 6rem;
  }
  .ml-mv-6 {
    margin-left: 6rem;
  }
  .mr-mv-6 {
    margin-right: 6rem;
  }
  .ms-mv-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .mv-mv-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pt-mv-6 {
    padding-top: 6rem;
  }
  .pb-mv-6 {
    padding-bottom: 6rem;
  }
  .pl-mv-6 {
    padding-left: 6rem;
  }
  .pr-mv-6 {
    padding-right: 6rem;
  }
  .ps-mv-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .pv-mv-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .p-mv-6 {
    padding: 6rem;
  }
  .row.gap-mv-6 {
    gap: 6rem;
  }
  .column-count-mv-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 991px) {
  .rm-6,
  .m-dt-6 {
    margin: 6rem;
  }
  .rml-6,
  .ml-dt-6 {
    margin-left: 6rem;
  }
  .rmr-6,
  .mr-dt-6 {
    margin-right: 6rem;
  }
  .rmt-6,
  .mt-dt-6 {
    margin-top: 6rem;
  }
  .rmb-6,
  .mb-dt-6 {
    margin-bottom: 6rem;
  }
  .rmv-6,
  .mv-dt-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .rms-6,
  .ms-dt-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .rp-6,
  .p-dt-6 {
    padding: 6rem;
  }
  .rpl-6,
  .pl-dt-6 {
    padding-left: 6rem;
  }
  .rpr-6,
  .pr-dt-6 {
    padding-right: 6rem;
  }
  .rpt-6,
  .pt-dt-6 {
    padding-top: 6rem;
  }
  .rpb-6,
  .pb-dt-6 {
    padding-bottom: 6rem;
  }
  .rpv-6,
  .pv-dt-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .rps-6,
  .ps-dt-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .rcolumn-count-6,
  .column-count-dt-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-6 {
    margin: 6rem;
  }
  .mt-ls-6 {
    margin-top: 6rem;
  }
  .mb-ls-6 {
    margin-bottom: 6rem;
  }
  .ml-ls-6 {
    margin-left: 6rem;
  }
  .mr-ls-6 {
    margin-right: 6rem;
  }
  .ms-ls-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .mv-ls-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pt-ls-6 {
    padding-top: 6rem;
  }
  .pb-ls-6 {
    padding-bottom: 6rem;
  }
  .pl-ls-6 {
    padding-left: 6rem;
  }
  .pr-ls-6 {
    padding-right: 6rem;
  }
  .ps-ls-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .pv-ls-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .p-ls-6 {
    padding: 6rem;
  }
  .row.gap-ls-6 {
    gap: 6rem;
  }
  .column-count-ls-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-7 {
    margin: 7rem;
  }
  .mt-mh-7 {
    margin-top: 7rem;
  }
  .mb-mh-7 {
    margin-bottom: 7rem;
  }
  .ml-mh-7 {
    margin-left: 7rem;
  }
  .mr-mh-7 {
    margin-right: 7rem;
  }
  .ms-mh-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .mv-mh-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pt-mh-7 {
    padding-top: 7rem;
  }
  .pb-mh-7 {
    padding-bottom: 7rem;
  }
  .pl-mh-7 {
    padding-left: 7rem;
  }
  .pr-mh-7 {
    padding-right: 7rem;
  }
  .ps-mh-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .pv-mh-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-mh-7 {
    padding: 7rem;
  }
  .row.gap-mh-7 {
    gap: 7rem;
  }
  .column-count-mh-7 {
    column-count: 7;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-7 {
    margin: 7rem;
  }
  .mt-mv-7 {
    margin-top: 7rem;
  }
  .mb-mv-7 {
    margin-bottom: 7rem;
  }
  .ml-mv-7 {
    margin-left: 7rem;
  }
  .mr-mv-7 {
    margin-right: 7rem;
  }
  .ms-mv-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .mv-mv-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pt-mv-7 {
    padding-top: 7rem;
  }
  .pb-mv-7 {
    padding-bottom: 7rem;
  }
  .pl-mv-7 {
    padding-left: 7rem;
  }
  .pr-mv-7 {
    padding-right: 7rem;
  }
  .ps-mv-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .pv-mv-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-mv-7 {
    padding: 7rem;
  }
  .row.gap-mv-7 {
    gap: 7rem;
  }
  .column-count-mv-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 991px) {
  .rm-7,
  .m-dt-7 {
    margin: 7rem;
  }
  .rml-7,
  .ml-dt-7 {
    margin-left: 7rem;
  }
  .rmr-7,
  .mr-dt-7 {
    margin-right: 7rem;
  }
  .rmt-7,
  .mt-dt-7 {
    margin-top: 7rem;
  }
  .rmb-7,
  .mb-dt-7 {
    margin-bottom: 7rem;
  }
  .rmv-7,
  .mv-dt-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .rms-7,
  .ms-dt-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .rp-7,
  .p-dt-7 {
    padding: 7rem;
  }
  .rpl-7,
  .pl-dt-7 {
    padding-left: 7rem;
  }
  .rpr-7,
  .pr-dt-7 {
    padding-right: 7rem;
  }
  .rpt-7,
  .pt-dt-7 {
    padding-top: 7rem;
  }
  .rpb-7,
  .pb-dt-7 {
    padding-bottom: 7rem;
  }
  .rpv-7,
  .pv-dt-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .rps-7,
  .ps-dt-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .rcolumn-count-7,
  .column-count-dt-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-7 {
    margin: 7rem;
  }
  .mt-ls-7 {
    margin-top: 7rem;
  }
  .mb-ls-7 {
    margin-bottom: 7rem;
  }
  .ml-ls-7 {
    margin-left: 7rem;
  }
  .mr-ls-7 {
    margin-right: 7rem;
  }
  .ms-ls-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .mv-ls-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pt-ls-7 {
    padding-top: 7rem;
  }
  .pb-ls-7 {
    padding-bottom: 7rem;
  }
  .pl-ls-7 {
    padding-left: 7rem;
  }
  .pr-ls-7 {
    padding-right: 7rem;
  }
  .ps-ls-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .pv-ls-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-ls-7 {
    padding: 7rem;
  }
  .row.gap-ls-7 {
    gap: 7rem;
  }
  .column-count-ls-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-8 {
    margin: 8rem;
  }
  .mt-mh-8 {
    margin-top: 8rem;
  }
  .mb-mh-8 {
    margin-bottom: 8rem;
  }
  .ml-mh-8 {
    margin-left: 8rem;
  }
  .mr-mh-8 {
    margin-right: 8rem;
  }
  .ms-mh-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mv-mh-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pt-mh-8 {
    padding-top: 8rem;
  }
  .pb-mh-8 {
    padding-bottom: 8rem;
  }
  .pl-mh-8 {
    padding-left: 8rem;
  }
  .pr-mh-8 {
    padding-right: 8rem;
  }
  .ps-mh-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pv-mh-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .p-mh-8 {
    padding: 8rem;
  }
  .row.gap-mh-8 {
    gap: 8rem;
  }
  .column-count-mh-8 {
    column-count: 8;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-8 {
    margin: 8rem;
  }
  .mt-mv-8 {
    margin-top: 8rem;
  }
  .mb-mv-8 {
    margin-bottom: 8rem;
  }
  .ml-mv-8 {
    margin-left: 8rem;
  }
  .mr-mv-8 {
    margin-right: 8rem;
  }
  .ms-mv-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mv-mv-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pt-mv-8 {
    padding-top: 8rem;
  }
  .pb-mv-8 {
    padding-bottom: 8rem;
  }
  .pl-mv-8 {
    padding-left: 8rem;
  }
  .pr-mv-8 {
    padding-right: 8rem;
  }
  .ps-mv-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pv-mv-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .p-mv-8 {
    padding: 8rem;
  }
  .row.gap-mv-8 {
    gap: 8rem;
  }
  .column-count-mv-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 991px) {
  .rm-8,
  .m-dt-8 {
    margin: 8rem;
  }
  .rml-8,
  .ml-dt-8 {
    margin-left: 8rem;
  }
  .rmr-8,
  .mr-dt-8 {
    margin-right: 8rem;
  }
  .rmt-8,
  .mt-dt-8 {
    margin-top: 8rem;
  }
  .rmb-8,
  .mb-dt-8 {
    margin-bottom: 8rem;
  }
  .rmv-8,
  .mv-dt-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .rms-8,
  .ms-dt-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .rp-8,
  .p-dt-8 {
    padding: 8rem;
  }
  .rpl-8,
  .pl-dt-8 {
    padding-left: 8rem;
  }
  .rpr-8,
  .pr-dt-8 {
    padding-right: 8rem;
  }
  .rpt-8,
  .pt-dt-8 {
    padding-top: 8rem;
  }
  .rpb-8,
  .pb-dt-8 {
    padding-bottom: 8rem;
  }
  .rpv-8,
  .pv-dt-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .rps-8,
  .ps-dt-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .rcolumn-count-8,
  .column-count-dt-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-8 {
    margin: 8rem;
  }
  .mt-ls-8 {
    margin-top: 8rem;
  }
  .mb-ls-8 {
    margin-bottom: 8rem;
  }
  .ml-ls-8 {
    margin-left: 8rem;
  }
  .mr-ls-8 {
    margin-right: 8rem;
  }
  .ms-ls-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mv-ls-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pt-ls-8 {
    padding-top: 8rem;
  }
  .pb-ls-8 {
    padding-bottom: 8rem;
  }
  .pl-ls-8 {
    padding-left: 8rem;
  }
  .pr-ls-8 {
    padding-right: 8rem;
  }
  .ps-ls-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pv-ls-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .p-ls-8 {
    padding: 8rem;
  }
  .row.gap-ls-8 {
    gap: 8rem;
  }
  .column-count-ls-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-9 {
    margin: 9rem;
  }
  .mt-mh-9 {
    margin-top: 9rem;
  }
  .mb-mh-9 {
    margin-bottom: 9rem;
  }
  .ml-mh-9 {
    margin-left: 9rem;
  }
  .mr-mh-9 {
    margin-right: 9rem;
  }
  .ms-mh-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .mv-mh-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pt-mh-9 {
    padding-top: 9rem;
  }
  .pb-mh-9 {
    padding-bottom: 9rem;
  }
  .pl-mh-9 {
    padding-left: 9rem;
  }
  .pr-mh-9 {
    padding-right: 9rem;
  }
  .ps-mh-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .pv-mh-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .p-mh-9 {
    padding: 9rem;
  }
  .row.gap-mh-9 {
    gap: 9rem;
  }
  .column-count-mh-9 {
    column-count: 9;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-9 {
    margin: 9rem;
  }
  .mt-mv-9 {
    margin-top: 9rem;
  }
  .mb-mv-9 {
    margin-bottom: 9rem;
  }
  .ml-mv-9 {
    margin-left: 9rem;
  }
  .mr-mv-9 {
    margin-right: 9rem;
  }
  .ms-mv-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .mv-mv-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pt-mv-9 {
    padding-top: 9rem;
  }
  .pb-mv-9 {
    padding-bottom: 9rem;
  }
  .pl-mv-9 {
    padding-left: 9rem;
  }
  .pr-mv-9 {
    padding-right: 9rem;
  }
  .ps-mv-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .pv-mv-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .p-mv-9 {
    padding: 9rem;
  }
  .row.gap-mv-9 {
    gap: 9rem;
  }
  .column-count-mv-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 991px) {
  .rm-9,
  .m-dt-9 {
    margin: 9rem;
  }
  .rml-9,
  .ml-dt-9 {
    margin-left: 9rem;
  }
  .rmr-9,
  .mr-dt-9 {
    margin-right: 9rem;
  }
  .rmt-9,
  .mt-dt-9 {
    margin-top: 9rem;
  }
  .rmb-9,
  .mb-dt-9 {
    margin-bottom: 9rem;
  }
  .rmv-9,
  .mv-dt-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .rms-9,
  .ms-dt-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .rp-9,
  .p-dt-9 {
    padding: 9rem;
  }
  .rpl-9,
  .pl-dt-9 {
    padding-left: 9rem;
  }
  .rpr-9,
  .pr-dt-9 {
    padding-right: 9rem;
  }
  .rpt-9,
  .pt-dt-9 {
    padding-top: 9rem;
  }
  .rpb-9,
  .pb-dt-9 {
    padding-bottom: 9rem;
  }
  .rpv-9,
  .pv-dt-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .rps-9,
  .ps-dt-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .rcolumn-count-9,
  .column-count-dt-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-9 {
    margin: 9rem;
  }
  .mt-ls-9 {
    margin-top: 9rem;
  }
  .mb-ls-9 {
    margin-bottom: 9rem;
  }
  .ml-ls-9 {
    margin-left: 9rem;
  }
  .mr-ls-9 {
    margin-right: 9rem;
  }
  .ms-ls-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .mv-ls-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pt-ls-9 {
    padding-top: 9rem;
  }
  .pb-ls-9 {
    padding-bottom: 9rem;
  }
  .pl-ls-9 {
    padding-left: 9rem;
  }
  .pr-ls-9 {
    padding-right: 9rem;
  }
  .ps-ls-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .pv-ls-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .p-ls-9 {
    padding: 9rem;
  }
  .row.gap-ls-9 {
    gap: 9rem;
  }
  .column-count-ls-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-10 {
    margin: 10rem;
  }
  .mt-mh-10 {
    margin-top: 10rem;
  }
  .mb-mh-10 {
    margin-bottom: 10rem;
  }
  .ml-mh-10 {
    margin-left: 10rem;
  }
  .mr-mh-10 {
    margin-right: 10rem;
  }
  .ms-mh-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mv-mh-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pt-mh-10 {
    padding-top: 10rem;
  }
  .pb-mh-10 {
    padding-bottom: 10rem;
  }
  .pl-mh-10 {
    padding-left: 10rem;
  }
  .pr-mh-10 {
    padding-right: 10rem;
  }
  .ps-mh-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .pv-mh-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .p-mh-10 {
    padding: 10rem;
  }
  .row.gap-mh-10 {
    gap: 10rem;
  }
  .column-count-mh-10 {
    column-count: 10;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-10 {
    margin: 10rem;
  }
  .mt-mv-10 {
    margin-top: 10rem;
  }
  .mb-mv-10 {
    margin-bottom: 10rem;
  }
  .ml-mv-10 {
    margin-left: 10rem;
  }
  .mr-mv-10 {
    margin-right: 10rem;
  }
  .ms-mv-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mv-mv-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pt-mv-10 {
    padding-top: 10rem;
  }
  .pb-mv-10 {
    padding-bottom: 10rem;
  }
  .pl-mv-10 {
    padding-left: 10rem;
  }
  .pr-mv-10 {
    padding-right: 10rem;
  }
  .ps-mv-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .pv-mv-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .p-mv-10 {
    padding: 10rem;
  }
  .row.gap-mv-10 {
    gap: 10rem;
  }
  .column-count-mv-10 {
    column-count: 10;
  }
}
@media screen and (min-width: 991px) {
  .rm-10,
  .m-dt-10 {
    margin: 10rem;
  }
  .rml-10,
  .ml-dt-10 {
    margin-left: 10rem;
  }
  .rmr-10,
  .mr-dt-10 {
    margin-right: 10rem;
  }
  .rmt-10,
  .mt-dt-10 {
    margin-top: 10rem;
  }
  .rmb-10,
  .mb-dt-10 {
    margin-bottom: 10rem;
  }
  .rmv-10,
  .mv-dt-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .rms-10,
  .ms-dt-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .rp-10,
  .p-dt-10 {
    padding: 10rem;
  }
  .rpl-10,
  .pl-dt-10 {
    padding-left: 10rem;
  }
  .rpr-10,
  .pr-dt-10 {
    padding-right: 10rem;
  }
  .rpt-10,
  .pt-dt-10 {
    padding-top: 10rem;
  }
  .rpb-10,
  .pb-dt-10 {
    padding-bottom: 10rem;
  }
  .rpv-10,
  .pv-dt-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .rps-10,
  .ps-dt-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .rcolumn-count-10,
  .column-count-dt-10 {
    column-count: 10;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-10 {
    margin: 10rem;
  }
  .mt-ls-10 {
    margin-top: 10rem;
  }
  .mb-ls-10 {
    margin-bottom: 10rem;
  }
  .ml-ls-10 {
    margin-left: 10rem;
  }
  .mr-ls-10 {
    margin-right: 10rem;
  }
  .ms-ls-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mv-ls-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pt-ls-10 {
    padding-top: 10rem;
  }
  .pb-ls-10 {
    padding-bottom: 10rem;
  }
  .pl-ls-10 {
    padding-left: 10rem;
  }
  .pr-ls-10 {
    padding-right: 10rem;
  }
  .ps-ls-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .pv-ls-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .p-ls-10 {
    padding: 10rem;
  }
  .row.gap-ls-10 {
    gap: 10rem;
  }
  .column-count-ls-10 {
    column-count: 10;
  }
}
.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}

html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: inherit;
}

ul li {
  list-style-type: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

img,
embed,
iframe,
object,
audio,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

a {
  color: inherit;
}

a:not(:disabled), a:not(.disabled), button:not(:disabled), button:not(.disabled) {
  cursor: pointer;
}

button {
  line-height: inherit;
  font-size: inherit;
}

textarea:focus, input:focus {
  outline: none;
}

.basis-5 {
  flex-basis: 5%;
}

@media screen and (min-width: 991px) {
  .sized-5 {
    max-width: 5%;
  }
  .basis-5 {
    max-width: 5%;
  }
  .sized-dt-5,
  .basis-dt-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-5,
  .basis-ls-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-5,
  .sized-mh-5 {
    max-width: 5%;
  }
  .basis-mobile-h-5,
  .basis-mh-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-5,
  .sized-mv-5 {
    max-width: 5%;
  }
  .basis-mobile-v-5,
  .basis-mv-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
.basis-10 {
  flex-basis: 10%;
}

@media screen and (min-width: 991px) {
  .sized-10 {
    max-width: 10%;
  }
  .basis-10 {
    max-width: 10%;
  }
  .sized-dt-10,
  .basis-dt-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-10,
  .basis-ls-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-10,
  .sized-mh-10 {
    max-width: 10%;
  }
  .basis-mobile-h-10,
  .basis-mh-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-10,
  .sized-mv-10 {
    max-width: 10%;
  }
  .basis-mobile-v-10,
  .basis-mv-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
.basis-15 {
  flex-basis: 15%;
}

@media screen and (min-width: 991px) {
  .sized-15 {
    max-width: 15%;
  }
  .basis-15 {
    max-width: 15%;
  }
  .sized-dt-15,
  .basis-dt-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-15,
  .basis-ls-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-15,
  .sized-mh-15 {
    max-width: 15%;
  }
  .basis-mobile-h-15,
  .basis-mh-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-15,
  .sized-mv-15 {
    max-width: 15%;
  }
  .basis-mobile-v-15,
  .basis-mv-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
.basis-20 {
  flex-basis: 20%;
}

@media screen and (min-width: 991px) {
  .sized-20 {
    max-width: 20%;
  }
  .basis-20 {
    max-width: 20%;
  }
  .sized-dt-20,
  .basis-dt-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-20,
  .basis-ls-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-20,
  .sized-mh-20 {
    max-width: 20%;
  }
  .basis-mobile-h-20,
  .basis-mh-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-20,
  .sized-mv-20 {
    max-width: 20%;
  }
  .basis-mobile-v-20,
  .basis-mv-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
.basis-25 {
  flex-basis: 25%;
}

@media screen and (min-width: 991px) {
  .sized-25 {
    max-width: 25%;
  }
  .basis-25 {
    max-width: 25%;
  }
  .sized-dt-25,
  .basis-dt-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-25,
  .basis-ls-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-25,
  .sized-mh-25 {
    max-width: 25%;
  }
  .basis-mobile-h-25,
  .basis-mh-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-25,
  .sized-mv-25 {
    max-width: 25%;
  }
  .basis-mobile-v-25,
  .basis-mv-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
.basis-30 {
  flex-basis: 30%;
}

@media screen and (min-width: 991px) {
  .sized-30 {
    max-width: 30%;
  }
  .basis-30 {
    max-width: 30%;
  }
  .sized-dt-30,
  .basis-dt-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-30,
  .basis-ls-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-30,
  .sized-mh-30 {
    max-width: 30%;
  }
  .basis-mobile-h-30,
  .basis-mh-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-30,
  .sized-mv-30 {
    max-width: 30%;
  }
  .basis-mobile-v-30,
  .basis-mv-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
.basis-35 {
  flex-basis: 35%;
}

@media screen and (min-width: 991px) {
  .sized-35 {
    max-width: 35%;
  }
  .basis-35 {
    max-width: 35%;
  }
  .sized-dt-35,
  .basis-dt-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-35,
  .basis-ls-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-35,
  .sized-mh-35 {
    max-width: 35%;
  }
  .basis-mobile-h-35,
  .basis-mh-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-35,
  .sized-mv-35 {
    max-width: 35%;
  }
  .basis-mobile-v-35,
  .basis-mv-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
.basis-40 {
  flex-basis: 40%;
}

@media screen and (min-width: 991px) {
  .sized-40 {
    max-width: 40%;
  }
  .basis-40 {
    max-width: 40%;
  }
  .sized-dt-40,
  .basis-dt-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-40,
  .basis-ls-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-40,
  .sized-mh-40 {
    max-width: 40%;
  }
  .basis-mobile-h-40,
  .basis-mh-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-40,
  .sized-mv-40 {
    max-width: 40%;
  }
  .basis-mobile-v-40,
  .basis-mv-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
.basis-45 {
  flex-basis: 45%;
}

@media screen and (min-width: 991px) {
  .sized-45 {
    max-width: 45%;
  }
  .basis-45 {
    max-width: 45%;
  }
  .sized-dt-45,
  .basis-dt-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-45,
  .basis-ls-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-45,
  .sized-mh-45 {
    max-width: 45%;
  }
  .basis-mobile-h-45,
  .basis-mh-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-45,
  .sized-mv-45 {
    max-width: 45%;
  }
  .basis-mobile-v-45,
  .basis-mv-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
.basis-50 {
  flex-basis: 50%;
}

@media screen and (min-width: 991px) {
  .sized-50 {
    max-width: 50%;
  }
  .basis-50 {
    max-width: 50%;
  }
  .sized-dt-50,
  .basis-dt-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-50,
  .basis-ls-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-50,
  .sized-mh-50 {
    max-width: 50%;
  }
  .basis-mobile-h-50,
  .basis-mh-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-50,
  .sized-mv-50 {
    max-width: 50%;
  }
  .basis-mobile-v-50,
  .basis-mv-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
.basis-55 {
  flex-basis: 55%;
}

@media screen and (min-width: 991px) {
  .sized-55 {
    max-width: 55%;
  }
  .basis-55 {
    max-width: 55%;
  }
  .sized-dt-55,
  .basis-dt-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-55,
  .basis-ls-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-55,
  .sized-mh-55 {
    max-width: 55%;
  }
  .basis-mobile-h-55,
  .basis-mh-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-55,
  .sized-mv-55 {
    max-width: 55%;
  }
  .basis-mobile-v-55,
  .basis-mv-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
.basis-60 {
  flex-basis: 60%;
}

@media screen and (min-width: 991px) {
  .sized-60 {
    max-width: 60%;
  }
  .basis-60 {
    max-width: 60%;
  }
  .sized-dt-60,
  .basis-dt-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-60,
  .basis-ls-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-60,
  .sized-mh-60 {
    max-width: 60%;
  }
  .basis-mobile-h-60,
  .basis-mh-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-60,
  .sized-mv-60 {
    max-width: 60%;
  }
  .basis-mobile-v-60,
  .basis-mv-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
.basis-65 {
  flex-basis: 65%;
}

@media screen and (min-width: 991px) {
  .sized-65 {
    max-width: 65%;
  }
  .basis-65 {
    max-width: 65%;
  }
  .sized-dt-65,
  .basis-dt-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-65,
  .basis-ls-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-65,
  .sized-mh-65 {
    max-width: 65%;
  }
  .basis-mobile-h-65,
  .basis-mh-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-65,
  .sized-mv-65 {
    max-width: 65%;
  }
  .basis-mobile-v-65,
  .basis-mv-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
.basis-70 {
  flex-basis: 70%;
}

@media screen and (min-width: 991px) {
  .sized-70 {
    max-width: 70%;
  }
  .basis-70 {
    max-width: 70%;
  }
  .sized-dt-70,
  .basis-dt-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-70,
  .basis-ls-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-70,
  .sized-mh-70 {
    max-width: 70%;
  }
  .basis-mobile-h-70,
  .basis-mh-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-70,
  .sized-mv-70 {
    max-width: 70%;
  }
  .basis-mobile-v-70,
  .basis-mv-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
.basis-75 {
  flex-basis: 75%;
}

@media screen and (min-width: 991px) {
  .sized-75 {
    max-width: 75%;
  }
  .basis-75 {
    max-width: 75%;
  }
  .sized-dt-75,
  .basis-dt-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-75,
  .basis-ls-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-75,
  .sized-mh-75 {
    max-width: 75%;
  }
  .basis-mobile-h-75,
  .basis-mh-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-75,
  .sized-mv-75 {
    max-width: 75%;
  }
  .basis-mobile-v-75,
  .basis-mv-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
.basis-80 {
  flex-basis: 80%;
}

@media screen and (min-width: 991px) {
  .sized-80 {
    max-width: 80%;
  }
  .basis-80 {
    max-width: 80%;
  }
  .sized-dt-80,
  .basis-dt-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-80,
  .basis-ls-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-80,
  .sized-mh-80 {
    max-width: 80%;
  }
  .basis-mobile-h-80,
  .basis-mh-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-80,
  .sized-mv-80 {
    max-width: 80%;
  }
  .basis-mobile-v-80,
  .basis-mv-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
.basis-85 {
  flex-basis: 85%;
}

@media screen and (min-width: 991px) {
  .sized-85 {
    max-width: 85%;
  }
  .basis-85 {
    max-width: 85%;
  }
  .sized-dt-85,
  .basis-dt-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-85,
  .basis-ls-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-85,
  .sized-mh-85 {
    max-width: 85%;
  }
  .basis-mobile-h-85,
  .basis-mh-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-85,
  .sized-mv-85 {
    max-width: 85%;
  }
  .basis-mobile-v-85,
  .basis-mv-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
.basis-90 {
  flex-basis: 90%;
}

@media screen and (min-width: 991px) {
  .sized-90 {
    max-width: 90%;
  }
  .basis-90 {
    max-width: 90%;
  }
  .sized-dt-90,
  .basis-dt-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-90,
  .basis-ls-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-90,
  .sized-mh-90 {
    max-width: 90%;
  }
  .basis-mobile-h-90,
  .basis-mh-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-90,
  .sized-mv-90 {
    max-width: 90%;
  }
  .basis-mobile-v-90,
  .basis-mv-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
.basis-95 {
  flex-basis: 95%;
}

@media screen and (min-width: 991px) {
  .sized-95 {
    max-width: 95%;
  }
  .basis-95 {
    max-width: 95%;
  }
  .sized-dt-95,
  .basis-dt-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-95,
  .basis-ls-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-95,
  .sized-mh-95 {
    max-width: 95%;
  }
  .basis-mobile-h-95,
  .basis-mh-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-95,
  .sized-mv-95 {
    max-width: 95%;
  }
  .basis-mobile-v-95,
  .basis-mv-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
.basis-100 {
  flex-basis: 100%;
}

@media screen and (min-width: 991px) {
  .sized-100 {
    max-width: 100%;
  }
  .basis-100 {
    max-width: 100%;
  }
  .sized-dt-100,
  .basis-dt-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-100,
  .basis-ls-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-100,
  .sized-mh-100 {
    max-width: 100%;
  }
  .basis-mobile-h-100,
  .basis-mh-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-100,
  .sized-mv-100 {
    max-width: 100%;
  }
  .basis-mobile-v-100,
  .basis-mv-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.wrap-2 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-2 > * {
  flex: 0 0 calc(100% / 2);
  padding: 15px;
}

.wrap-3 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-3 > * {
  flex: 0 0 calc(100% / 3);
  padding: 15px;
}

.wrap-4 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-4 > * {
  flex: 0 0 calc(100% / 4);
  padding: 15px;
}

.wrap-5 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-5 > * {
  flex: 0 0 calc(100% / 5);
  padding: 15px;
}

.wrap-6 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-6 > * {
  flex: 0 0 calc(100% / 6);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-2 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-2 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-3 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-3 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-4 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-4 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-5 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-5 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-6 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-6 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-1 > *,
  .wrap-mh-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-1 > *,
  .wrap-mv-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-2 > *,
  .wrap-mh-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-2 > *,
  .wrap-mv-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-3 > *,
  .wrap-mh-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-3 > *,
  .wrap-mv-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-4 > *,
  .wrap-mh-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-4 > *,
  .wrap-mv-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-5 > *,
  .wrap-mh-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-5 > *,
  .wrap-mv-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-6 > *,
  .wrap-mh-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-6 > *,
  .wrap-mv-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
.col-gap-0 {
  column-gap: 0rem;
}

.col-gap-1 {
  column-gap: 1rem;
}

.col-gap-2 {
  column-gap: 2rem;
}

.col-gap-3 {
  column-gap: 3rem;
}

.col-gap-4 {
  column-gap: 4rem;
}

.col-gap-5 {
  column-gap: 5rem;
}

.m-0 {
  margin: 0rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.ms-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.mv-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.pt-0 {
  padding-top: 0rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.pr-0 {
  padding-right: 0rem;
}

.ps-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.pv-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.p-0 {
  padding: 0rem;
}

.row.gap-0 {
  gap: 0rem;
}

.column-count-0 {
  column-count: 0;
}

.m-1 {
  margin: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ms-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mv-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.ps-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pv-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-1 {
  padding: 1rem;
}

.row.gap-1 {
  gap: 1rem;
}

.column-count-1 {
  column-count: 1;
}

.m-2 {
  margin: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.ms-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mv-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.ps-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pv-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-2 {
  padding: 2rem;
}

.row.gap-2 {
  gap: 2rem;
}

.column-count-2 {
  column-count: 2;
}

.m-3 {
  margin: 3rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mr-3 {
  margin-right: 3rem;
}

.ms-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mv-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: 3rem;
}

.ps-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pv-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-3 {
  padding: 3rem;
}

.row.gap-3 {
  gap: 3rem;
}

.column-count-3 {
  column-count: 3;
}

.m-4 {
  margin: 4rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.ml-4 {
  margin-left: 4rem;
}

.mr-4 {
  margin-right: 4rem;
}

.ms-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mv-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pr-4 {
  padding-right: 4rem;
}

.ps-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pv-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-4 {
  padding: 4rem;
}

.row.gap-4 {
  gap: 4rem;
}

.column-count-4 {
  column-count: 4;
}

.m-5 {
  margin: 5rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-5 {
  margin-right: 5rem;
}

.ms-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mv-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pl-5 {
  padding-left: 5rem;
}

.pr-5 {
  padding-right: 5rem;
}

.ps-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.pv-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.p-5 {
  padding: 5rem;
}

.row.gap-5 {
  gap: 5rem;
}

.column-count-5 {
  column-count: 5;
}

.m-6 {
  margin: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.ml-6 {
  margin-left: 6rem;
}

.mr-6 {
  margin-right: 6rem;
}

.ms-6 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mv-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.pt-6 {
  padding-top: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.pl-6 {
  padding-left: 6rem;
}

.pr-6 {
  padding-right: 6rem;
}

.ps-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.pv-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.p-6 {
  padding: 6rem;
}

.row.gap-6 {
  gap: 6rem;
}

.column-count-6 {
  column-count: 6;
}

.m-7 {
  margin: 7rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mb-7 {
  margin-bottom: 7rem;
}

.ml-7 {
  margin-left: 7rem;
}

.mr-7 {
  margin-right: 7rem;
}

.ms-7 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.mv-7 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.pt-7 {
  padding-top: 7rem;
}

.pb-7 {
  padding-bottom: 7rem;
}

.pl-7 {
  padding-left: 7rem;
}

.pr-7 {
  padding-right: 7rem;
}

.ps-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.pv-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.p-7 {
  padding: 7rem;
}

.row.gap-7 {
  gap: 7rem;
}

.column-count-7 {
  column-count: 7;
}

.m-8 {
  margin: 8rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

.ml-8 {
  margin-left: 8rem;
}

.mr-8 {
  margin-right: 8rem;
}

.ms-8 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mv-8 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.pt-8 {
  padding-top: 8rem;
}

.pb-8 {
  padding-bottom: 8rem;
}

.pl-8 {
  padding-left: 8rem;
}

.pr-8 {
  padding-right: 8rem;
}

.ps-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.pv-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.p-8 {
  padding: 8rem;
}

.row.gap-8 {
  gap: 8rem;
}

.column-count-8 {
  column-count: 8;
}

.m-9 {
  margin: 9rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mb-9 {
  margin-bottom: 9rem;
}

.ml-9 {
  margin-left: 9rem;
}

.mr-9 {
  margin-right: 9rem;
}

.ms-9 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.mv-9 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.pt-9 {
  padding-top: 9rem;
}

.pb-9 {
  padding-bottom: 9rem;
}

.pl-9 {
  padding-left: 9rem;
}

.pr-9 {
  padding-right: 9rem;
}

.ps-9 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.pv-9 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.p-9 {
  padding: 9rem;
}

.row.gap-9 {
  gap: 9rem;
}

.column-count-9 {
  column-count: 9;
}

.m-10 {
  margin: 10rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.ml-10 {
  margin-left: 10rem;
}

.mr-10 {
  margin-right: 10rem;
}

.ms-10 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.mv-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.pt-10 {
  padding-top: 10rem;
}

.pb-10 {
  padding-bottom: 10rem;
}

.pl-10 {
  padding-left: 10rem;
}

.pr-10 {
  padding-right: 10rem;
}

.ps-10 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.pv-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.p-10 {
  padding: 10rem;
}

.row.gap-10 {
  gap: 10rem;
}

.column-count-10 {
  column-count: 10;
}

.mb--0 {
  margin-bottom: -0rem;
}

.mb--1 {
  margin-bottom: -1rem;
}

.mb--2 {
  margin-bottom: -2rem;
}

.mb--3 {
  margin-bottom: -3rem;
}

.mb--4 {
  margin-bottom: -4rem;
}

.mb--5 {
  margin-bottom: -5rem;
}

.mb--6 {
  margin-bottom: -6rem;
}

.mb--7 {
  margin-bottom: -7rem;
}

.mb--8 {
  margin-bottom: -8rem;
}

.mb--9 {
  margin-bottom: -9rem;
}

.mb--10 {
  margin-bottom: -10rem;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-0 {
    margin: 0rem;
  }
  .mt-mh-0 {
    margin-top: 0rem;
  }
  .mb-mh-0 {
    margin-bottom: 0rem;
  }
  .ml-mh-0 {
    margin-left: 0rem;
  }
  .mr-mh-0 {
    margin-right: 0rem;
  }
  .ms-mh-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mv-mh-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .pt-mh-0 {
    padding-top: 0rem;
  }
  .pb-mh-0 {
    padding-bottom: 0rem;
  }
  .pl-mh-0 {
    padding-left: 0rem;
  }
  .pr-mh-0 {
    padding-right: 0rem;
  }
  .ps-mh-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pv-mh-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .p-mh-0 {
    padding: 0rem;
  }
  .row.gap-mh-0 {
    gap: 0rem;
  }
  .column-count-mh-0 {
    column-count: 0;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-0 {
    margin: 0rem;
  }
  .mt-mv-0 {
    margin-top: 0rem;
  }
  .mb-mv-0 {
    margin-bottom: 0rem;
  }
  .ml-mv-0 {
    margin-left: 0rem;
  }
  .mr-mv-0 {
    margin-right: 0rem;
  }
  .ms-mv-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mv-mv-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .pt-mv-0 {
    padding-top: 0rem;
  }
  .pb-mv-0 {
    padding-bottom: 0rem;
  }
  .pl-mv-0 {
    padding-left: 0rem;
  }
  .pr-mv-0 {
    padding-right: 0rem;
  }
  .ps-mv-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pv-mv-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .p-mv-0 {
    padding: 0rem;
  }
  .row.gap-mv-0 {
    gap: 0rem;
  }
  .column-count-mv-0 {
    column-count: 0;
  }
}
@media screen and (min-width: 991px) {
  .rm-0,
  .m-dt-0 {
    margin: 0rem;
  }
  .rml-0,
  .ml-dt-0 {
    margin-left: 0rem;
  }
  .rmr-0,
  .mr-dt-0 {
    margin-right: 0rem;
  }
  .rmt-0,
  .mt-dt-0 {
    margin-top: 0rem;
  }
  .rmb-0,
  .mb-dt-0 {
    margin-bottom: 0rem;
  }
  .rmv-0,
  .mv-dt-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .rms-0,
  .ms-dt-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .rp-0,
  .p-dt-0 {
    padding: 0rem;
  }
  .rpl-0,
  .pl-dt-0 {
    padding-left: 0rem;
  }
  .rpr-0,
  .pr-dt-0 {
    padding-right: 0rem;
  }
  .rpt-0,
  .pt-dt-0 {
    padding-top: 0rem;
  }
  .rpb-0,
  .pb-dt-0 {
    padding-bottom: 0rem;
  }
  .rpv-0,
  .pv-dt-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .rps-0,
  .ps-dt-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .rcolumn-count-0,
  .column-count-dt-0 {
    column-count: 0;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-0 {
    margin: 0rem;
  }
  .mt-ls-0 {
    margin-top: 0rem;
  }
  .mb-ls-0 {
    margin-bottom: 0rem;
  }
  .ml-ls-0 {
    margin-left: 0rem;
  }
  .mr-ls-0 {
    margin-right: 0rem;
  }
  .ms-ls-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mv-ls-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .pt-ls-0 {
    padding-top: 0rem;
  }
  .pb-ls-0 {
    padding-bottom: 0rem;
  }
  .pl-ls-0 {
    padding-left: 0rem;
  }
  .pr-ls-0 {
    padding-right: 0rem;
  }
  .ps-ls-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pv-ls-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .p-ls-0 {
    padding: 0rem;
  }
  .row.gap-ls-0 {
    gap: 0rem;
  }
  .column-count-ls-0 {
    column-count: 0;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-1 {
    margin: 1rem;
  }
  .mt-mh-1 {
    margin-top: 1rem;
  }
  .mb-mh-1 {
    margin-bottom: 1rem;
  }
  .ml-mh-1 {
    margin-left: 1rem;
  }
  .mr-mh-1 {
    margin-right: 1rem;
  }
  .ms-mh-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mv-mh-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pt-mh-1 {
    padding-top: 1rem;
  }
  .pb-mh-1 {
    padding-bottom: 1rem;
  }
  .pl-mh-1 {
    padding-left: 1rem;
  }
  .pr-mh-1 {
    padding-right: 1rem;
  }
  .ps-mh-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pv-mh-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p-mh-1 {
    padding: 1rem;
  }
  .row.gap-mh-1 {
    gap: 1rem;
  }
  .column-count-mh-1 {
    column-count: 1;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-1 {
    margin: 1rem;
  }
  .mt-mv-1 {
    margin-top: 1rem;
  }
  .mb-mv-1 {
    margin-bottom: 1rem;
  }
  .ml-mv-1 {
    margin-left: 1rem;
  }
  .mr-mv-1 {
    margin-right: 1rem;
  }
  .ms-mv-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mv-mv-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pt-mv-1 {
    padding-top: 1rem;
  }
  .pb-mv-1 {
    padding-bottom: 1rem;
  }
  .pl-mv-1 {
    padding-left: 1rem;
  }
  .pr-mv-1 {
    padding-right: 1rem;
  }
  .ps-mv-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pv-mv-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p-mv-1 {
    padding: 1rem;
  }
  .row.gap-mv-1 {
    gap: 1rem;
  }
  .column-count-mv-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 991px) {
  .rm-1,
  .m-dt-1 {
    margin: 1rem;
  }
  .rml-1,
  .ml-dt-1 {
    margin-left: 1rem;
  }
  .rmr-1,
  .mr-dt-1 {
    margin-right: 1rem;
  }
  .rmt-1,
  .mt-dt-1 {
    margin-top: 1rem;
  }
  .rmb-1,
  .mb-dt-1 {
    margin-bottom: 1rem;
  }
  .rmv-1,
  .mv-dt-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .rms-1,
  .ms-dt-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .rp-1,
  .p-dt-1 {
    padding: 1rem;
  }
  .rpl-1,
  .pl-dt-1 {
    padding-left: 1rem;
  }
  .rpr-1,
  .pr-dt-1 {
    padding-right: 1rem;
  }
  .rpt-1,
  .pt-dt-1 {
    padding-top: 1rem;
  }
  .rpb-1,
  .pb-dt-1 {
    padding-bottom: 1rem;
  }
  .rpv-1,
  .pv-dt-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .rps-1,
  .ps-dt-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .rcolumn-count-1,
  .column-count-dt-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-1 {
    margin: 1rem;
  }
  .mt-ls-1 {
    margin-top: 1rem;
  }
  .mb-ls-1 {
    margin-bottom: 1rem;
  }
  .ml-ls-1 {
    margin-left: 1rem;
  }
  .mr-ls-1 {
    margin-right: 1rem;
  }
  .ms-ls-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mv-ls-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pt-ls-1 {
    padding-top: 1rem;
  }
  .pb-ls-1 {
    padding-bottom: 1rem;
  }
  .pl-ls-1 {
    padding-left: 1rem;
  }
  .pr-ls-1 {
    padding-right: 1rem;
  }
  .ps-ls-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pv-ls-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p-ls-1 {
    padding: 1rem;
  }
  .row.gap-ls-1 {
    gap: 1rem;
  }
  .column-count-ls-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-2 {
    margin: 2rem;
  }
  .mt-mh-2 {
    margin-top: 2rem;
  }
  .mb-mh-2 {
    margin-bottom: 2rem;
  }
  .ml-mh-2 {
    margin-left: 2rem;
  }
  .mr-mh-2 {
    margin-right: 2rem;
  }
  .ms-mh-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mv-mh-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pt-mh-2 {
    padding-top: 2rem;
  }
  .pb-mh-2 {
    padding-bottom: 2rem;
  }
  .pl-mh-2 {
    padding-left: 2rem;
  }
  .pr-mh-2 {
    padding-right: 2rem;
  }
  .ps-mh-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pv-mh-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p-mh-2 {
    padding: 2rem;
  }
  .row.gap-mh-2 {
    gap: 2rem;
  }
  .column-count-mh-2 {
    column-count: 2;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-2 {
    margin: 2rem;
  }
  .mt-mv-2 {
    margin-top: 2rem;
  }
  .mb-mv-2 {
    margin-bottom: 2rem;
  }
  .ml-mv-2 {
    margin-left: 2rem;
  }
  .mr-mv-2 {
    margin-right: 2rem;
  }
  .ms-mv-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mv-mv-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pt-mv-2 {
    padding-top: 2rem;
  }
  .pb-mv-2 {
    padding-bottom: 2rem;
  }
  .pl-mv-2 {
    padding-left: 2rem;
  }
  .pr-mv-2 {
    padding-right: 2rem;
  }
  .ps-mv-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pv-mv-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p-mv-2 {
    padding: 2rem;
  }
  .row.gap-mv-2 {
    gap: 2rem;
  }
  .column-count-mv-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 991px) {
  .rm-2,
  .m-dt-2 {
    margin: 2rem;
  }
  .rml-2,
  .ml-dt-2 {
    margin-left: 2rem;
  }
  .rmr-2,
  .mr-dt-2 {
    margin-right: 2rem;
  }
  .rmt-2,
  .mt-dt-2 {
    margin-top: 2rem;
  }
  .rmb-2,
  .mb-dt-2 {
    margin-bottom: 2rem;
  }
  .rmv-2,
  .mv-dt-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .rms-2,
  .ms-dt-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .rp-2,
  .p-dt-2 {
    padding: 2rem;
  }
  .rpl-2,
  .pl-dt-2 {
    padding-left: 2rem;
  }
  .rpr-2,
  .pr-dt-2 {
    padding-right: 2rem;
  }
  .rpt-2,
  .pt-dt-2 {
    padding-top: 2rem;
  }
  .rpb-2,
  .pb-dt-2 {
    padding-bottom: 2rem;
  }
  .rpv-2,
  .pv-dt-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .rps-2,
  .ps-dt-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .rcolumn-count-2,
  .column-count-dt-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-2 {
    margin: 2rem;
  }
  .mt-ls-2 {
    margin-top: 2rem;
  }
  .mb-ls-2 {
    margin-bottom: 2rem;
  }
  .ml-ls-2 {
    margin-left: 2rem;
  }
  .mr-ls-2 {
    margin-right: 2rem;
  }
  .ms-ls-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mv-ls-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pt-ls-2 {
    padding-top: 2rem;
  }
  .pb-ls-2 {
    padding-bottom: 2rem;
  }
  .pl-ls-2 {
    padding-left: 2rem;
  }
  .pr-ls-2 {
    padding-right: 2rem;
  }
  .ps-ls-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pv-ls-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p-ls-2 {
    padding: 2rem;
  }
  .row.gap-ls-2 {
    gap: 2rem;
  }
  .column-count-ls-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-3 {
    margin: 3rem;
  }
  .mt-mh-3 {
    margin-top: 3rem;
  }
  .mb-mh-3 {
    margin-bottom: 3rem;
  }
  .ml-mh-3 {
    margin-left: 3rem;
  }
  .mr-mh-3 {
    margin-right: 3rem;
  }
  .ms-mh-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mv-mh-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pt-mh-3 {
    padding-top: 3rem;
  }
  .pb-mh-3 {
    padding-bottom: 3rem;
  }
  .pl-mh-3 {
    padding-left: 3rem;
  }
  .pr-mh-3 {
    padding-right: 3rem;
  }
  .ps-mh-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pv-mh-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .p-mh-3 {
    padding: 3rem;
  }
  .row.gap-mh-3 {
    gap: 3rem;
  }
  .column-count-mh-3 {
    column-count: 3;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-3 {
    margin: 3rem;
  }
  .mt-mv-3 {
    margin-top: 3rem;
  }
  .mb-mv-3 {
    margin-bottom: 3rem;
  }
  .ml-mv-3 {
    margin-left: 3rem;
  }
  .mr-mv-3 {
    margin-right: 3rem;
  }
  .ms-mv-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mv-mv-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pt-mv-3 {
    padding-top: 3rem;
  }
  .pb-mv-3 {
    padding-bottom: 3rem;
  }
  .pl-mv-3 {
    padding-left: 3rem;
  }
  .pr-mv-3 {
    padding-right: 3rem;
  }
  .ps-mv-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pv-mv-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .p-mv-3 {
    padding: 3rem;
  }
  .row.gap-mv-3 {
    gap: 3rem;
  }
  .column-count-mv-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 991px) {
  .rm-3,
  .m-dt-3 {
    margin: 3rem;
  }
  .rml-3,
  .ml-dt-3 {
    margin-left: 3rem;
  }
  .rmr-3,
  .mr-dt-3 {
    margin-right: 3rem;
  }
  .rmt-3,
  .mt-dt-3 {
    margin-top: 3rem;
  }
  .rmb-3,
  .mb-dt-3 {
    margin-bottom: 3rem;
  }
  .rmv-3,
  .mv-dt-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .rms-3,
  .ms-dt-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .rp-3,
  .p-dt-3 {
    padding: 3rem;
  }
  .rpl-3,
  .pl-dt-3 {
    padding-left: 3rem;
  }
  .rpr-3,
  .pr-dt-3 {
    padding-right: 3rem;
  }
  .rpt-3,
  .pt-dt-3 {
    padding-top: 3rem;
  }
  .rpb-3,
  .pb-dt-3 {
    padding-bottom: 3rem;
  }
  .rpv-3,
  .pv-dt-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .rps-3,
  .ps-dt-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .rcolumn-count-3,
  .column-count-dt-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-3 {
    margin: 3rem;
  }
  .mt-ls-3 {
    margin-top: 3rem;
  }
  .mb-ls-3 {
    margin-bottom: 3rem;
  }
  .ml-ls-3 {
    margin-left: 3rem;
  }
  .mr-ls-3 {
    margin-right: 3rem;
  }
  .ms-ls-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mv-ls-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pt-ls-3 {
    padding-top: 3rem;
  }
  .pb-ls-3 {
    padding-bottom: 3rem;
  }
  .pl-ls-3 {
    padding-left: 3rem;
  }
  .pr-ls-3 {
    padding-right: 3rem;
  }
  .ps-ls-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pv-ls-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .p-ls-3 {
    padding: 3rem;
  }
  .row.gap-ls-3 {
    gap: 3rem;
  }
  .column-count-ls-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-4 {
    margin: 4rem;
  }
  .mt-mh-4 {
    margin-top: 4rem;
  }
  .mb-mh-4 {
    margin-bottom: 4rem;
  }
  .ml-mh-4 {
    margin-left: 4rem;
  }
  .mr-mh-4 {
    margin-right: 4rem;
  }
  .ms-mh-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv-mh-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pt-mh-4 {
    padding-top: 4rem;
  }
  .pb-mh-4 {
    padding-bottom: 4rem;
  }
  .pl-mh-4 {
    padding-left: 4rem;
  }
  .pr-mh-4 {
    padding-right: 4rem;
  }
  .ps-mh-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv-mh-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .p-mh-4 {
    padding: 4rem;
  }
  .row.gap-mh-4 {
    gap: 4rem;
  }
  .column-count-mh-4 {
    column-count: 4;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-4 {
    margin: 4rem;
  }
  .mt-mv-4 {
    margin-top: 4rem;
  }
  .mb-mv-4 {
    margin-bottom: 4rem;
  }
  .ml-mv-4 {
    margin-left: 4rem;
  }
  .mr-mv-4 {
    margin-right: 4rem;
  }
  .ms-mv-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv-mv-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pt-mv-4 {
    padding-top: 4rem;
  }
  .pb-mv-4 {
    padding-bottom: 4rem;
  }
  .pl-mv-4 {
    padding-left: 4rem;
  }
  .pr-mv-4 {
    padding-right: 4rem;
  }
  .ps-mv-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv-mv-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .p-mv-4 {
    padding: 4rem;
  }
  .row.gap-mv-4 {
    gap: 4rem;
  }
  .column-count-mv-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 991px) {
  .rm-4,
  .m-dt-4 {
    margin: 4rem;
  }
  .rml-4,
  .ml-dt-4 {
    margin-left: 4rem;
  }
  .rmr-4,
  .mr-dt-4 {
    margin-right: 4rem;
  }
  .rmt-4,
  .mt-dt-4 {
    margin-top: 4rem;
  }
  .rmb-4,
  .mb-dt-4 {
    margin-bottom: 4rem;
  }
  .rmv-4,
  .mv-dt-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .rms-4,
  .ms-dt-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .rp-4,
  .p-dt-4 {
    padding: 4rem;
  }
  .rpl-4,
  .pl-dt-4 {
    padding-left: 4rem;
  }
  .rpr-4,
  .pr-dt-4 {
    padding-right: 4rem;
  }
  .rpt-4,
  .pt-dt-4 {
    padding-top: 4rem;
  }
  .rpb-4,
  .pb-dt-4 {
    padding-bottom: 4rem;
  }
  .rpv-4,
  .pv-dt-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .rps-4,
  .ps-dt-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .rcolumn-count-4,
  .column-count-dt-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-4 {
    margin: 4rem;
  }
  .mt-ls-4 {
    margin-top: 4rem;
  }
  .mb-ls-4 {
    margin-bottom: 4rem;
  }
  .ml-ls-4 {
    margin-left: 4rem;
  }
  .mr-ls-4 {
    margin-right: 4rem;
  }
  .ms-ls-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv-ls-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pt-ls-4 {
    padding-top: 4rem;
  }
  .pb-ls-4 {
    padding-bottom: 4rem;
  }
  .pl-ls-4 {
    padding-left: 4rem;
  }
  .pr-ls-4 {
    padding-right: 4rem;
  }
  .ps-ls-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv-ls-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .p-ls-4 {
    padding: 4rem;
  }
  .row.gap-ls-4 {
    gap: 4rem;
  }
  .column-count-ls-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-5 {
    margin: 5rem;
  }
  .mt-mh-5 {
    margin-top: 5rem;
  }
  .mb-mh-5 {
    margin-bottom: 5rem;
  }
  .ml-mh-5 {
    margin-left: 5rem;
  }
  .mr-mh-5 {
    margin-right: 5rem;
  }
  .ms-mh-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mv-mh-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pt-mh-5 {
    padding-top: 5rem;
  }
  .pb-mh-5 {
    padding-bottom: 5rem;
  }
  .pl-mh-5 {
    padding-left: 5rem;
  }
  .pr-mh-5 {
    padding-right: 5rem;
  }
  .ps-mh-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .pv-mh-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .p-mh-5 {
    padding: 5rem;
  }
  .row.gap-mh-5 {
    gap: 5rem;
  }
  .column-count-mh-5 {
    column-count: 5;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-5 {
    margin: 5rem;
  }
  .mt-mv-5 {
    margin-top: 5rem;
  }
  .mb-mv-5 {
    margin-bottom: 5rem;
  }
  .ml-mv-5 {
    margin-left: 5rem;
  }
  .mr-mv-5 {
    margin-right: 5rem;
  }
  .ms-mv-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mv-mv-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pt-mv-5 {
    padding-top: 5rem;
  }
  .pb-mv-5 {
    padding-bottom: 5rem;
  }
  .pl-mv-5 {
    padding-left: 5rem;
  }
  .pr-mv-5 {
    padding-right: 5rem;
  }
  .ps-mv-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .pv-mv-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .p-mv-5 {
    padding: 5rem;
  }
  .row.gap-mv-5 {
    gap: 5rem;
  }
  .column-count-mv-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 991px) {
  .rm-5,
  .m-dt-5 {
    margin: 5rem;
  }
  .rml-5,
  .ml-dt-5 {
    margin-left: 5rem;
  }
  .rmr-5,
  .mr-dt-5 {
    margin-right: 5rem;
  }
  .rmt-5,
  .mt-dt-5 {
    margin-top: 5rem;
  }
  .rmb-5,
  .mb-dt-5 {
    margin-bottom: 5rem;
  }
  .rmv-5,
  .mv-dt-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .rms-5,
  .ms-dt-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .rp-5,
  .p-dt-5 {
    padding: 5rem;
  }
  .rpl-5,
  .pl-dt-5 {
    padding-left: 5rem;
  }
  .rpr-5,
  .pr-dt-5 {
    padding-right: 5rem;
  }
  .rpt-5,
  .pt-dt-5 {
    padding-top: 5rem;
  }
  .rpb-5,
  .pb-dt-5 {
    padding-bottom: 5rem;
  }
  .rpv-5,
  .pv-dt-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .rps-5,
  .ps-dt-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .rcolumn-count-5,
  .column-count-dt-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-5 {
    margin: 5rem;
  }
  .mt-ls-5 {
    margin-top: 5rem;
  }
  .mb-ls-5 {
    margin-bottom: 5rem;
  }
  .ml-ls-5 {
    margin-left: 5rem;
  }
  .mr-ls-5 {
    margin-right: 5rem;
  }
  .ms-ls-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mv-ls-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pt-ls-5 {
    padding-top: 5rem;
  }
  .pb-ls-5 {
    padding-bottom: 5rem;
  }
  .pl-ls-5 {
    padding-left: 5rem;
  }
  .pr-ls-5 {
    padding-right: 5rem;
  }
  .ps-ls-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .pv-ls-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .p-ls-5 {
    padding: 5rem;
  }
  .row.gap-ls-5 {
    gap: 5rem;
  }
  .column-count-ls-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-6 {
    margin: 6rem;
  }
  .mt-mh-6 {
    margin-top: 6rem;
  }
  .mb-mh-6 {
    margin-bottom: 6rem;
  }
  .ml-mh-6 {
    margin-left: 6rem;
  }
  .mr-mh-6 {
    margin-right: 6rem;
  }
  .ms-mh-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .mv-mh-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pt-mh-6 {
    padding-top: 6rem;
  }
  .pb-mh-6 {
    padding-bottom: 6rem;
  }
  .pl-mh-6 {
    padding-left: 6rem;
  }
  .pr-mh-6 {
    padding-right: 6rem;
  }
  .ps-mh-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .pv-mh-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .p-mh-6 {
    padding: 6rem;
  }
  .row.gap-mh-6 {
    gap: 6rem;
  }
  .column-count-mh-6 {
    column-count: 6;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-6 {
    margin: 6rem;
  }
  .mt-mv-6 {
    margin-top: 6rem;
  }
  .mb-mv-6 {
    margin-bottom: 6rem;
  }
  .ml-mv-6 {
    margin-left: 6rem;
  }
  .mr-mv-6 {
    margin-right: 6rem;
  }
  .ms-mv-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .mv-mv-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pt-mv-6 {
    padding-top: 6rem;
  }
  .pb-mv-6 {
    padding-bottom: 6rem;
  }
  .pl-mv-6 {
    padding-left: 6rem;
  }
  .pr-mv-6 {
    padding-right: 6rem;
  }
  .ps-mv-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .pv-mv-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .p-mv-6 {
    padding: 6rem;
  }
  .row.gap-mv-6 {
    gap: 6rem;
  }
  .column-count-mv-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 991px) {
  .rm-6,
  .m-dt-6 {
    margin: 6rem;
  }
  .rml-6,
  .ml-dt-6 {
    margin-left: 6rem;
  }
  .rmr-6,
  .mr-dt-6 {
    margin-right: 6rem;
  }
  .rmt-6,
  .mt-dt-6 {
    margin-top: 6rem;
  }
  .rmb-6,
  .mb-dt-6 {
    margin-bottom: 6rem;
  }
  .rmv-6,
  .mv-dt-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .rms-6,
  .ms-dt-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .rp-6,
  .p-dt-6 {
    padding: 6rem;
  }
  .rpl-6,
  .pl-dt-6 {
    padding-left: 6rem;
  }
  .rpr-6,
  .pr-dt-6 {
    padding-right: 6rem;
  }
  .rpt-6,
  .pt-dt-6 {
    padding-top: 6rem;
  }
  .rpb-6,
  .pb-dt-6 {
    padding-bottom: 6rem;
  }
  .rpv-6,
  .pv-dt-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .rps-6,
  .ps-dt-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .rcolumn-count-6,
  .column-count-dt-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-6 {
    margin: 6rem;
  }
  .mt-ls-6 {
    margin-top: 6rem;
  }
  .mb-ls-6 {
    margin-bottom: 6rem;
  }
  .ml-ls-6 {
    margin-left: 6rem;
  }
  .mr-ls-6 {
    margin-right: 6rem;
  }
  .ms-ls-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .mv-ls-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pt-ls-6 {
    padding-top: 6rem;
  }
  .pb-ls-6 {
    padding-bottom: 6rem;
  }
  .pl-ls-6 {
    padding-left: 6rem;
  }
  .pr-ls-6 {
    padding-right: 6rem;
  }
  .ps-ls-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .pv-ls-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .p-ls-6 {
    padding: 6rem;
  }
  .row.gap-ls-6 {
    gap: 6rem;
  }
  .column-count-ls-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-7 {
    margin: 7rem;
  }
  .mt-mh-7 {
    margin-top: 7rem;
  }
  .mb-mh-7 {
    margin-bottom: 7rem;
  }
  .ml-mh-7 {
    margin-left: 7rem;
  }
  .mr-mh-7 {
    margin-right: 7rem;
  }
  .ms-mh-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .mv-mh-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pt-mh-7 {
    padding-top: 7rem;
  }
  .pb-mh-7 {
    padding-bottom: 7rem;
  }
  .pl-mh-7 {
    padding-left: 7rem;
  }
  .pr-mh-7 {
    padding-right: 7rem;
  }
  .ps-mh-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .pv-mh-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-mh-7 {
    padding: 7rem;
  }
  .row.gap-mh-7 {
    gap: 7rem;
  }
  .column-count-mh-7 {
    column-count: 7;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-7 {
    margin: 7rem;
  }
  .mt-mv-7 {
    margin-top: 7rem;
  }
  .mb-mv-7 {
    margin-bottom: 7rem;
  }
  .ml-mv-7 {
    margin-left: 7rem;
  }
  .mr-mv-7 {
    margin-right: 7rem;
  }
  .ms-mv-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .mv-mv-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pt-mv-7 {
    padding-top: 7rem;
  }
  .pb-mv-7 {
    padding-bottom: 7rem;
  }
  .pl-mv-7 {
    padding-left: 7rem;
  }
  .pr-mv-7 {
    padding-right: 7rem;
  }
  .ps-mv-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .pv-mv-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-mv-7 {
    padding: 7rem;
  }
  .row.gap-mv-7 {
    gap: 7rem;
  }
  .column-count-mv-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 991px) {
  .rm-7,
  .m-dt-7 {
    margin: 7rem;
  }
  .rml-7,
  .ml-dt-7 {
    margin-left: 7rem;
  }
  .rmr-7,
  .mr-dt-7 {
    margin-right: 7rem;
  }
  .rmt-7,
  .mt-dt-7 {
    margin-top: 7rem;
  }
  .rmb-7,
  .mb-dt-7 {
    margin-bottom: 7rem;
  }
  .rmv-7,
  .mv-dt-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .rms-7,
  .ms-dt-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .rp-7,
  .p-dt-7 {
    padding: 7rem;
  }
  .rpl-7,
  .pl-dt-7 {
    padding-left: 7rem;
  }
  .rpr-7,
  .pr-dt-7 {
    padding-right: 7rem;
  }
  .rpt-7,
  .pt-dt-7 {
    padding-top: 7rem;
  }
  .rpb-7,
  .pb-dt-7 {
    padding-bottom: 7rem;
  }
  .rpv-7,
  .pv-dt-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .rps-7,
  .ps-dt-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .rcolumn-count-7,
  .column-count-dt-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-7 {
    margin: 7rem;
  }
  .mt-ls-7 {
    margin-top: 7rem;
  }
  .mb-ls-7 {
    margin-bottom: 7rem;
  }
  .ml-ls-7 {
    margin-left: 7rem;
  }
  .mr-ls-7 {
    margin-right: 7rem;
  }
  .ms-ls-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .mv-ls-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pt-ls-7 {
    padding-top: 7rem;
  }
  .pb-ls-7 {
    padding-bottom: 7rem;
  }
  .pl-ls-7 {
    padding-left: 7rem;
  }
  .pr-ls-7 {
    padding-right: 7rem;
  }
  .ps-ls-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .pv-ls-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-ls-7 {
    padding: 7rem;
  }
  .row.gap-ls-7 {
    gap: 7rem;
  }
  .column-count-ls-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-8 {
    margin: 8rem;
  }
  .mt-mh-8 {
    margin-top: 8rem;
  }
  .mb-mh-8 {
    margin-bottom: 8rem;
  }
  .ml-mh-8 {
    margin-left: 8rem;
  }
  .mr-mh-8 {
    margin-right: 8rem;
  }
  .ms-mh-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mv-mh-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pt-mh-8 {
    padding-top: 8rem;
  }
  .pb-mh-8 {
    padding-bottom: 8rem;
  }
  .pl-mh-8 {
    padding-left: 8rem;
  }
  .pr-mh-8 {
    padding-right: 8rem;
  }
  .ps-mh-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pv-mh-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .p-mh-8 {
    padding: 8rem;
  }
  .row.gap-mh-8 {
    gap: 8rem;
  }
  .column-count-mh-8 {
    column-count: 8;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-8 {
    margin: 8rem;
  }
  .mt-mv-8 {
    margin-top: 8rem;
  }
  .mb-mv-8 {
    margin-bottom: 8rem;
  }
  .ml-mv-8 {
    margin-left: 8rem;
  }
  .mr-mv-8 {
    margin-right: 8rem;
  }
  .ms-mv-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mv-mv-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pt-mv-8 {
    padding-top: 8rem;
  }
  .pb-mv-8 {
    padding-bottom: 8rem;
  }
  .pl-mv-8 {
    padding-left: 8rem;
  }
  .pr-mv-8 {
    padding-right: 8rem;
  }
  .ps-mv-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pv-mv-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .p-mv-8 {
    padding: 8rem;
  }
  .row.gap-mv-8 {
    gap: 8rem;
  }
  .column-count-mv-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 991px) {
  .rm-8,
  .m-dt-8 {
    margin: 8rem;
  }
  .rml-8,
  .ml-dt-8 {
    margin-left: 8rem;
  }
  .rmr-8,
  .mr-dt-8 {
    margin-right: 8rem;
  }
  .rmt-8,
  .mt-dt-8 {
    margin-top: 8rem;
  }
  .rmb-8,
  .mb-dt-8 {
    margin-bottom: 8rem;
  }
  .rmv-8,
  .mv-dt-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .rms-8,
  .ms-dt-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .rp-8,
  .p-dt-8 {
    padding: 8rem;
  }
  .rpl-8,
  .pl-dt-8 {
    padding-left: 8rem;
  }
  .rpr-8,
  .pr-dt-8 {
    padding-right: 8rem;
  }
  .rpt-8,
  .pt-dt-8 {
    padding-top: 8rem;
  }
  .rpb-8,
  .pb-dt-8 {
    padding-bottom: 8rem;
  }
  .rpv-8,
  .pv-dt-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .rps-8,
  .ps-dt-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .rcolumn-count-8,
  .column-count-dt-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-8 {
    margin: 8rem;
  }
  .mt-ls-8 {
    margin-top: 8rem;
  }
  .mb-ls-8 {
    margin-bottom: 8rem;
  }
  .ml-ls-8 {
    margin-left: 8rem;
  }
  .mr-ls-8 {
    margin-right: 8rem;
  }
  .ms-ls-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mv-ls-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pt-ls-8 {
    padding-top: 8rem;
  }
  .pb-ls-8 {
    padding-bottom: 8rem;
  }
  .pl-ls-8 {
    padding-left: 8rem;
  }
  .pr-ls-8 {
    padding-right: 8rem;
  }
  .ps-ls-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pv-ls-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .p-ls-8 {
    padding: 8rem;
  }
  .row.gap-ls-8 {
    gap: 8rem;
  }
  .column-count-ls-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-9 {
    margin: 9rem;
  }
  .mt-mh-9 {
    margin-top: 9rem;
  }
  .mb-mh-9 {
    margin-bottom: 9rem;
  }
  .ml-mh-9 {
    margin-left: 9rem;
  }
  .mr-mh-9 {
    margin-right: 9rem;
  }
  .ms-mh-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .mv-mh-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pt-mh-9 {
    padding-top: 9rem;
  }
  .pb-mh-9 {
    padding-bottom: 9rem;
  }
  .pl-mh-9 {
    padding-left: 9rem;
  }
  .pr-mh-9 {
    padding-right: 9rem;
  }
  .ps-mh-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .pv-mh-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .p-mh-9 {
    padding: 9rem;
  }
  .row.gap-mh-9 {
    gap: 9rem;
  }
  .column-count-mh-9 {
    column-count: 9;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-9 {
    margin: 9rem;
  }
  .mt-mv-9 {
    margin-top: 9rem;
  }
  .mb-mv-9 {
    margin-bottom: 9rem;
  }
  .ml-mv-9 {
    margin-left: 9rem;
  }
  .mr-mv-9 {
    margin-right: 9rem;
  }
  .ms-mv-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .mv-mv-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pt-mv-9 {
    padding-top: 9rem;
  }
  .pb-mv-9 {
    padding-bottom: 9rem;
  }
  .pl-mv-9 {
    padding-left: 9rem;
  }
  .pr-mv-9 {
    padding-right: 9rem;
  }
  .ps-mv-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .pv-mv-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .p-mv-9 {
    padding: 9rem;
  }
  .row.gap-mv-9 {
    gap: 9rem;
  }
  .column-count-mv-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 991px) {
  .rm-9,
  .m-dt-9 {
    margin: 9rem;
  }
  .rml-9,
  .ml-dt-9 {
    margin-left: 9rem;
  }
  .rmr-9,
  .mr-dt-9 {
    margin-right: 9rem;
  }
  .rmt-9,
  .mt-dt-9 {
    margin-top: 9rem;
  }
  .rmb-9,
  .mb-dt-9 {
    margin-bottom: 9rem;
  }
  .rmv-9,
  .mv-dt-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .rms-9,
  .ms-dt-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .rp-9,
  .p-dt-9 {
    padding: 9rem;
  }
  .rpl-9,
  .pl-dt-9 {
    padding-left: 9rem;
  }
  .rpr-9,
  .pr-dt-9 {
    padding-right: 9rem;
  }
  .rpt-9,
  .pt-dt-9 {
    padding-top: 9rem;
  }
  .rpb-9,
  .pb-dt-9 {
    padding-bottom: 9rem;
  }
  .rpv-9,
  .pv-dt-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .rps-9,
  .ps-dt-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .rcolumn-count-9,
  .column-count-dt-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-9 {
    margin: 9rem;
  }
  .mt-ls-9 {
    margin-top: 9rem;
  }
  .mb-ls-9 {
    margin-bottom: 9rem;
  }
  .ml-ls-9 {
    margin-left: 9rem;
  }
  .mr-ls-9 {
    margin-right: 9rem;
  }
  .ms-ls-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .mv-ls-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pt-ls-9 {
    padding-top: 9rem;
  }
  .pb-ls-9 {
    padding-bottom: 9rem;
  }
  .pl-ls-9 {
    padding-left: 9rem;
  }
  .pr-ls-9 {
    padding-right: 9rem;
  }
  .ps-ls-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .pv-ls-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .p-ls-9 {
    padding: 9rem;
  }
  .row.gap-ls-9 {
    gap: 9rem;
  }
  .column-count-ls-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-10 {
    margin: 10rem;
  }
  .mt-mh-10 {
    margin-top: 10rem;
  }
  .mb-mh-10 {
    margin-bottom: 10rem;
  }
  .ml-mh-10 {
    margin-left: 10rem;
  }
  .mr-mh-10 {
    margin-right: 10rem;
  }
  .ms-mh-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mv-mh-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pt-mh-10 {
    padding-top: 10rem;
  }
  .pb-mh-10 {
    padding-bottom: 10rem;
  }
  .pl-mh-10 {
    padding-left: 10rem;
  }
  .pr-mh-10 {
    padding-right: 10rem;
  }
  .ps-mh-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .pv-mh-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .p-mh-10 {
    padding: 10rem;
  }
  .row.gap-mh-10 {
    gap: 10rem;
  }
  .column-count-mh-10 {
    column-count: 10;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-10 {
    margin: 10rem;
  }
  .mt-mv-10 {
    margin-top: 10rem;
  }
  .mb-mv-10 {
    margin-bottom: 10rem;
  }
  .ml-mv-10 {
    margin-left: 10rem;
  }
  .mr-mv-10 {
    margin-right: 10rem;
  }
  .ms-mv-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mv-mv-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pt-mv-10 {
    padding-top: 10rem;
  }
  .pb-mv-10 {
    padding-bottom: 10rem;
  }
  .pl-mv-10 {
    padding-left: 10rem;
  }
  .pr-mv-10 {
    padding-right: 10rem;
  }
  .ps-mv-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .pv-mv-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .p-mv-10 {
    padding: 10rem;
  }
  .row.gap-mv-10 {
    gap: 10rem;
  }
  .column-count-mv-10 {
    column-count: 10;
  }
}
@media screen and (min-width: 991px) {
  .rm-10,
  .m-dt-10 {
    margin: 10rem;
  }
  .rml-10,
  .ml-dt-10 {
    margin-left: 10rem;
  }
  .rmr-10,
  .mr-dt-10 {
    margin-right: 10rem;
  }
  .rmt-10,
  .mt-dt-10 {
    margin-top: 10rem;
  }
  .rmb-10,
  .mb-dt-10 {
    margin-bottom: 10rem;
  }
  .rmv-10,
  .mv-dt-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .rms-10,
  .ms-dt-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .rp-10,
  .p-dt-10 {
    padding: 10rem;
  }
  .rpl-10,
  .pl-dt-10 {
    padding-left: 10rem;
  }
  .rpr-10,
  .pr-dt-10 {
    padding-right: 10rem;
  }
  .rpt-10,
  .pt-dt-10 {
    padding-top: 10rem;
  }
  .rpb-10,
  .pb-dt-10 {
    padding-bottom: 10rem;
  }
  .rpv-10,
  .pv-dt-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .rps-10,
  .ps-dt-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .rcolumn-count-10,
  .column-count-dt-10 {
    column-count: 10;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-10 {
    margin: 10rem;
  }
  .mt-ls-10 {
    margin-top: 10rem;
  }
  .mb-ls-10 {
    margin-bottom: 10rem;
  }
  .ml-ls-10 {
    margin-left: 10rem;
  }
  .mr-ls-10 {
    margin-right: 10rem;
  }
  .ms-ls-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mv-ls-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pt-ls-10 {
    padding-top: 10rem;
  }
  .pb-ls-10 {
    padding-bottom: 10rem;
  }
  .pl-ls-10 {
    padding-left: 10rem;
  }
  .pr-ls-10 {
    padding-right: 10rem;
  }
  .ps-ls-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .pv-ls-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .p-ls-10 {
    padding: 10rem;
  }
  .row.gap-ls-10 {
    gap: 10rem;
  }
  .column-count-ls-10 {
    column-count: 10;
  }
}
.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}

section {
  padding: 3rem 0;
}

section.fh {
  min-height: 100vh;
}

.container {
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
}

.container.super-slim {
  max-width: 800px;
}

.container.slim {
  max-width: 900px;
}

.container.narrow {
  max-width: 1100px;
}

.container.standart {
  max-width: 1300px;
}

.container.wider {
  max-width: 1500px;
}

.container.wide {
  max-width: 1700px;
}

.container.widest {
  max-width: 1850px;
}

.container.largescreen {
  max-width: 2430px;
}

.container.fw {
  max-width: none !important;
}

@media screen and (max-width: 1249px) {
  .container {
    padding: 0 calc(15px + 2%);
  }
}
@media screen and (max-width: 990px) {
  .container {
    padding: 0 calc(15px + 1%);
  }
}
.row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

@media screen and (max-width: 990px) {
  .row {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
  }
  .row.eq-h {
    height: auto !important;
  }
}
@media screen and (max-width: 990px) {
  .row.no-collapse {
    display: flex;
  }
  .row.no-collapse .col {
    margin-bottom: 0;
  }
}
.col {
  padding: 0 15px;
  flex-shrink: 0;
  box-sizing: border-box;
}

.col:not([class*=basis]) {
  flex-basis: 0;
  flex-grow: 1;
}

.col[class*=basis] {
  flex-grow: 0;
}

@media screen and (max-width: 990px) {
  .col {
    margin-bottom: 1rem;
  }
  .col:last-of-type {
    margin-bottom: 0;
  }
}
.masonry > div {
  display: flex;
  flex-direction: column;
}

.masonry-inner {
  display: block;
  flex-grow: 1;
}

.masonry-inverted .masonry-inner {
  box-shadow: none;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.d-none {
  display: none;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .d-mh-flex {
    display: flex;
  }
  .d-mh-inline-flex {
    display: inline-flex;
  }
  .d-mh-block {
    display: block;
  }
  .d-mh-inline-block {
    display: inline-block;
  }
  .d-mh-inline {
    display: inline;
  }
  .d-mh-none {
    display: none;
  }
}
@media screen and (max-width: 479px) {
  .d-mv-flex {
    display: flex;
  }
  .d-mv-inline-flex {
    display: inline-flex;
  }
  .d-mv-block {
    display: block;
  }
  .d-mv-inline-block {
    display: inline-block;
  }
  .d-mv-inline {
    display: inline;
  }
  .d-mv-none {
    display: none;
  }
}
@media screen and (min-width: 991px) {
  .d-r-flex, .d-dt-flex {
    display: flex;
  }
  .d-r-inline-flex, .d-dt-inline-flex {
    display: inline-flex;
  }
  .d-r-block, .d-dt-block {
    display: block;
  }
  .d-r-inline-block, .d-dt-inline-block {
    display: inline-block;
  }
  .d-r-inline, .d-dt-inline {
    display: inline;
  }
  .d-r-none, .d-dt-none {
    display: none;
  }
}
@media screen and (min-width: 2430px) {
  .d-ls-flex {
    display: flex;
  }
  .d-ls-inline-flex {
    display: inline-flex;
  }
  .d-ls-block {
    display: block;
  }
  .d-ls-inline-block {
    display: inline-block;
  }
  .d-ls-inline {
    display: inline;
  }
  .d-ls-none {
    display: none;
  }
}
.p-static {
  position: static;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.p-sticky {
  position: sticky;
}

.border {
  border-width: 0;
  border-style: solid;
  border-color: #000000;
}

.border-all-width-0 {
  border-width: 0px;
}

.border-all-width-1 {
  border-width: 1px;
}

.border-all-width-2 {
  border-width: 2px;
}

.border-all-width-3 {
  border-width: 3px;
}

.border-all-width-4 {
  border-width: 4px;
}

.border-all-width-5 {
  border-width: 5px;
}

.border-all-width-6 {
  border-width: 6px;
}

.border-all-width-7 {
  border-width: 7px;
}

.border-all-width-8 {
  border-width: 8px;
}

.border-all-width-9 {
  border-width: 9px;
}

.border-all-width-10 {
  border-width: 10px;
}

.border-top-width-0 {
  border-top-width: 0px;
}

.border-top-width-1 {
  border-top-width: 1px;
}

.border-top-width-2 {
  border-top-width: 2px;
}

.border-top-width-3 {
  border-top-width: 3px;
}

.border-top-width-4 {
  border-top-width: 4px;
}

.border-top-width-5 {
  border-top-width: 5px;
}

.border-top-width-6 {
  border-top-width: 6px;
}

.border-top-width-7 {
  border-top-width: 7px;
}

.border-top-width-8 {
  border-top-width: 8px;
}

.border-top-width-9 {
  border-top-width: 9px;
}

.border-top-width-10 {
  border-top-width: 10px;
}

.border-bottom-width-0 {
  border-bottom-width: 0px;
}

.border-bottom-width-1 {
  border-bottom-width: 1px;
}

.border-bottom-width-2 {
  border-bottom-width: 2px;
}

.border-bottom-width-3 {
  border-bottom-width: 3px;
}

.border-bottom-width-4 {
  border-bottom-width: 4px;
}

.border-bottom-width-5 {
  border-bottom-width: 5px;
}

.border-bottom-width-6 {
  border-bottom-width: 6px;
}

.border-bottom-width-7 {
  border-bottom-width: 7px;
}

.border-bottom-width-8 {
  border-bottom-width: 8px;
}

.border-bottom-width-9 {
  border-bottom-width: 9px;
}

.border-bottom-width-10 {
  border-bottom-width: 10px;
}

.border-left-width-0 {
  border-left-width: 0px;
}

.border-left-width-1 {
  border-left-width: 1px;
}

.border-left-width-2 {
  border-left-width: 2px;
}

.border-left-width-3 {
  border-left-width: 3px;
}

.border-left-width-4 {
  border-left-width: 4px;
}

.border-left-width-5 {
  border-left-width: 5px;
}

.border-left-width-6 {
  border-left-width: 6px;
}

.border-left-width-7 {
  border-left-width: 7px;
}

.border-left-width-8 {
  border-left-width: 8px;
}

.border-left-width-9 {
  border-left-width: 9px;
}

.border-left-width-10 {
  border-left-width: 10px;
}

.border-right-width-0 {
  border-right-width: 0px;
}

.border-right-width-1 {
  border-right-width: 1px;
}

.border-right-width-2 {
  border-right-width: 2px;
}

.border-right-width-3 {
  border-right-width: 3px;
}

.border-right-width-4 {
  border-right-width: 4px;
}

.border-right-width-5 {
  border-right-width: 5px;
}

.border-right-width-6 {
  border-right-width: 6px;
}

.border-right-width-7 {
  border-right-width: 7px;
}

.border-right-width-8 {
  border-right-width: 8px;
}

.border-right-width-9 {
  border-right-width: 9px;
}

.border-right-width-10 {
  border-right-width: 10px;
}

.border-color-yellow {
  border-color: #ffdb3c;
}

.border-color-purple {
  border-color: #7f87ac;
}

.border-color-white {
  border-color: #fff;
}

.border-color-beige {
  border-color: #F9FAF0;
}

.border-color-gray {
  border-color: #505050;
}

.border-color-black {
  border-color: #000000;
}

.border-color-black-alt {
  border-color: #2b2b33;
}

.border-color-base {
  border-color: #3c3c3c;
}

.border-color-red {
  border-color: #d0685c;
}

.border-color-orange {
  border-color: #ffe2be;
}

.border-color-scarlet {
  border-color: #db8443;
}

.border-color-green {
  border-color: #7eb2a3;
}

.border-color-blue {
  border-color: #443b5f;
}

.border-color-pink {
  border-color: #fff5ec;
}

.border-style-dotted {
  border-style: dotted;
}

.border-style-dashed {
  border-style: dashed;
}

.border-style-solid {
  border-style: solid;
}

.border-style-double {
  border-top-style: double;
  border-bottom-style: double;
  border-left-style: double;
  border-right-style: double;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-6 {
  border-radius: 6px;
}

.border-radius-7 {
  border-radius: 7px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-9 {
  border-radius: 9px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-rounded {
  border-radius: 50%;
}

.flex-aic {
  align-items: center;
}

.flex-ait {
  align-items: flex-start;
}

.flex-aib {
  align-items: flex-end;
}

.flex-jcs {
  justify-content: flex-start;
}

.flex-jce {
  justify-content: flex-end;
}

.flex-jcc {
  justify-content: center;
}

.flex-jcsb {
  justify-content: space-between;
}

.flex-jcsa {
  justify-content: space-around;
}

.flex-jcse {
  justify-content: space-evenly;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

@media screen and (min-width: 991px) {
  .flex-direction-dt-column {
    flex-direction: column;
  }
  .flex-direction-dt-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-dt-row {
    flex-direction: row;
  }
  .flex-direction-dt-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 2430px) {
  .flex-direction-ls-column {
    flex-direction: column;
  }
  .flex-direction-ls-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-ls-row {
    flex-direction: row;
  }
  .flex-direction-ls-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .flex-direction-mh-column {
    flex-direction: column;
  }
  .flex-direction-mh-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-mh-row {
    flex-direction: row;
  }
  .flex-direction-mh-row-reverse {
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 479px) {
  .flex-direction-mv-column {
    flex-direction: column;
  }
  .flex-direction-mv-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-direction-mv-row {
    flex-direction: row;
  }
  .flex-direction-mv-row-reverse {
    flex-direction: row-reverse;
  }
}
.flex-wrap {
  flex-wrap: wrap;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .flex-mh-jcse {
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 479px) {
  .flex-mv-jcse {
    justify-content: space-evenly;
  }
}
.fg-1 {
  flex-grow: 1;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-uc {
  text-transform: uppercase;
}

.text-lc {
  text-transform: lowercase;
}

.text-cz {
  text-transform: capitalize;
}

@media screen and (min-width: 991px) {
  .text-r-left, .text-dt-left {
    text-align: left;
  }
  .text-r-center, .text-dt-center {
    text-align: center;
  }
  .text-r-right, .text-dt-right {
    text-align: right;
  }
  .text-r-justify, .text-dt-justify {
    text-align: justify;
  }
}
@media screen and (min-width: 2430px) {
  .text-ls-left {
    text-align: left;
  }
  .text-ls-center {
    text-align: center;
  }
  .text-ls-right {
    text-align: right;
  }
  .text-ls-justify {
    text-align: justify;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .text-mh-left {
    text-align: left;
  }
  .text-mh-center {
    text-align: center;
  }
  .text-mh-right {
    text-align: right;
  }
  .text-mh-justify {
    text-align: justify;
  }
}
@media screen and (max-width: 479px) {
  .text-mv-left {
    text-align: left;
  }
  .text-mv-center {
    text-align: center;
  }
  .text-mv-right {
    text-align: right;
  }
  .text-mv-justify {
    text-align: justify;
  }
}
.float-container:after {
  content: "";
  display: block;
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

@media screen and (min-width: 991px) {
  .float-dt-left {
    float: left;
  }
  .float-dt-right {
    float: right;
  }
}
@media screen and (min-width: 2430px) {
  .float-ls-left {
    float: left;
  }
  .float-ls-right {
    float: right;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .float-mh-left {
    float: left;
  }
  .float-mh-right {
    float: right;
  }
}
@media screen and (max-width: 479px) {
  .float-mv-left {
    float: left;
  }
  .float-mv-right {
    float: right;
  }
}
.font-size-xs {
  font-size: 60%;
}

.font-size-sm {
  font-size: 80%;
}

.font-size-n {
  font-size: 100%;
}

.font-size-l {
  font-size: 125%;
}

.font-size-xl {
  font-size: 150%;
}

.font-size-xxl {
  font-size: 200%;
}

.font-size-xxxl {
  font-size: 300%;
}

.font-size-xxxxl {
  font-size: 400%;
}

.font-size-xxxxxl {
  font-size: 500%;
}

@media screen and (min-width: 991px) {
  .font-size-dt-xs {
    font-size: 60%;
  }
  .font-size-dt-sm {
    font-size: 80%;
  }
  .font-size-dt-n {
    font-size: 100%;
  }
  .font-size-dt-l {
    font-size: 125%;
  }
  .font-size-dt-xl {
    font-size: 150%;
  }
  .font-size-dt-xxl {
    font-size: 200%;
  }
  .font-size-dt-xxxl {
    font-size: 300%;
  }
  .font-size-dt-xxxxl {
    font-size: 400%;
  }
  .font-size-dt-xxxxxl {
    font-size: 500%;
  }
}
@media screen and (min-width: 2430px) {
  .font-size-ls-xs {
    font-size: 60%;
  }
  .font-size-ls-sm {
    font-size: 80%;
  }
  .font-size-ls-n {
    font-size: 100%;
  }
  .font-size-ls-l {
    font-size: 125%;
  }
  .font-size-ls-xl {
    font-size: 150%;
  }
  .font-size-ls-xxl {
    font-size: 200%;
  }
  .font-size-ls-xxxl {
    font-size: 300%;
  }
  .font-size-ls-xxxxl {
    font-size: 400%;
  }
  .font-size-ls-xxxxxl {
    font-size: 500%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .font-size-mh-xs {
    font-size: 60%;
  }
  .font-size-mh-sm {
    font-size: 80%;
  }
  .font-size-mh-n {
    font-size: 100%;
  }
  .font-size-mh-l {
    font-size: 125%;
  }
  .font-size-mh-xl {
    font-size: 150%;
  }
  .font-size-mh-xxl {
    font-size: 200%;
  }
  .font-size-mh-xxxl {
    font-size: 300%;
  }
  .font-size-mh-xxxxl {
    font-size: 400%;
  }
  .font-size-mh-xxxxxl {
    font-size: 500%;
  }
}
@media screen and (max-width: 479px) {
  .font-size-mv-xs {
    font-size: 60%;
  }
  .font-size-mv-sm {
    font-size: 80%;
  }
  .font-size-mv-n {
    font-size: 100%;
  }
  .font-size-mv-l {
    font-size: 125%;
  }
  .font-size-mv-xl {
    font-size: 150%;
  }
  .font-size-mv-xxl {
    font-size: 200%;
  }
  .font-size-mv-xxxl {
    font-size: 300%;
  }
  .font-size-mv-xxxxl {
    font-size: 400%;
  }
  .font-size-mv-xxxxxl {
    font-size: 500%;
  }
}
.line-height-inherit {
  line-height: inherit;
}

.line-height-normal, .line-height-n {
  line-height: normal;
}

.line-height-60 {
  line-height: 60%;
}

.line-height-80 {
  line-height: 80%;
}

.line-height-100 {
  line-height: 100%;
}

.line-height-125 {
  line-height: 125%;
}

.line-height-150 {
  line-height: 150%;
}

.line-height-200 {
  line-height: 200%;
}

.ls-min {
  letter-spacing: -0.1em;
}

.ls-condenced {
  letter-spacing: -0.05em;
}

.ls-wide {
  letter-spacing: 0.05em;
}

.ls-max {
  letter-spacing: 0.1em;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 990px) {
  .hidden-mobile {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .hidden-desktop {
    display: none !important;
  }
}
.visibility-on {
  visibility: visible;
}

.visibility-off {
  visibility: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

@media screen and (min-width: 991px) {
  .m-dt-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 991px) {
  .ml-dt-auto {
    margin-left: auto;
  }
}
@media screen and (min-width: 991px) {
  .mr-dt-auto {
    margin-right: auto;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 2430px) {
  .ml-ls-auto {
    margin-left: auto;
  }
}
@media screen and (min-width: 2430px) {
  .mr-ls-auto {
    margin-right: auto;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .ml-mh-auto {
    margin-left: auto;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .mr-mh-auto {
    margin-right: auto;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 479px) {
  .ml-mv-auto {
    margin-left: auto;
  }
}
@media screen and (max-width: 479px) {
  .mr-mv-auto {
    margin-right: auto;
  }
}
.no-bg {
  background-image: none !important;
}

.underline {
  text-decoration: underline;
}

.shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.shadow-small {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.shadow-big {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
}

.pr {
  position: relative;
}

.bold {
  font-weight: 700;
}

.media-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.media-wrapper iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.clearfix:after {
  display: table;
  content: "";
  clear: both;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

.white-space-pre {
  white-space: pre;
}

.word-break-normal {
  word-break: normal;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

.bfv-hidden {
  backface-visibility: hidden;
}

.bfv-visible {
  backface-visibility: visible;
}

.acc-content {
  display: none;
}

.acc .btn-acc span:first-child {
  display: inline-block;
}

.acc .btn-acc span:last-child {
  display: none;
}

.acc-opened .acc-content {
  display: block;
}

.acc-opened .btn-acc span:first-child {
  display: none;
}

.acc-opened .btn-acc span:last-child {
  display: inline-block;
}

.tab-titles {
  margin-bottom: 0;
}

.tab-titles li {
  list-style-type: none;
  margin-left: 0;
}

.tab-titles li a {
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
}

.tab-titles li a:not(.active) {
  background-color: inherit !important;
  color: inherit !important;
}

.tab-titles li a:hover {
  opacity: 1;
}

.tab-info {
  padding: 1rem;
}

.tab-info div[data-item-tab] {
  display: none;
}

.tab-info div[data-item-tab].active {
  display: block;
}

.tab-horizontal .tab-titles {
  display: flex;
  overflow-x: auto;
}

.tab-vertical {
  display: flex;
}

.tab-vertical .tab-info {
  flex-grow: 1;
}

.banner-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-tile {
  background-repeat: repeat;
}

@media screen and (min-width: 991px) {
  .banner-fixed {
    background-attachment: fixed;
  }
}
.opacity-panel {
  position: relative;
}

.opacity-panel-bg {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.opacity-panel-content {
  position: relative;
  z-index: 5;
}

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

.op-10 {
  opacity: 1;
}

.img-cover img {
  object-fit: cover;
  width: 100%;
}

.img-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.text-yellow {
  color: #ffdb3c;
}

.text-olive {
  color: #879676;
}

.text-purple {
  color: #7f87ac;
}

.text-silver {
  color: #B2B2B2;
}

.text-lightpurple {
  color: #f2f5fd;
}

.text-white {
  color: #fff;
}

.text-beige {
  color: #F9FAF0;
}

.text-gray {
  color: #505050;
}

.text-black {
  color: #000000;
}

.text-black-alt {
  color: #2b2b33;
}

.text-base {
  color: #3c3c3c;
}

.text-darkgray {
  color: #2C2731;
}

.text-orange {
  color: #ffe2be;
}

.text-scarlet {
  color: #db8443;
}

.text-red {
  color: #d0685c;
}

.text-pink {
  color: #fff5ec;
}

.text-blue {
  color: #443b5f;
}

.text-green {
  color: #7eb2a3;
}

.text-lightgreen {
  color: #e1f4ee;
}

.bg-yellow {
  background-color: #ffdb3c;
}

.bg-crystal {
  background-color: #D0C5D3;
}

.bg-sober {
  background-color: #BD8FC6;
}

.bg-bronze {
  background-color: #ADADAD;
}

.bg-olive {
  background-color: #879676;
}

.bg-purple {
  background-color: #7f87ac;
}

.bg-silver {
  background-color: #B2B2B2;
}

.bg-lightpurple {
  background-color: #f2f5fd;
}

.bg-white {
  background-color: #fff;
}

.bg-beige {
  background-color: #F9FAF0;
}

.bg-gray {
  background-color: #505050;
}

.bg-darkgray {
  background-color: #2C2731;
}

.bg-black {
  background-color: #000000;
}

.bg-black-alt {
  background-color: #2b2b33;
}

.bg-base {
  background-color: #3c3c3c;
}

.bg-orange {
  background-color: #ffe2be;
}

.bg-scarlet {
  background-color: #db8443;
}

.bg-red {
  background-color: #d0685c;
}

.bg-pink {
  background-color: #fff5ec;
}

.bg-blue {
  background-color: #443b5f;
}

.bg-green {
  background-color: #7eb2a3;
}

.bg-lightgreen {
  background-color: #e1f4ee;
}

body.loaded a,
body.loaded button {
  transition: all 0.4s ease-in-out;
}

a,
button {
  transition: none;
}

a:not(.disabled):not(:disabled),
button:not(.disabled):not(:disabled) {
  cursor: pointer;
}

a.disabled, a:disabled,
button.disabled,
button:disabled {
  cursor: default;
  pointer-events: none;
}

a {
  text-decoration: underline;
}

a:not(.btn):hover {
  opacity: 0.75;
}

html {
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 990px) {
  html {
    font-size: 16px;
  }
}
body {
  line-height: 1.4;
  color: #3c3c3c;
  font-family: "Krub", sans-serif;
  background-color: #F9FAF0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1-style,
.h2-style,
.h3-style,
.h4-style,
.h5-style,
.h6-style {
  line-height: normal;
  font-family: "Jost", sans-serif;
}

h1,
.h1-style {
  font-size: 3.8888888889em;
  font-weight: 600;
  line-height: 1;
}

@media screen and (max-width: 479px) {
  h1,
  .h1-style {
    font-size: 3.3333333333em;
  }
}
h2,
.h2-style {
  font-size: 2.3888888889em;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 479px) {
  h2,
  .h2-style {
    font-size: 2.1111111111em;
  }
}
h3,
.h3-style {
  font-family: "Rubik", sans-serif;
  font-size: 1.7777777778em;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 479px) {
  h3,
  .h3-style {
    font-size: 1.5555555556em;
  }
}
h4,
.h4-style {
  font-size: 1.6666666667em;
  font-family: "Rubik", sans-serif;
  line-height: 1.1;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 479px) {
  h4,
  .h4-style {
    font-size: 1.5em;
  }
}
h5,
.h5-style {
  font-size: 1.3888888889em;
  font-family: "Rubik", sans-serif;
  line-height: 1.3;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 479px) {
  h5,
  .h5-style {
    font-size: 1.2777777778em;
  }
}
h6,
.h6-style {
  font-size: 1.2777777778em;
  line-height: 1.1;
  font-weight: 700;
}

p,
ul,
ol {
  margin-bottom: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.6em;
}

blockquote {
  border-left: 0.5555555556rem solid currentColor;
  padding: 2rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  font-style: italic;
}

blockquote > *:last-child {
  margin-bottom: 0;
}

section ul li {
  list-style-type: disc;
  margin-left: 1em;
}

section ol li {
  margin-left: 1em;
}

ul.list-style-none li {
  list-style-type: none !important;
}

ul.list-style-dots li {
  list-style-type: disc !important;
}

ul.list-style-triangle li {
  list-style-type: disclosure-closed !important;
}

header a,
footer a {
  display: inline-block;
}

.design-block a:not(.default) {
  text-decoration: none;
}

.design-block a:not(.default):not(.btn):hover {
  opacity: 1;
}

.design-block ul:not(.default) li,
.design-block ol:not(.default) li {
  list-style-type: none;
}

img.img-fw {
  width: 100%;
}

a obj {
  pointer-events: none;
}

.btn {
  padding: 0.85rem 1rem;
  text-align: center;
  text-decoration: none;
  border: 1px solid #000000;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 24px;
  color: #000000;
  background-color: transparent;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  line-height: 1.4;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.05em;
}

.btn.active, .btn:hover {
  background-color: #000000;
  color: #fff;
}

@media screen and (max-width: 990px) {
  .btn {
    font-size: 1rem;
  }
}
@media screen and (min-width: 991px) {
  .btn {
    min-width: 165px;
  }
}
.btn.bold {
  font-weight: 700;
}

.btn-small {
  font-size: 0.7rem;
}

@media screen and (max-width: 990px) {
  .btn-small {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 991px) {
  .btn-small {
    min-width: 125px;
  }
}
.btn-large {
  font-size: 1rem;
}

@media screen and (max-width: 990px) {
  .btn-large {
    font-size: 1rem;
  }
}
@media screen and (min-width: 991px) {
  .btn-large {
    min-width: 272px;
  }
}
.btn-inverted {
  background-color: #000000;
  color: #fff;
}

.btn-inverted:hover {
  background-color: #fff;
  color: #000000;
}

.btn-white {
  background-color: #fff;
}

.btn-white:hover {
  background-color: #000000;
  color: #fff;
}

.btn-white-dimm {
  background-color: #fff;
  color: #000000;
  border: 1px solid #fff;
}

.btn-white-dimm:hover {
  background-color: #fff;
  color: #000000;
  filter: brightness(90%);
}

.btn-gray {
  border: 1px solid #505050;
  color: #505050;
}

.btn-gray:hover {
  background-color: #505050;
  color: #000000;
}

.btn-gray-inverted {
  border: 1px solid #505050;
  background-color: #505050;
  color: #000000;
}

.btn-gray-inverted:hover {
  border: 1px solid #505050;
  background-color: transparent;
  color: #505050;
}

.btn-gray-dimm {
  background-color: #505050;
  color: #000000;
  border: 1px solid #505050;
}

.btn-gray-dimm:hover {
  background-color: #505050;
  color: #000000;
  filter: brightness(90%);
}

.btn-darkgray {
  border: 1px solid #2C2731;
  color: #2C2731;
}

.btn-darkgray:hover {
  background-color: #2C2731;
  color: #fff;
}

.btn-darkgray-inverted {
  border: 1px solid #2C2731;
  background-color: #2C2731;
  color: #fff;
}

.btn-darkgray-inverted:hover {
  border: 1px solid #2C2731;
  background-color: transparent;
  color: #2C2731;
}

.btn-darkgray-dimm {
  background-color: #2C2731;
  color: #fff;
  border: 1px solid #2C2731;
}

.btn-darkgray-dimm:hover {
  background-color: #2C2731;
  color: #fff;
  filter: brightness(90%);
}

.btn-yellow {
  border: 1px solid #ffdb3c;
  color: #ffdb3c;
}

.btn-yellow:hover {
  background-color: #ffdb3c;
  color: #3c3c3c;
}

.btn-yellow-inverted {
  border: 1px solid #ffdb3c;
  background-color: #ffdb3c;
  color: #3c3c3c;
}

.btn-yellow-inverted:hover {
  border: 1px solid #ffdb3c;
  background-color: transparent;
  color: #ffdb3c;
}

.btn-yellow-dimm {
  background-color: #ffdb3c;
  color: #3c3c3c;
  border: 1px solid #ffdb3c;
}

.btn-yellow-dimm:hover {
  background-color: #ffdb3c;
  color: #3c3c3c;
  filter: brightness(90%);
}

.btn-purple {
  border: 1px solid #7f87ac;
  color: #7f87ac;
}

.btn-purple:hover {
  background-color: #7f87ac;
  color: #fff;
}

.btn-purple-inverted {
  border: 1px solid #7f87ac;
  background-color: #7f87ac;
  color: #fff;
}

.btn-purple-inverted:hover {
  border: 1px solid #7f87ac;
  background-color: transparent;
  color: #7f87ac;
}

.btn-purple-dimm {
  background-color: #7f87ac;
  color: #fff;
  border: 1px solid #7f87ac;
}

.btn-purple-dimm:hover {
  background-color: #7f87ac;
  color: #fff;
  filter: brightness(90%);
}

.btn-lightpurple {
  border: 1px solid #f2f5fd;
  color: #f2f5fd;
}

.btn-lightpurple:hover {
  background-color: #f2f5fd;
  color: #443b5f;
}

.btn-lightpurple-inverted {
  border: 1px solid #f2f5fd;
  background-color: #f2f5fd;
  color: #443b5f;
}

.btn-lightpurple-inverted:hover {
  border: 1px solid #f2f5fd;
  background-color: transparent;
  color: #f2f5fd;
}

.btn-lightpurple-dimm {
  background-color: #f2f5fd;
  color: #443b5f;
  border: 1px solid #f2f5fd;
}

.btn-lightpurple-dimm:hover {
  background-color: #f2f5fd;
  color: #443b5f;
  filter: brightness(90%);
}

.btn-pink {
  border: 1px solid #fff5ec;
  color: #fff5ec;
}

.btn-pink:hover {
  background-color: #fff5ec;
  color: #3c3c3c;
}

.btn-pink-inverted {
  border: 1px solid #fff5ec;
  background-color: #fff5ec;
  color: #3c3c3c;
}

.btn-pink-inverted:hover {
  border: 1px solid #fff5ec;
  background-color: transparent;
  color: #fff5ec;
}

.btn-pink-dimm {
  background-color: #fff5ec;
  color: #3c3c3c;
  border: 1px solid #fff5ec;
}

.btn-pink-dimm:hover {
  background-color: #fff5ec;
  color: #3c3c3c;
  filter: brightness(90%);
}

.btn-blue {
  border: 1px solid #443b5f;
  color: #443b5f;
}

.btn-blue:hover {
  background-color: #443b5f;
  color: #3c3c3c;
}

.btn-blue-inverted {
  border: 1px solid #443b5f;
  background-color: #443b5f;
  color: #3c3c3c;
}

.btn-blue-inverted:hover {
  border: 1px solid #443b5f;
  background-color: transparent;
  color: #443b5f;
}

.btn-blue-dimm {
  background-color: #443b5f;
  color: #3c3c3c;
  border: 1px solid #443b5f;
}

.btn-blue-dimm:hover {
  background-color: #443b5f;
  color: #3c3c3c;
  filter: brightness(90%);
}

.btn-green {
  border: 1px solid #7eb2a3;
  color: #7eb2a3;
}

.btn-green:hover {
  background-color: #7eb2a3;
  color: #3c3c3c;
}

.btn-green-inverted {
  border: 1px solid #7eb2a3;
  background-color: #7eb2a3;
  color: #fff;
}

.btn-green-inverted:hover {
  border: 1px solid #7eb2a3;
  background-color: transparent;
  color: #7eb2a3;
}

.btn-green-dimm {
  background-color: #7eb2a3;
  color: #fff5ec;
  border: 1px solid #7eb2a3;
}

.btn-green-dimm:hover {
  background-color: #7eb2a3;
  color: #fff5ec;
  filter: brightness(90%);
}

.btn-lightgreen {
  border: 1px solid #e1f4ee;
  color: #e1f4ee;
}

.btn-lightgreen:hover {
  background-color: #e1f4ee;
  color: #443b5f;
}

.btn-lightgreen-inverted {
  border: 1px solid #e1f4ee;
  background-color: #e1f4ee;
  color: #443b5f;
}

.btn-lightgreen-inverted:hover {
  border: 1px solid #e1f4ee;
  background-color: transparent;
  color: #e1f4ee;
}

.btn-lightgreen-dimm {
  background-color: #e1f4ee;
  color: #443b5f;
  border: 1px solid #e1f4ee;
}

.btn-lightgreen-dimm:hover {
  background-color: #e1f4ee;
  color: #443b5f;
  filter: brightness(90%);
}

.btn-scarlet {
  border: 1px solid #db8443;
  color: #db8443;
}

.btn-scarlet:hover {
  background-color: #db8443;
  color: #fff;
}

.btn-scarlet-inverted {
  background-color: #db8443;
  border: 1px solid #db8443;
  color: #fff;
}

.btn-scarlet-inverted:hover {
  border: 1px solid #db8443;
  background-color: transparent;
  color: #db8443;
}

.btn-scarlet-dimm {
  background-color: #db8443;
  color: #fff;
  border: 1px solid #db8443;
}

.btn-scarlet-dimm:hover {
  background-color: #db8443;
  color: #fff;
  filter: brightness(90%);
}

.btn-orange {
  border: 1px solid #ffe2be;
  color: #ffe2be;
}

.btn-orange:hover {
  background-color: #ffe2be;
  color: #fff;
}

.btn-orange-inverted {
  background-color: #ffe2be;
  color: #fff;
  border: 1px solid #ffe2be;
}

.btn-orange-inverted:hover {
  border: 1px solid #ffe2be;
  background-color: transparent;
  color: #ffe2be;
}

.btn-orange-dimm {
  background-color: #ffe2be;
  color: #fff;
  border: 1px solid #ffe2be;
}

.btn-orange-dimm:hover {
  background-color: #ffe2be;
  color: #fff;
  filter: brightness(90%);
}

.btn-red {
  border: 1px solid #d0685c;
  color: #d0685c;
}

.btn-red:hover {
  background-color: #d0685c;
  color: #fff;
}

.btn-red-inverted {
  background-color: #d0685c;
  border: 1px solid #d0685c;
  color: #fff;
}

.btn-red-inverted:hover {
  border: 1px solid #d0685c;
  background-color: transparent;
  color: #d0685c;
}

.btn-red-dimm {
  background-color: #d0685c;
  color: #fff;
  border: 1px solid #d0685c;
}

.btn-red-dimm:hover {
  background-color: #d0685c;
  color: #fff;
  filter: brightness(90%);
}

.btn-rounded {
  border-radius: 1rem;
}

.btn[class^=btn-animated], .btn[class*=" btn-animated"] {
  border: none !important;
  position: relative;
  overflow: hidden;
}

.btn[class^=btn-animated] span, .btn[class*=" btn-animated"] span {
  z-index: 5;
  position: relative;
}

.btn[class^=btn-animated]:hover:before, .btn[class*=" btn-animated"]:hover:before {
  right: -50%;
  top: -50%;
}

.btn[class^=btn-animated]:before, .btn[class*=" btn-animated"]:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  top: -5px;
  transition: all 0.7s;
  width: 200%;
  height: 300%;
  right: 100%;
}

.btn[class^=btn-animated][class*=-from-white], .btn[class*=" btn-animated"][class*=-from-white] {
  background-color: #fff;
  color: #000000;
}

.btn[class^=btn-animated][class*=-from-black], .btn[class*=" btn-animated"][class*=-from-black] {
  background-color: #000000;
  color: #fff;
}

.btn[class^=btn-animated][class*=-from-gray], .btn[class*=" btn-animated"][class*=-from-gray] {
  background-color: #505050;
  color: #000000;
}

.btn[class^=btn-animated][class*=-from-darkgray], .btn[class*=" btn-animated"][class*=-from-darkgray] {
  background-color: #2C2731;
  color: #fff;
}

.btn[class^=btn-animated][class*=-from-blue], .btn[class*=" btn-animated"][class*=-from-blue] {
  background-color: #443b5f;
  color: #000000;
}

.btn[class^=btn-animated][class*=-from-green], .btn[class*=" btn-animated"][class*=-from-green] {
  background-color: #7eb2a3;
  color: #000000;
}

.btn[class^=btn-animated][class*=-from-pink], .btn[class*=" btn-animated"][class*=-from-pink] {
  background-color: #fff5ec;
  color: #000000;
}

.btn[class^=btn-animated][class*=-from-yellow], .btn[class*=" btn-animated"][class*=-from-yellow] {
  background-color: #ffdb3c;
  color: #fff;
}

.btn[class^=btn-animated][class*=-from-red], .btn[class*=" btn-animated"][class*=-from-red] {
  background-color: #d0685c;
  color: #fff;
}

.btn[class^=btn-animated][class*=-from-orange], .btn[class*=" btn-animated"][class*=-from-orange] {
  background-color: #ffe2be;
  color: #fff;
}

.btn[class^=btn-animated][class*=-from-scarlet], .btn[class*=" btn-animated"][class*=-from-scarlet] {
  background-color: #db8443;
  color: #fff;
}

.btn[class^=btn-animated][class*=-from-purple], .btn[class*=" btn-animated"][class*=-from-purple] {
  background-color: #7f87ac;
  color: #fff;
}

.btn[class^=btn-animated][class*=-to-pink]:hover, .btn[class*=" btn-animated"][class*=-to-pink]:hover {
  color: #000000;
}

.btn[class^=btn-animated][class*=-to-pink]:before, .btn[class*=" btn-animated"][class*=-to-pink]:before {
  background-color: #fff5ec;
}

.btn[class^=btn-animated][class*=-to-blue]:hover, .btn[class*=" btn-animated"][class*=-to-blue]:hover {
  color: #000000;
}

.btn[class^=btn-animated][class*=-to-blue]:before, .btn[class*=" btn-animated"][class*=-to-blue]:before {
  background-color: #443b5f;
}

.btn[class^=btn-animated][class*=-to-green]:hover, .btn[class*=" btn-animated"][class*=-to-green]:hover {
  color: #000000;
}

.btn[class^=btn-animated][class*=-to-green]:before, .btn[class*=" btn-animated"][class*=-to-green]:before {
  background-color: #7eb2a3;
}

.btn[class^=btn-animated][class*=-to-yellow]:hover, .btn[class*=" btn-animated"][class*=-to-yellow]:hover {
  color: #000000;
}

.btn[class^=btn-animated][class*=-to-yellow]:before, .btn[class*=" btn-animated"][class*=-to-yellow]:before {
  background-color: #ffdb3c;
}

.btn[class^=btn-animated][class*=-to-red]:hover, .btn[class*=" btn-animated"][class*=-to-red]:hover {
  color: #fff;
}

.btn[class^=btn-animated][class*=-to-red]:before, .btn[class*=" btn-animated"][class*=-to-red]:before {
  background-color: #d0685c;
}

.btn[class^=btn-animated][class*=-to-purple]:hover, .btn[class*=" btn-animated"][class*=-to-purple]:hover {
  color: #fff;
}

.btn[class^=btn-animated][class*=-to-purple]:before, .btn[class*=" btn-animated"][class*=-to-purple]:before {
  background-color: #7f87ac;
}

.btn[class^=btn-animated][class*=-to-scarlet]:hover, .btn[class*=" btn-animated"][class*=-to-scarlet]:hover {
  color: #fff;
}

.btn[class^=btn-animated][class*=-to-scarlet]:before, .btn[class*=" btn-animated"][class*=-to-scarlet]:before {
  background-color: #db8443;
}

.btn[class^=btn-animated][class*=-to-orange]:hover, .btn[class*=" btn-animated"][class*=-to-orange]:hover {
  color: #fff;
}

.btn[class^=btn-animated][class*=-to-orange]:before, .btn[class*=" btn-animated"][class*=-to-orange]:before {
  background-color: #ffe2be;
}

.btn[class^=btn-animated][class*=-to-white]:hover, .btn[class*=" btn-animated"][class*=-to-white]:hover {
  color: #000000;
}

.btn[class^=btn-animated][class*=-to-white]:before, .btn[class*=" btn-animated"][class*=-to-white]:before {
  background-color: #fff;
}

.btn[class^=btn-animated][class*=-to-black]:hover, .btn[class*=" btn-animated"][class*=-to-black]:hover {
  color: #fff;
}

.btn[class^=btn-animated][class*=-to-black]:before, .btn[class*=" btn-animated"][class*=-to-black]:before {
  background-color: #000000;
}

.btn[class^=btn-animated][class*=-to-gray]:hover, .btn[class*=" btn-animated"][class*=-to-gray]:hover {
  color: #000000;
}

.btn[class^=btn-animated][class*=-to-gray]:before, .btn[class*=" btn-animated"][class*=-to-gray]:before {
  background-color: #505050;
}

.btn[class^=btn-animated][class*=-to-darkgray]:hover, .btn[class*=" btn-animated"][class*=-to-darkgray]:hover {
  color: #fff;
}

.btn[class^=btn-animated][class*=-to-darkgray]:before, .btn[class*=" btn-animated"][class*=-to-darkgray]:before {
  background-color: #505050;
}

.table-container {
  overflow-x: auto;
}

.table-fw {
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
}

table td,
table th {
  vertical-align: top;
  border: 1px solid #505050;
  padding: 5px;
}

table th {
  font-weight: 700;
  text-align: center;
}

table.text-left td,
table.text-left th {
  text-align: left;
}

table.text-right td,
table.text-right th {
  text-align: right;
}

table.text-center td,
table.text-center th {
  text-align: center;
}

table.table-inverted {
  color: #F9FAF0;
}

table.table-valign-top td,
table.table-valign-top th {
  vertical-align: top;
}

table.table-valign-middle td,
table.table-valign-middle th {
  vertical-align: middle;
}

table.table-valign-bottom td,
table.table-valign-bottom th {
  vertical-align: bottom;
}

xmp {
  font-size: 0.7rem;
  font-family: monospace;
}

.code-container {
  overflow-x: auto;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.basis-5 {
  flex-basis: 5%;
}

@media screen and (min-width: 991px) {
  .sized-5 {
    max-width: 5%;
  }
  .basis-5 {
    max-width: 5%;
  }
  .sized-dt-5,
  .basis-dt-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-5,
  .basis-ls-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-5,
  .sized-mh-5 {
    max-width: 5%;
  }
  .basis-mobile-h-5,
  .basis-mh-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-5,
  .sized-mv-5 {
    max-width: 5%;
  }
  .basis-mobile-v-5,
  .basis-mv-5 {
    flex-basis: 5%;
    max-width: 5%;
  }
}
.basis-10 {
  flex-basis: 10%;
}

@media screen and (min-width: 991px) {
  .sized-10 {
    max-width: 10%;
  }
  .basis-10 {
    max-width: 10%;
  }
  .sized-dt-10,
  .basis-dt-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-10,
  .basis-ls-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-10,
  .sized-mh-10 {
    max-width: 10%;
  }
  .basis-mobile-h-10,
  .basis-mh-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-10,
  .sized-mv-10 {
    max-width: 10%;
  }
  .basis-mobile-v-10,
  .basis-mv-10 {
    flex-basis: 10%;
    max-width: 10%;
  }
}
.basis-15 {
  flex-basis: 15%;
}

@media screen and (min-width: 991px) {
  .sized-15 {
    max-width: 15%;
  }
  .basis-15 {
    max-width: 15%;
  }
  .sized-dt-15,
  .basis-dt-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-15,
  .basis-ls-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-15,
  .sized-mh-15 {
    max-width: 15%;
  }
  .basis-mobile-h-15,
  .basis-mh-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-15,
  .sized-mv-15 {
    max-width: 15%;
  }
  .basis-mobile-v-15,
  .basis-mv-15 {
    flex-basis: 15%;
    max-width: 15%;
  }
}
.basis-20 {
  flex-basis: 20%;
}

@media screen and (min-width: 991px) {
  .sized-20 {
    max-width: 20%;
  }
  .basis-20 {
    max-width: 20%;
  }
  .sized-dt-20,
  .basis-dt-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-20,
  .basis-ls-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-20,
  .sized-mh-20 {
    max-width: 20%;
  }
  .basis-mobile-h-20,
  .basis-mh-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-20,
  .sized-mv-20 {
    max-width: 20%;
  }
  .basis-mobile-v-20,
  .basis-mv-20 {
    flex-basis: 20%;
    max-width: 20%;
  }
}
.basis-25 {
  flex-basis: 25%;
}

@media screen and (min-width: 991px) {
  .sized-25 {
    max-width: 25%;
  }
  .basis-25 {
    max-width: 25%;
  }
  .sized-dt-25,
  .basis-dt-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-25,
  .basis-ls-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-25,
  .sized-mh-25 {
    max-width: 25%;
  }
  .basis-mobile-h-25,
  .basis-mh-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-25,
  .sized-mv-25 {
    max-width: 25%;
  }
  .basis-mobile-v-25,
  .basis-mv-25 {
    flex-basis: 25%;
    max-width: 25%;
  }
}
.basis-30 {
  flex-basis: 30%;
}

@media screen and (min-width: 991px) {
  .sized-30 {
    max-width: 30%;
  }
  .basis-30 {
    max-width: 30%;
  }
  .sized-dt-30,
  .basis-dt-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-30,
  .basis-ls-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-30,
  .sized-mh-30 {
    max-width: 30%;
  }
  .basis-mobile-h-30,
  .basis-mh-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-30,
  .sized-mv-30 {
    max-width: 30%;
  }
  .basis-mobile-v-30,
  .basis-mv-30 {
    flex-basis: 30%;
    max-width: 30%;
  }
}
.basis-35 {
  flex-basis: 35%;
}

@media screen and (min-width: 991px) {
  .sized-35 {
    max-width: 35%;
  }
  .basis-35 {
    max-width: 35%;
  }
  .sized-dt-35,
  .basis-dt-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-35,
  .basis-ls-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-35,
  .sized-mh-35 {
    max-width: 35%;
  }
  .basis-mobile-h-35,
  .basis-mh-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-35,
  .sized-mv-35 {
    max-width: 35%;
  }
  .basis-mobile-v-35,
  .basis-mv-35 {
    flex-basis: 35%;
    max-width: 35%;
  }
}
.basis-40 {
  flex-basis: 40%;
}

@media screen and (min-width: 991px) {
  .sized-40 {
    max-width: 40%;
  }
  .basis-40 {
    max-width: 40%;
  }
  .sized-dt-40,
  .basis-dt-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-40,
  .basis-ls-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-40,
  .sized-mh-40 {
    max-width: 40%;
  }
  .basis-mobile-h-40,
  .basis-mh-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-40,
  .sized-mv-40 {
    max-width: 40%;
  }
  .basis-mobile-v-40,
  .basis-mv-40 {
    flex-basis: 40%;
    max-width: 40%;
  }
}
.basis-45 {
  flex-basis: 45%;
}

@media screen and (min-width: 991px) {
  .sized-45 {
    max-width: 45%;
  }
  .basis-45 {
    max-width: 45%;
  }
  .sized-dt-45,
  .basis-dt-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-45,
  .basis-ls-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-45,
  .sized-mh-45 {
    max-width: 45%;
  }
  .basis-mobile-h-45,
  .basis-mh-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-45,
  .sized-mv-45 {
    max-width: 45%;
  }
  .basis-mobile-v-45,
  .basis-mv-45 {
    flex-basis: 45%;
    max-width: 45%;
  }
}
.basis-50 {
  flex-basis: 50%;
}

@media screen and (min-width: 991px) {
  .sized-50 {
    max-width: 50%;
  }
  .basis-50 {
    max-width: 50%;
  }
  .sized-dt-50,
  .basis-dt-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-50,
  .basis-ls-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-50,
  .sized-mh-50 {
    max-width: 50%;
  }
  .basis-mobile-h-50,
  .basis-mh-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-50,
  .sized-mv-50 {
    max-width: 50%;
  }
  .basis-mobile-v-50,
  .basis-mv-50 {
    flex-basis: 50%;
    max-width: 50%;
  }
}
.basis-55 {
  flex-basis: 55%;
}

@media screen and (min-width: 991px) {
  .sized-55 {
    max-width: 55%;
  }
  .basis-55 {
    max-width: 55%;
  }
  .sized-dt-55,
  .basis-dt-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-55,
  .basis-ls-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-55,
  .sized-mh-55 {
    max-width: 55%;
  }
  .basis-mobile-h-55,
  .basis-mh-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-55,
  .sized-mv-55 {
    max-width: 55%;
  }
  .basis-mobile-v-55,
  .basis-mv-55 {
    flex-basis: 55%;
    max-width: 55%;
  }
}
.basis-60 {
  flex-basis: 60%;
}

@media screen and (min-width: 991px) {
  .sized-60 {
    max-width: 60%;
  }
  .basis-60 {
    max-width: 60%;
  }
  .sized-dt-60,
  .basis-dt-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-60,
  .basis-ls-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-60,
  .sized-mh-60 {
    max-width: 60%;
  }
  .basis-mobile-h-60,
  .basis-mh-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-60,
  .sized-mv-60 {
    max-width: 60%;
  }
  .basis-mobile-v-60,
  .basis-mv-60 {
    flex-basis: 60%;
    max-width: 60%;
  }
}
.basis-65 {
  flex-basis: 65%;
}

@media screen and (min-width: 991px) {
  .sized-65 {
    max-width: 65%;
  }
  .basis-65 {
    max-width: 65%;
  }
  .sized-dt-65,
  .basis-dt-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-65,
  .basis-ls-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-65,
  .sized-mh-65 {
    max-width: 65%;
  }
  .basis-mobile-h-65,
  .basis-mh-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-65,
  .sized-mv-65 {
    max-width: 65%;
  }
  .basis-mobile-v-65,
  .basis-mv-65 {
    flex-basis: 65%;
    max-width: 65%;
  }
}
.basis-70 {
  flex-basis: 70%;
}

@media screen and (min-width: 991px) {
  .sized-70 {
    max-width: 70%;
  }
  .basis-70 {
    max-width: 70%;
  }
  .sized-dt-70,
  .basis-dt-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-70,
  .basis-ls-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-70,
  .sized-mh-70 {
    max-width: 70%;
  }
  .basis-mobile-h-70,
  .basis-mh-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-70,
  .sized-mv-70 {
    max-width: 70%;
  }
  .basis-mobile-v-70,
  .basis-mv-70 {
    flex-basis: 70%;
    max-width: 70%;
  }
}
.basis-75 {
  flex-basis: 75%;
}

@media screen and (min-width: 991px) {
  .sized-75 {
    max-width: 75%;
  }
  .basis-75 {
    max-width: 75%;
  }
  .sized-dt-75,
  .basis-dt-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-75,
  .basis-ls-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-75,
  .sized-mh-75 {
    max-width: 75%;
  }
  .basis-mobile-h-75,
  .basis-mh-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-75,
  .sized-mv-75 {
    max-width: 75%;
  }
  .basis-mobile-v-75,
  .basis-mv-75 {
    flex-basis: 75%;
    max-width: 75%;
  }
}
.basis-80 {
  flex-basis: 80%;
}

@media screen and (min-width: 991px) {
  .sized-80 {
    max-width: 80%;
  }
  .basis-80 {
    max-width: 80%;
  }
  .sized-dt-80,
  .basis-dt-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-80,
  .basis-ls-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-80,
  .sized-mh-80 {
    max-width: 80%;
  }
  .basis-mobile-h-80,
  .basis-mh-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-80,
  .sized-mv-80 {
    max-width: 80%;
  }
  .basis-mobile-v-80,
  .basis-mv-80 {
    flex-basis: 80%;
    max-width: 80%;
  }
}
.basis-85 {
  flex-basis: 85%;
}

@media screen and (min-width: 991px) {
  .sized-85 {
    max-width: 85%;
  }
  .basis-85 {
    max-width: 85%;
  }
  .sized-dt-85,
  .basis-dt-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-85,
  .basis-ls-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-85,
  .sized-mh-85 {
    max-width: 85%;
  }
  .basis-mobile-h-85,
  .basis-mh-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-85,
  .sized-mv-85 {
    max-width: 85%;
  }
  .basis-mobile-v-85,
  .basis-mv-85 {
    flex-basis: 85%;
    max-width: 85%;
  }
}
.basis-90 {
  flex-basis: 90%;
}

@media screen and (min-width: 991px) {
  .sized-90 {
    max-width: 90%;
  }
  .basis-90 {
    max-width: 90%;
  }
  .sized-dt-90,
  .basis-dt-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-90,
  .basis-ls-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-90,
  .sized-mh-90 {
    max-width: 90%;
  }
  .basis-mobile-h-90,
  .basis-mh-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-90,
  .sized-mv-90 {
    max-width: 90%;
  }
  .basis-mobile-v-90,
  .basis-mv-90 {
    flex-basis: 90%;
    max-width: 90%;
  }
}
.basis-95 {
  flex-basis: 95%;
}

@media screen and (min-width: 991px) {
  .sized-95 {
    max-width: 95%;
  }
  .basis-95 {
    max-width: 95%;
  }
  .sized-dt-95,
  .basis-dt-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-95,
  .basis-ls-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-95,
  .sized-mh-95 {
    max-width: 95%;
  }
  .basis-mobile-h-95,
  .basis-mh-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-95,
  .sized-mv-95 {
    max-width: 95%;
  }
  .basis-mobile-v-95,
  .basis-mv-95 {
    flex-basis: 95%;
    max-width: 95%;
  }
}
.basis-100 {
  flex-basis: 100%;
}

@media screen and (min-width: 991px) {
  .sized-100 {
    max-width: 100%;
  }
  .basis-100 {
    max-width: 100%;
  }
  .sized-dt-100,
  .basis-dt-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 2430px) {
  .sized-ls-100,
  .basis-ls-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .sized-mobile-h-100,
  .sized-mh-100 {
    max-width: 100%;
  }
  .basis-mobile-h-100,
  .basis-mh-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .sized-mobile-v-100,
  .sized-mv-100 {
    max-width: 100%;
  }
  .basis-mobile-v-100,
  .basis-mv-100 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.wrap-2 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-2 > * {
  flex: 0 0 calc(100% / 2);
  padding: 15px;
}

.wrap-3 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-3 > * {
  flex: 0 0 calc(100% / 3);
  padding: 15px;
}

.wrap-4 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-4 > * {
  flex: 0 0 calc(100% / 4);
  padding: 15px;
}

.wrap-5 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-5 > * {
  flex: 0 0 calc(100% / 5);
  padding: 15px;
}

.wrap-6 {
  flex-wrap: wrap;
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.wrap-6 > * {
  flex: 0 0 calc(100% / 6);
  padding: 15px;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-2 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-2 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-3 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-3 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-4 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-4 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-5 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-5 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-6 > * {
    flex: 0 0 49%;
  }
}
@media screen and (max-width: 479px) {
  .wrap-6 > * {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-1 > *,
  .wrap-mh-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-1 > *,
  .wrap-mv-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-1 > * {
    flex: 0 0 calc(100% / 1);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-2 > *,
  .wrap-mh-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-2 > *,
  .wrap-mv-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-2 > * {
    flex: 0 0 calc(100% / 2);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-3 > *,
  .wrap-mh-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-3 > *,
  .wrap-mv-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-3 > * {
    flex: 0 0 calc(100% / 3);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-4 > *,
  .wrap-mh-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-4 > *,
  .wrap-mv-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-4 > * {
    flex: 0 0 calc(100% / 4);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-5 > *,
  .wrap-mh-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-5 > *,
  .wrap-mv-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-5 > * {
    flex: 0 0 calc(100% / 5);
    padding: 15px;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .wrap-mobile-h-6 > *,
  .wrap-mh-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (max-width: 479px) {
  .wrap-mobile-v-6 > *,
  .wrap-mv-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (min-width: 991px) {
  .wrap-dt-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
@media screen and (min-width: 2430px) {
  .wrap-ls-6 > * {
    flex: 0 0 calc(100% / 6);
    padding: 15px;
  }
}
.col-gap-0 {
  column-gap: 0rem;
}

.col-gap-1 {
  column-gap: 1rem;
}

.col-gap-2 {
  column-gap: 2rem;
}

.col-gap-3 {
  column-gap: 3rem;
}

.col-gap-4 {
  column-gap: 4rem;
}

.col-gap-5 {
  column-gap: 5rem;
}

.m-0 {
  margin: 0rem;
}

.mt-0 {
  margin-top: 0rem;
}

.mb-0 {
  margin-bottom: 0rem;
}

.ml-0 {
  margin-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.ms-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}

.mv-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.pt-0 {
  padding-top: 0rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.pr-0 {
  padding-right: 0rem;
}

.ps-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}

.pv-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.p-0 {
  padding: 0rem;
}

.row.gap-0 {
  gap: 0rem;
}

.column-count-0 {
  column-count: 0;
}

.m-1 {
  margin: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ms-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mv-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.ps-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pv-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.p-1 {
  padding: 1rem;
}

.row.gap-1 {
  gap: 1rem;
}

.column-count-1 {
  column-count: 1;
}

.m-2 {
  margin: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.ms-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mv-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.ps-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pv-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.p-2 {
  padding: 2rem;
}

.row.gap-2 {
  gap: 2rem;
}

.column-count-2 {
  column-count: 2;
}

.m-3 {
  margin: 3rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mr-3 {
  margin-right: 3rem;
}

.ms-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mv-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: 3rem;
}

.ps-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pv-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-3 {
  padding: 3rem;
}

.row.gap-3 {
  gap: 3rem;
}

.column-count-3 {
  column-count: 3;
}

.m-4 {
  margin: 4rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.ml-4 {
  margin-left: 4rem;
}

.mr-4 {
  margin-right: 4rem;
}

.ms-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mv-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pr-4 {
  padding-right: 4rem;
}

.ps-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pv-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p-4 {
  padding: 4rem;
}

.row.gap-4 {
  gap: 4rem;
}

.column-count-4 {
  column-count: 4;
}

.m-5 {
  margin: 5rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-5 {
  margin-right: 5rem;
}

.ms-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mv-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pl-5 {
  padding-left: 5rem;
}

.pr-5 {
  padding-right: 5rem;
}

.ps-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.pv-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.p-5 {
  padding: 5rem;
}

.row.gap-5 {
  gap: 5rem;
}

.column-count-5 {
  column-count: 5;
}

.m-6 {
  margin: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.ml-6 {
  margin-left: 6rem;
}

.mr-6 {
  margin-right: 6rem;
}

.ms-6 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mv-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.pt-6 {
  padding-top: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.pl-6 {
  padding-left: 6rem;
}

.pr-6 {
  padding-right: 6rem;
}

.ps-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.pv-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.p-6 {
  padding: 6rem;
}

.row.gap-6 {
  gap: 6rem;
}

.column-count-6 {
  column-count: 6;
}

.m-7 {
  margin: 7rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mb-7 {
  margin-bottom: 7rem;
}

.ml-7 {
  margin-left: 7rem;
}

.mr-7 {
  margin-right: 7rem;
}

.ms-7 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.mv-7 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.pt-7 {
  padding-top: 7rem;
}

.pb-7 {
  padding-bottom: 7rem;
}

.pl-7 {
  padding-left: 7rem;
}

.pr-7 {
  padding-right: 7rem;
}

.ps-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.pv-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.p-7 {
  padding: 7rem;
}

.row.gap-7 {
  gap: 7rem;
}

.column-count-7 {
  column-count: 7;
}

.m-8 {
  margin: 8rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

.ml-8 {
  margin-left: 8rem;
}

.mr-8 {
  margin-right: 8rem;
}

.ms-8 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mv-8 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.pt-8 {
  padding-top: 8rem;
}

.pb-8 {
  padding-bottom: 8rem;
}

.pl-8 {
  padding-left: 8rem;
}

.pr-8 {
  padding-right: 8rem;
}

.ps-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.pv-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.p-8 {
  padding: 8rem;
}

.row.gap-8 {
  gap: 8rem;
}

.column-count-8 {
  column-count: 8;
}

.m-9 {
  margin: 9rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mb-9 {
  margin-bottom: 9rem;
}

.ml-9 {
  margin-left: 9rem;
}

.mr-9 {
  margin-right: 9rem;
}

.ms-9 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.mv-9 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.pt-9 {
  padding-top: 9rem;
}

.pb-9 {
  padding-bottom: 9rem;
}

.pl-9 {
  padding-left: 9rem;
}

.pr-9 {
  padding-right: 9rem;
}

.ps-9 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.pv-9 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.p-9 {
  padding: 9rem;
}

.row.gap-9 {
  gap: 9rem;
}

.column-count-9 {
  column-count: 9;
}

.m-10 {
  margin: 10rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.ml-10 {
  margin-left: 10rem;
}

.mr-10 {
  margin-right: 10rem;
}

.ms-10 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.mv-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.pt-10 {
  padding-top: 10rem;
}

.pb-10 {
  padding-bottom: 10rem;
}

.pl-10 {
  padding-left: 10rem;
}

.pr-10 {
  padding-right: 10rem;
}

.ps-10 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.pv-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.p-10 {
  padding: 10rem;
}

.row.gap-10 {
  gap: 10rem;
}

.column-count-10 {
  column-count: 10;
}

.mb--0 {
  margin-bottom: -0rem;
}

.mb--1 {
  margin-bottom: -1rem;
}

.mb--2 {
  margin-bottom: -2rem;
}

.mb--3 {
  margin-bottom: -3rem;
}

.mb--4 {
  margin-bottom: -4rem;
}

.mb--5 {
  margin-bottom: -5rem;
}

.mb--6 {
  margin-bottom: -6rem;
}

.mb--7 {
  margin-bottom: -7rem;
}

.mb--8 {
  margin-bottom: -8rem;
}

.mb--9 {
  margin-bottom: -9rem;
}

.mb--10 {
  margin-bottom: -10rem;
}

@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-0 {
    margin: 0rem;
  }
  .mt-mh-0 {
    margin-top: 0rem;
  }
  .mb-mh-0 {
    margin-bottom: 0rem;
  }
  .ml-mh-0 {
    margin-left: 0rem;
  }
  .mr-mh-0 {
    margin-right: 0rem;
  }
  .ms-mh-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mv-mh-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .pt-mh-0 {
    padding-top: 0rem;
  }
  .pb-mh-0 {
    padding-bottom: 0rem;
  }
  .pl-mh-0 {
    padding-left: 0rem;
  }
  .pr-mh-0 {
    padding-right: 0rem;
  }
  .ps-mh-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pv-mh-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .p-mh-0 {
    padding: 0rem;
  }
  .row.gap-mh-0 {
    gap: 0rem;
  }
  .column-count-mh-0 {
    column-count: 0;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-0 {
    margin: 0rem;
  }
  .mt-mv-0 {
    margin-top: 0rem;
  }
  .mb-mv-0 {
    margin-bottom: 0rem;
  }
  .ml-mv-0 {
    margin-left: 0rem;
  }
  .mr-mv-0 {
    margin-right: 0rem;
  }
  .ms-mv-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mv-mv-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .pt-mv-0 {
    padding-top: 0rem;
  }
  .pb-mv-0 {
    padding-bottom: 0rem;
  }
  .pl-mv-0 {
    padding-left: 0rem;
  }
  .pr-mv-0 {
    padding-right: 0rem;
  }
  .ps-mv-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pv-mv-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .p-mv-0 {
    padding: 0rem;
  }
  .row.gap-mv-0 {
    gap: 0rem;
  }
  .column-count-mv-0 {
    column-count: 0;
  }
}
@media screen and (min-width: 991px) {
  .rm-0,
  .m-dt-0 {
    margin: 0rem;
  }
  .rml-0,
  .ml-dt-0 {
    margin-left: 0rem;
  }
  .rmr-0,
  .mr-dt-0 {
    margin-right: 0rem;
  }
  .rmt-0,
  .mt-dt-0 {
    margin-top: 0rem;
  }
  .rmb-0,
  .mb-dt-0 {
    margin-bottom: 0rem;
  }
  .rmv-0,
  .mv-dt-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .rms-0,
  .ms-dt-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .rp-0,
  .p-dt-0 {
    padding: 0rem;
  }
  .rpl-0,
  .pl-dt-0 {
    padding-left: 0rem;
  }
  .rpr-0,
  .pr-dt-0 {
    padding-right: 0rem;
  }
  .rpt-0,
  .pt-dt-0 {
    padding-top: 0rem;
  }
  .rpb-0,
  .pb-dt-0 {
    padding-bottom: 0rem;
  }
  .rpv-0,
  .pv-dt-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .rps-0,
  .ps-dt-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .rcolumn-count-0,
  .column-count-dt-0 {
    column-count: 0;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-0 {
    margin: 0rem;
  }
  .mt-ls-0 {
    margin-top: 0rem;
  }
  .mb-ls-0 {
    margin-bottom: 0rem;
  }
  .ml-ls-0 {
    margin-left: 0rem;
  }
  .mr-ls-0 {
    margin-right: 0rem;
  }
  .ms-ls-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mv-ls-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .pt-ls-0 {
    padding-top: 0rem;
  }
  .pb-ls-0 {
    padding-bottom: 0rem;
  }
  .pl-ls-0 {
    padding-left: 0rem;
  }
  .pr-ls-0 {
    padding-right: 0rem;
  }
  .ps-ls-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pv-ls-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .p-ls-0 {
    padding: 0rem;
  }
  .row.gap-ls-0 {
    gap: 0rem;
  }
  .column-count-ls-0 {
    column-count: 0;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-1 {
    margin: 1rem;
  }
  .mt-mh-1 {
    margin-top: 1rem;
  }
  .mb-mh-1 {
    margin-bottom: 1rem;
  }
  .ml-mh-1 {
    margin-left: 1rem;
  }
  .mr-mh-1 {
    margin-right: 1rem;
  }
  .ms-mh-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mv-mh-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pt-mh-1 {
    padding-top: 1rem;
  }
  .pb-mh-1 {
    padding-bottom: 1rem;
  }
  .pl-mh-1 {
    padding-left: 1rem;
  }
  .pr-mh-1 {
    padding-right: 1rem;
  }
  .ps-mh-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pv-mh-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p-mh-1 {
    padding: 1rem;
  }
  .row.gap-mh-1 {
    gap: 1rem;
  }
  .column-count-mh-1 {
    column-count: 1;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-1 {
    margin: 1rem;
  }
  .mt-mv-1 {
    margin-top: 1rem;
  }
  .mb-mv-1 {
    margin-bottom: 1rem;
  }
  .ml-mv-1 {
    margin-left: 1rem;
  }
  .mr-mv-1 {
    margin-right: 1rem;
  }
  .ms-mv-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mv-mv-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pt-mv-1 {
    padding-top: 1rem;
  }
  .pb-mv-1 {
    padding-bottom: 1rem;
  }
  .pl-mv-1 {
    padding-left: 1rem;
  }
  .pr-mv-1 {
    padding-right: 1rem;
  }
  .ps-mv-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pv-mv-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p-mv-1 {
    padding: 1rem;
  }
  .row.gap-mv-1 {
    gap: 1rem;
  }
  .column-count-mv-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 991px) {
  .rm-1,
  .m-dt-1 {
    margin: 1rem;
  }
  .rml-1,
  .ml-dt-1 {
    margin-left: 1rem;
  }
  .rmr-1,
  .mr-dt-1 {
    margin-right: 1rem;
  }
  .rmt-1,
  .mt-dt-1 {
    margin-top: 1rem;
  }
  .rmb-1,
  .mb-dt-1 {
    margin-bottom: 1rem;
  }
  .rmv-1,
  .mv-dt-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .rms-1,
  .ms-dt-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .rp-1,
  .p-dt-1 {
    padding: 1rem;
  }
  .rpl-1,
  .pl-dt-1 {
    padding-left: 1rem;
  }
  .rpr-1,
  .pr-dt-1 {
    padding-right: 1rem;
  }
  .rpt-1,
  .pt-dt-1 {
    padding-top: 1rem;
  }
  .rpb-1,
  .pb-dt-1 {
    padding-bottom: 1rem;
  }
  .rpv-1,
  .pv-dt-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .rps-1,
  .ps-dt-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .rcolumn-count-1,
  .column-count-dt-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-1 {
    margin: 1rem;
  }
  .mt-ls-1 {
    margin-top: 1rem;
  }
  .mb-ls-1 {
    margin-bottom: 1rem;
  }
  .ml-ls-1 {
    margin-left: 1rem;
  }
  .mr-ls-1 {
    margin-right: 1rem;
  }
  .ms-ls-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mv-ls-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pt-ls-1 {
    padding-top: 1rem;
  }
  .pb-ls-1 {
    padding-bottom: 1rem;
  }
  .pl-ls-1 {
    padding-left: 1rem;
  }
  .pr-ls-1 {
    padding-right: 1rem;
  }
  .ps-ls-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .pv-ls-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .p-ls-1 {
    padding: 1rem;
  }
  .row.gap-ls-1 {
    gap: 1rem;
  }
  .column-count-ls-1 {
    column-count: 1;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-2 {
    margin: 2rem;
  }
  .mt-mh-2 {
    margin-top: 2rem;
  }
  .mb-mh-2 {
    margin-bottom: 2rem;
  }
  .ml-mh-2 {
    margin-left: 2rem;
  }
  .mr-mh-2 {
    margin-right: 2rem;
  }
  .ms-mh-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mv-mh-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pt-mh-2 {
    padding-top: 2rem;
  }
  .pb-mh-2 {
    padding-bottom: 2rem;
  }
  .pl-mh-2 {
    padding-left: 2rem;
  }
  .pr-mh-2 {
    padding-right: 2rem;
  }
  .ps-mh-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pv-mh-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p-mh-2 {
    padding: 2rem;
  }
  .row.gap-mh-2 {
    gap: 2rem;
  }
  .column-count-mh-2 {
    column-count: 2;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-2 {
    margin: 2rem;
  }
  .mt-mv-2 {
    margin-top: 2rem;
  }
  .mb-mv-2 {
    margin-bottom: 2rem;
  }
  .ml-mv-2 {
    margin-left: 2rem;
  }
  .mr-mv-2 {
    margin-right: 2rem;
  }
  .ms-mv-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mv-mv-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pt-mv-2 {
    padding-top: 2rem;
  }
  .pb-mv-2 {
    padding-bottom: 2rem;
  }
  .pl-mv-2 {
    padding-left: 2rem;
  }
  .pr-mv-2 {
    padding-right: 2rem;
  }
  .ps-mv-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pv-mv-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p-mv-2 {
    padding: 2rem;
  }
  .row.gap-mv-2 {
    gap: 2rem;
  }
  .column-count-mv-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 991px) {
  .rm-2,
  .m-dt-2 {
    margin: 2rem;
  }
  .rml-2,
  .ml-dt-2 {
    margin-left: 2rem;
  }
  .rmr-2,
  .mr-dt-2 {
    margin-right: 2rem;
  }
  .rmt-2,
  .mt-dt-2 {
    margin-top: 2rem;
  }
  .rmb-2,
  .mb-dt-2 {
    margin-bottom: 2rem;
  }
  .rmv-2,
  .mv-dt-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .rms-2,
  .ms-dt-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .rp-2,
  .p-dt-2 {
    padding: 2rem;
  }
  .rpl-2,
  .pl-dt-2 {
    padding-left: 2rem;
  }
  .rpr-2,
  .pr-dt-2 {
    padding-right: 2rem;
  }
  .rpt-2,
  .pt-dt-2 {
    padding-top: 2rem;
  }
  .rpb-2,
  .pb-dt-2 {
    padding-bottom: 2rem;
  }
  .rpv-2,
  .pv-dt-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .rps-2,
  .ps-dt-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .rcolumn-count-2,
  .column-count-dt-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-2 {
    margin: 2rem;
  }
  .mt-ls-2 {
    margin-top: 2rem;
  }
  .mb-ls-2 {
    margin-bottom: 2rem;
  }
  .ml-ls-2 {
    margin-left: 2rem;
  }
  .mr-ls-2 {
    margin-right: 2rem;
  }
  .ms-ls-2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .mv-ls-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .pt-ls-2 {
    padding-top: 2rem;
  }
  .pb-ls-2 {
    padding-bottom: 2rem;
  }
  .pl-ls-2 {
    padding-left: 2rem;
  }
  .pr-ls-2 {
    padding-right: 2rem;
  }
  .ps-ls-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pv-ls-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p-ls-2 {
    padding: 2rem;
  }
  .row.gap-ls-2 {
    gap: 2rem;
  }
  .column-count-ls-2 {
    column-count: 2;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-3 {
    margin: 3rem;
  }
  .mt-mh-3 {
    margin-top: 3rem;
  }
  .mb-mh-3 {
    margin-bottom: 3rem;
  }
  .ml-mh-3 {
    margin-left: 3rem;
  }
  .mr-mh-3 {
    margin-right: 3rem;
  }
  .ms-mh-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mv-mh-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pt-mh-3 {
    padding-top: 3rem;
  }
  .pb-mh-3 {
    padding-bottom: 3rem;
  }
  .pl-mh-3 {
    padding-left: 3rem;
  }
  .pr-mh-3 {
    padding-right: 3rem;
  }
  .ps-mh-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pv-mh-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .p-mh-3 {
    padding: 3rem;
  }
  .row.gap-mh-3 {
    gap: 3rem;
  }
  .column-count-mh-3 {
    column-count: 3;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-3 {
    margin: 3rem;
  }
  .mt-mv-3 {
    margin-top: 3rem;
  }
  .mb-mv-3 {
    margin-bottom: 3rem;
  }
  .ml-mv-3 {
    margin-left: 3rem;
  }
  .mr-mv-3 {
    margin-right: 3rem;
  }
  .ms-mv-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mv-mv-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pt-mv-3 {
    padding-top: 3rem;
  }
  .pb-mv-3 {
    padding-bottom: 3rem;
  }
  .pl-mv-3 {
    padding-left: 3rem;
  }
  .pr-mv-3 {
    padding-right: 3rem;
  }
  .ps-mv-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pv-mv-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .p-mv-3 {
    padding: 3rem;
  }
  .row.gap-mv-3 {
    gap: 3rem;
  }
  .column-count-mv-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 991px) {
  .rm-3,
  .m-dt-3 {
    margin: 3rem;
  }
  .rml-3,
  .ml-dt-3 {
    margin-left: 3rem;
  }
  .rmr-3,
  .mr-dt-3 {
    margin-right: 3rem;
  }
  .rmt-3,
  .mt-dt-3 {
    margin-top: 3rem;
  }
  .rmb-3,
  .mb-dt-3 {
    margin-bottom: 3rem;
  }
  .rmv-3,
  .mv-dt-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .rms-3,
  .ms-dt-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .rp-3,
  .p-dt-3 {
    padding: 3rem;
  }
  .rpl-3,
  .pl-dt-3 {
    padding-left: 3rem;
  }
  .rpr-3,
  .pr-dt-3 {
    padding-right: 3rem;
  }
  .rpt-3,
  .pt-dt-3 {
    padding-top: 3rem;
  }
  .rpb-3,
  .pb-dt-3 {
    padding-bottom: 3rem;
  }
  .rpv-3,
  .pv-dt-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .rps-3,
  .ps-dt-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .rcolumn-count-3,
  .column-count-dt-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-3 {
    margin: 3rem;
  }
  .mt-ls-3 {
    margin-top: 3rem;
  }
  .mb-ls-3 {
    margin-bottom: 3rem;
  }
  .ml-ls-3 {
    margin-left: 3rem;
  }
  .mr-ls-3 {
    margin-right: 3rem;
  }
  .ms-ls-3 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .mv-ls-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .pt-ls-3 {
    padding-top: 3rem;
  }
  .pb-ls-3 {
    padding-bottom: 3rem;
  }
  .pl-ls-3 {
    padding-left: 3rem;
  }
  .pr-ls-3 {
    padding-right: 3rem;
  }
  .ps-ls-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pv-ls-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .p-ls-3 {
    padding: 3rem;
  }
  .row.gap-ls-3 {
    gap: 3rem;
  }
  .column-count-ls-3 {
    column-count: 3;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-4 {
    margin: 4rem;
  }
  .mt-mh-4 {
    margin-top: 4rem;
  }
  .mb-mh-4 {
    margin-bottom: 4rem;
  }
  .ml-mh-4 {
    margin-left: 4rem;
  }
  .mr-mh-4 {
    margin-right: 4rem;
  }
  .ms-mh-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv-mh-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pt-mh-4 {
    padding-top: 4rem;
  }
  .pb-mh-4 {
    padding-bottom: 4rem;
  }
  .pl-mh-4 {
    padding-left: 4rem;
  }
  .pr-mh-4 {
    padding-right: 4rem;
  }
  .ps-mh-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv-mh-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .p-mh-4 {
    padding: 4rem;
  }
  .row.gap-mh-4 {
    gap: 4rem;
  }
  .column-count-mh-4 {
    column-count: 4;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-4 {
    margin: 4rem;
  }
  .mt-mv-4 {
    margin-top: 4rem;
  }
  .mb-mv-4 {
    margin-bottom: 4rem;
  }
  .ml-mv-4 {
    margin-left: 4rem;
  }
  .mr-mv-4 {
    margin-right: 4rem;
  }
  .ms-mv-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv-mv-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pt-mv-4 {
    padding-top: 4rem;
  }
  .pb-mv-4 {
    padding-bottom: 4rem;
  }
  .pl-mv-4 {
    padding-left: 4rem;
  }
  .pr-mv-4 {
    padding-right: 4rem;
  }
  .ps-mv-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv-mv-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .p-mv-4 {
    padding: 4rem;
  }
  .row.gap-mv-4 {
    gap: 4rem;
  }
  .column-count-mv-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 991px) {
  .rm-4,
  .m-dt-4 {
    margin: 4rem;
  }
  .rml-4,
  .ml-dt-4 {
    margin-left: 4rem;
  }
  .rmr-4,
  .mr-dt-4 {
    margin-right: 4rem;
  }
  .rmt-4,
  .mt-dt-4 {
    margin-top: 4rem;
  }
  .rmb-4,
  .mb-dt-4 {
    margin-bottom: 4rem;
  }
  .rmv-4,
  .mv-dt-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .rms-4,
  .ms-dt-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .rp-4,
  .p-dt-4 {
    padding: 4rem;
  }
  .rpl-4,
  .pl-dt-4 {
    padding-left: 4rem;
  }
  .rpr-4,
  .pr-dt-4 {
    padding-right: 4rem;
  }
  .rpt-4,
  .pt-dt-4 {
    padding-top: 4rem;
  }
  .rpb-4,
  .pb-dt-4 {
    padding-bottom: 4rem;
  }
  .rpv-4,
  .pv-dt-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .rps-4,
  .ps-dt-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .rcolumn-count-4,
  .column-count-dt-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-4 {
    margin: 4rem;
  }
  .mt-ls-4 {
    margin-top: 4rem;
  }
  .mb-ls-4 {
    margin-bottom: 4rem;
  }
  .ml-ls-4 {
    margin-left: 4rem;
  }
  .mr-ls-4 {
    margin-right: 4rem;
  }
  .ms-ls-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .mv-ls-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .pt-ls-4 {
    padding-top: 4rem;
  }
  .pb-ls-4 {
    padding-bottom: 4rem;
  }
  .pl-ls-4 {
    padding-left: 4rem;
  }
  .pr-ls-4 {
    padding-right: 4rem;
  }
  .ps-ls-4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .pv-ls-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .p-ls-4 {
    padding: 4rem;
  }
  .row.gap-ls-4 {
    gap: 4rem;
  }
  .column-count-ls-4 {
    column-count: 4;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-5 {
    margin: 5rem;
  }
  .mt-mh-5 {
    margin-top: 5rem;
  }
  .mb-mh-5 {
    margin-bottom: 5rem;
  }
  .ml-mh-5 {
    margin-left: 5rem;
  }
  .mr-mh-5 {
    margin-right: 5rem;
  }
  .ms-mh-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mv-mh-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pt-mh-5 {
    padding-top: 5rem;
  }
  .pb-mh-5 {
    padding-bottom: 5rem;
  }
  .pl-mh-5 {
    padding-left: 5rem;
  }
  .pr-mh-5 {
    padding-right: 5rem;
  }
  .ps-mh-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .pv-mh-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .p-mh-5 {
    padding: 5rem;
  }
  .row.gap-mh-5 {
    gap: 5rem;
  }
  .column-count-mh-5 {
    column-count: 5;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-5 {
    margin: 5rem;
  }
  .mt-mv-5 {
    margin-top: 5rem;
  }
  .mb-mv-5 {
    margin-bottom: 5rem;
  }
  .ml-mv-5 {
    margin-left: 5rem;
  }
  .mr-mv-5 {
    margin-right: 5rem;
  }
  .ms-mv-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mv-mv-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pt-mv-5 {
    padding-top: 5rem;
  }
  .pb-mv-5 {
    padding-bottom: 5rem;
  }
  .pl-mv-5 {
    padding-left: 5rem;
  }
  .pr-mv-5 {
    padding-right: 5rem;
  }
  .ps-mv-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .pv-mv-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .p-mv-5 {
    padding: 5rem;
  }
  .row.gap-mv-5 {
    gap: 5rem;
  }
  .column-count-mv-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 991px) {
  .rm-5,
  .m-dt-5 {
    margin: 5rem;
  }
  .rml-5,
  .ml-dt-5 {
    margin-left: 5rem;
  }
  .rmr-5,
  .mr-dt-5 {
    margin-right: 5rem;
  }
  .rmt-5,
  .mt-dt-5 {
    margin-top: 5rem;
  }
  .rmb-5,
  .mb-dt-5 {
    margin-bottom: 5rem;
  }
  .rmv-5,
  .mv-dt-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .rms-5,
  .ms-dt-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .rp-5,
  .p-dt-5 {
    padding: 5rem;
  }
  .rpl-5,
  .pl-dt-5 {
    padding-left: 5rem;
  }
  .rpr-5,
  .pr-dt-5 {
    padding-right: 5rem;
  }
  .rpt-5,
  .pt-dt-5 {
    padding-top: 5rem;
  }
  .rpb-5,
  .pb-dt-5 {
    padding-bottom: 5rem;
  }
  .rpv-5,
  .pv-dt-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .rps-5,
  .ps-dt-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .rcolumn-count-5,
  .column-count-dt-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-5 {
    margin: 5rem;
  }
  .mt-ls-5 {
    margin-top: 5rem;
  }
  .mb-ls-5 {
    margin-bottom: 5rem;
  }
  .ml-ls-5 {
    margin-left: 5rem;
  }
  .mr-ls-5 {
    margin-right: 5rem;
  }
  .ms-ls-5 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .mv-ls-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .pt-ls-5 {
    padding-top: 5rem;
  }
  .pb-ls-5 {
    padding-bottom: 5rem;
  }
  .pl-ls-5 {
    padding-left: 5rem;
  }
  .pr-ls-5 {
    padding-right: 5rem;
  }
  .ps-ls-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .pv-ls-5 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .p-ls-5 {
    padding: 5rem;
  }
  .row.gap-ls-5 {
    gap: 5rem;
  }
  .column-count-ls-5 {
    column-count: 5;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-6 {
    margin: 6rem;
  }
  .mt-mh-6 {
    margin-top: 6rem;
  }
  .mb-mh-6 {
    margin-bottom: 6rem;
  }
  .ml-mh-6 {
    margin-left: 6rem;
  }
  .mr-mh-6 {
    margin-right: 6rem;
  }
  .ms-mh-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .mv-mh-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pt-mh-6 {
    padding-top: 6rem;
  }
  .pb-mh-6 {
    padding-bottom: 6rem;
  }
  .pl-mh-6 {
    padding-left: 6rem;
  }
  .pr-mh-6 {
    padding-right: 6rem;
  }
  .ps-mh-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .pv-mh-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .p-mh-6 {
    padding: 6rem;
  }
  .row.gap-mh-6 {
    gap: 6rem;
  }
  .column-count-mh-6 {
    column-count: 6;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-6 {
    margin: 6rem;
  }
  .mt-mv-6 {
    margin-top: 6rem;
  }
  .mb-mv-6 {
    margin-bottom: 6rem;
  }
  .ml-mv-6 {
    margin-left: 6rem;
  }
  .mr-mv-6 {
    margin-right: 6rem;
  }
  .ms-mv-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .mv-mv-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pt-mv-6 {
    padding-top: 6rem;
  }
  .pb-mv-6 {
    padding-bottom: 6rem;
  }
  .pl-mv-6 {
    padding-left: 6rem;
  }
  .pr-mv-6 {
    padding-right: 6rem;
  }
  .ps-mv-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .pv-mv-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .p-mv-6 {
    padding: 6rem;
  }
  .row.gap-mv-6 {
    gap: 6rem;
  }
  .column-count-mv-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 991px) {
  .rm-6,
  .m-dt-6 {
    margin: 6rem;
  }
  .rml-6,
  .ml-dt-6 {
    margin-left: 6rem;
  }
  .rmr-6,
  .mr-dt-6 {
    margin-right: 6rem;
  }
  .rmt-6,
  .mt-dt-6 {
    margin-top: 6rem;
  }
  .rmb-6,
  .mb-dt-6 {
    margin-bottom: 6rem;
  }
  .rmv-6,
  .mv-dt-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .rms-6,
  .ms-dt-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .rp-6,
  .p-dt-6 {
    padding: 6rem;
  }
  .rpl-6,
  .pl-dt-6 {
    padding-left: 6rem;
  }
  .rpr-6,
  .pr-dt-6 {
    padding-right: 6rem;
  }
  .rpt-6,
  .pt-dt-6 {
    padding-top: 6rem;
  }
  .rpb-6,
  .pb-dt-6 {
    padding-bottom: 6rem;
  }
  .rpv-6,
  .pv-dt-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .rps-6,
  .ps-dt-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .rcolumn-count-6,
  .column-count-dt-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-6 {
    margin: 6rem;
  }
  .mt-ls-6 {
    margin-top: 6rem;
  }
  .mb-ls-6 {
    margin-bottom: 6rem;
  }
  .ml-ls-6 {
    margin-left: 6rem;
  }
  .mr-ls-6 {
    margin-right: 6rem;
  }
  .ms-ls-6 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .mv-ls-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .pt-ls-6 {
    padding-top: 6rem;
  }
  .pb-ls-6 {
    padding-bottom: 6rem;
  }
  .pl-ls-6 {
    padding-left: 6rem;
  }
  .pr-ls-6 {
    padding-right: 6rem;
  }
  .ps-ls-6 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .pv-ls-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .p-ls-6 {
    padding: 6rem;
  }
  .row.gap-ls-6 {
    gap: 6rem;
  }
  .column-count-ls-6 {
    column-count: 6;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-7 {
    margin: 7rem;
  }
  .mt-mh-7 {
    margin-top: 7rem;
  }
  .mb-mh-7 {
    margin-bottom: 7rem;
  }
  .ml-mh-7 {
    margin-left: 7rem;
  }
  .mr-mh-7 {
    margin-right: 7rem;
  }
  .ms-mh-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .mv-mh-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pt-mh-7 {
    padding-top: 7rem;
  }
  .pb-mh-7 {
    padding-bottom: 7rem;
  }
  .pl-mh-7 {
    padding-left: 7rem;
  }
  .pr-mh-7 {
    padding-right: 7rem;
  }
  .ps-mh-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .pv-mh-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-mh-7 {
    padding: 7rem;
  }
  .row.gap-mh-7 {
    gap: 7rem;
  }
  .column-count-mh-7 {
    column-count: 7;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-7 {
    margin: 7rem;
  }
  .mt-mv-7 {
    margin-top: 7rem;
  }
  .mb-mv-7 {
    margin-bottom: 7rem;
  }
  .ml-mv-7 {
    margin-left: 7rem;
  }
  .mr-mv-7 {
    margin-right: 7rem;
  }
  .ms-mv-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .mv-mv-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pt-mv-7 {
    padding-top: 7rem;
  }
  .pb-mv-7 {
    padding-bottom: 7rem;
  }
  .pl-mv-7 {
    padding-left: 7rem;
  }
  .pr-mv-7 {
    padding-right: 7rem;
  }
  .ps-mv-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .pv-mv-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-mv-7 {
    padding: 7rem;
  }
  .row.gap-mv-7 {
    gap: 7rem;
  }
  .column-count-mv-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 991px) {
  .rm-7,
  .m-dt-7 {
    margin: 7rem;
  }
  .rml-7,
  .ml-dt-7 {
    margin-left: 7rem;
  }
  .rmr-7,
  .mr-dt-7 {
    margin-right: 7rem;
  }
  .rmt-7,
  .mt-dt-7 {
    margin-top: 7rem;
  }
  .rmb-7,
  .mb-dt-7 {
    margin-bottom: 7rem;
  }
  .rmv-7,
  .mv-dt-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .rms-7,
  .ms-dt-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .rp-7,
  .p-dt-7 {
    padding: 7rem;
  }
  .rpl-7,
  .pl-dt-7 {
    padding-left: 7rem;
  }
  .rpr-7,
  .pr-dt-7 {
    padding-right: 7rem;
  }
  .rpt-7,
  .pt-dt-7 {
    padding-top: 7rem;
  }
  .rpb-7,
  .pb-dt-7 {
    padding-bottom: 7rem;
  }
  .rpv-7,
  .pv-dt-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .rps-7,
  .ps-dt-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .rcolumn-count-7,
  .column-count-dt-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-7 {
    margin: 7rem;
  }
  .mt-ls-7 {
    margin-top: 7rem;
  }
  .mb-ls-7 {
    margin-bottom: 7rem;
  }
  .ml-ls-7 {
    margin-left: 7rem;
  }
  .mr-ls-7 {
    margin-right: 7rem;
  }
  .ms-ls-7 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .mv-ls-7 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .pt-ls-7 {
    padding-top: 7rem;
  }
  .pb-ls-7 {
    padding-bottom: 7rem;
  }
  .pl-ls-7 {
    padding-left: 7rem;
  }
  .pr-ls-7 {
    padding-right: 7rem;
  }
  .ps-ls-7 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .pv-ls-7 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .p-ls-7 {
    padding: 7rem;
  }
  .row.gap-ls-7 {
    gap: 7rem;
  }
  .column-count-ls-7 {
    column-count: 7;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-8 {
    margin: 8rem;
  }
  .mt-mh-8 {
    margin-top: 8rem;
  }
  .mb-mh-8 {
    margin-bottom: 8rem;
  }
  .ml-mh-8 {
    margin-left: 8rem;
  }
  .mr-mh-8 {
    margin-right: 8rem;
  }
  .ms-mh-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mv-mh-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pt-mh-8 {
    padding-top: 8rem;
  }
  .pb-mh-8 {
    padding-bottom: 8rem;
  }
  .pl-mh-8 {
    padding-left: 8rem;
  }
  .pr-mh-8 {
    padding-right: 8rem;
  }
  .ps-mh-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pv-mh-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .p-mh-8 {
    padding: 8rem;
  }
  .row.gap-mh-8 {
    gap: 8rem;
  }
  .column-count-mh-8 {
    column-count: 8;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-8 {
    margin: 8rem;
  }
  .mt-mv-8 {
    margin-top: 8rem;
  }
  .mb-mv-8 {
    margin-bottom: 8rem;
  }
  .ml-mv-8 {
    margin-left: 8rem;
  }
  .mr-mv-8 {
    margin-right: 8rem;
  }
  .ms-mv-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mv-mv-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pt-mv-8 {
    padding-top: 8rem;
  }
  .pb-mv-8 {
    padding-bottom: 8rem;
  }
  .pl-mv-8 {
    padding-left: 8rem;
  }
  .pr-mv-8 {
    padding-right: 8rem;
  }
  .ps-mv-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pv-mv-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .p-mv-8 {
    padding: 8rem;
  }
  .row.gap-mv-8 {
    gap: 8rem;
  }
  .column-count-mv-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 991px) {
  .rm-8,
  .m-dt-8 {
    margin: 8rem;
  }
  .rml-8,
  .ml-dt-8 {
    margin-left: 8rem;
  }
  .rmr-8,
  .mr-dt-8 {
    margin-right: 8rem;
  }
  .rmt-8,
  .mt-dt-8 {
    margin-top: 8rem;
  }
  .rmb-8,
  .mb-dt-8 {
    margin-bottom: 8rem;
  }
  .rmv-8,
  .mv-dt-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .rms-8,
  .ms-dt-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .rp-8,
  .p-dt-8 {
    padding: 8rem;
  }
  .rpl-8,
  .pl-dt-8 {
    padding-left: 8rem;
  }
  .rpr-8,
  .pr-dt-8 {
    padding-right: 8rem;
  }
  .rpt-8,
  .pt-dt-8 {
    padding-top: 8rem;
  }
  .rpb-8,
  .pb-dt-8 {
    padding-bottom: 8rem;
  }
  .rpv-8,
  .pv-dt-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .rps-8,
  .ps-dt-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .rcolumn-count-8,
  .column-count-dt-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-8 {
    margin: 8rem;
  }
  .mt-ls-8 {
    margin-top: 8rem;
  }
  .mb-ls-8 {
    margin-bottom: 8rem;
  }
  .ml-ls-8 {
    margin-left: 8rem;
  }
  .mr-ls-8 {
    margin-right: 8rem;
  }
  .ms-ls-8 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .mv-ls-8 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .pt-ls-8 {
    padding-top: 8rem;
  }
  .pb-ls-8 {
    padding-bottom: 8rem;
  }
  .pl-ls-8 {
    padding-left: 8rem;
  }
  .pr-ls-8 {
    padding-right: 8rem;
  }
  .ps-ls-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .pv-ls-8 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .p-ls-8 {
    padding: 8rem;
  }
  .row.gap-ls-8 {
    gap: 8rem;
  }
  .column-count-ls-8 {
    column-count: 8;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-9 {
    margin: 9rem;
  }
  .mt-mh-9 {
    margin-top: 9rem;
  }
  .mb-mh-9 {
    margin-bottom: 9rem;
  }
  .ml-mh-9 {
    margin-left: 9rem;
  }
  .mr-mh-9 {
    margin-right: 9rem;
  }
  .ms-mh-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .mv-mh-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pt-mh-9 {
    padding-top: 9rem;
  }
  .pb-mh-9 {
    padding-bottom: 9rem;
  }
  .pl-mh-9 {
    padding-left: 9rem;
  }
  .pr-mh-9 {
    padding-right: 9rem;
  }
  .ps-mh-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .pv-mh-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .p-mh-9 {
    padding: 9rem;
  }
  .row.gap-mh-9 {
    gap: 9rem;
  }
  .column-count-mh-9 {
    column-count: 9;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-9 {
    margin: 9rem;
  }
  .mt-mv-9 {
    margin-top: 9rem;
  }
  .mb-mv-9 {
    margin-bottom: 9rem;
  }
  .ml-mv-9 {
    margin-left: 9rem;
  }
  .mr-mv-9 {
    margin-right: 9rem;
  }
  .ms-mv-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .mv-mv-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pt-mv-9 {
    padding-top: 9rem;
  }
  .pb-mv-9 {
    padding-bottom: 9rem;
  }
  .pl-mv-9 {
    padding-left: 9rem;
  }
  .pr-mv-9 {
    padding-right: 9rem;
  }
  .ps-mv-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .pv-mv-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .p-mv-9 {
    padding: 9rem;
  }
  .row.gap-mv-9 {
    gap: 9rem;
  }
  .column-count-mv-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 991px) {
  .rm-9,
  .m-dt-9 {
    margin: 9rem;
  }
  .rml-9,
  .ml-dt-9 {
    margin-left: 9rem;
  }
  .rmr-9,
  .mr-dt-9 {
    margin-right: 9rem;
  }
  .rmt-9,
  .mt-dt-9 {
    margin-top: 9rem;
  }
  .rmb-9,
  .mb-dt-9 {
    margin-bottom: 9rem;
  }
  .rmv-9,
  .mv-dt-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .rms-9,
  .ms-dt-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .rp-9,
  .p-dt-9 {
    padding: 9rem;
  }
  .rpl-9,
  .pl-dt-9 {
    padding-left: 9rem;
  }
  .rpr-9,
  .pr-dt-9 {
    padding-right: 9rem;
  }
  .rpt-9,
  .pt-dt-9 {
    padding-top: 9rem;
  }
  .rpb-9,
  .pb-dt-9 {
    padding-bottom: 9rem;
  }
  .rpv-9,
  .pv-dt-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .rps-9,
  .ps-dt-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .rcolumn-count-9,
  .column-count-dt-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-9 {
    margin: 9rem;
  }
  .mt-ls-9 {
    margin-top: 9rem;
  }
  .mb-ls-9 {
    margin-bottom: 9rem;
  }
  .ml-ls-9 {
    margin-left: 9rem;
  }
  .mr-ls-9 {
    margin-right: 9rem;
  }
  .ms-ls-9 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .mv-ls-9 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .pt-ls-9 {
    padding-top: 9rem;
  }
  .pb-ls-9 {
    padding-bottom: 9rem;
  }
  .pl-ls-9 {
    padding-left: 9rem;
  }
  .pr-ls-9 {
    padding-right: 9rem;
  }
  .ps-ls-9 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .pv-ls-9 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .p-ls-9 {
    padding: 9rem;
  }
  .row.gap-ls-9 {
    gap: 9rem;
  }
  .column-count-ls-9 {
    column-count: 9;
  }
}
@media screen and (min-width: 480px) and (max-width: 990px) {
  .m-mh-10 {
    margin: 10rem;
  }
  .mt-mh-10 {
    margin-top: 10rem;
  }
  .mb-mh-10 {
    margin-bottom: 10rem;
  }
  .ml-mh-10 {
    margin-left: 10rem;
  }
  .mr-mh-10 {
    margin-right: 10rem;
  }
  .ms-mh-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mv-mh-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pt-mh-10 {
    padding-top: 10rem;
  }
  .pb-mh-10 {
    padding-bottom: 10rem;
  }
  .pl-mh-10 {
    padding-left: 10rem;
  }
  .pr-mh-10 {
    padding-right: 10rem;
  }
  .ps-mh-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .pv-mh-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .p-mh-10 {
    padding: 10rem;
  }
  .row.gap-mh-10 {
    gap: 10rem;
  }
  .column-count-mh-10 {
    column-count: 10;
  }
}
@media screen and (max-width: 479px) {
  .m-mv-10 {
    margin: 10rem;
  }
  .mt-mv-10 {
    margin-top: 10rem;
  }
  .mb-mv-10 {
    margin-bottom: 10rem;
  }
  .ml-mv-10 {
    margin-left: 10rem;
  }
  .mr-mv-10 {
    margin-right: 10rem;
  }
  .ms-mv-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mv-mv-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pt-mv-10 {
    padding-top: 10rem;
  }
  .pb-mv-10 {
    padding-bottom: 10rem;
  }
  .pl-mv-10 {
    padding-left: 10rem;
  }
  .pr-mv-10 {
    padding-right: 10rem;
  }
  .ps-mv-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .pv-mv-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .p-mv-10 {
    padding: 10rem;
  }
  .row.gap-mv-10 {
    gap: 10rem;
  }
  .column-count-mv-10 {
    column-count: 10;
  }
}
@media screen and (min-width: 991px) {
  .rm-10,
  .m-dt-10 {
    margin: 10rem;
  }
  .rml-10,
  .ml-dt-10 {
    margin-left: 10rem;
  }
  .rmr-10,
  .mr-dt-10 {
    margin-right: 10rem;
  }
  .rmt-10,
  .mt-dt-10 {
    margin-top: 10rem;
  }
  .rmb-10,
  .mb-dt-10 {
    margin-bottom: 10rem;
  }
  .rmv-10,
  .mv-dt-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .rms-10,
  .ms-dt-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .rp-10,
  .p-dt-10 {
    padding: 10rem;
  }
  .rpl-10,
  .pl-dt-10 {
    padding-left: 10rem;
  }
  .rpr-10,
  .pr-dt-10 {
    padding-right: 10rem;
  }
  .rpt-10,
  .pt-dt-10 {
    padding-top: 10rem;
  }
  .rpb-10,
  .pb-dt-10 {
    padding-bottom: 10rem;
  }
  .rpv-10,
  .pv-dt-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .rps-10,
  .ps-dt-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .rcolumn-count-10,
  .column-count-dt-10 {
    column-count: 10;
  }
}
@media screen and (min-width: 2430px) {
  .m-ls-10 {
    margin: 10rem;
  }
  .mt-ls-10 {
    margin-top: 10rem;
  }
  .mb-ls-10 {
    margin-bottom: 10rem;
  }
  .ml-ls-10 {
    margin-left: 10rem;
  }
  .mr-ls-10 {
    margin-right: 10rem;
  }
  .ms-ls-10 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .mv-ls-10 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .pt-ls-10 {
    padding-top: 10rem;
  }
  .pb-ls-10 {
    padding-bottom: 10rem;
  }
  .pl-ls-10 {
    padding-left: 10rem;
  }
  .pr-ls-10 {
    padding-right: 10rem;
  }
  .ps-ls-10 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .pv-ls-10 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .p-ls-10 {
    padding: 10rem;
  }
  .row.gap-ls-10 {
    gap: 10rem;
  }
  .column-count-ls-10 {
    column-count: 10;
  }
}
.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}

body {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body main {
  flex: 1 1 auto;
}

body.menu-opened main {
  display: none;
}

.header-wrapper {
  position: relative;
  border-top: 9px solid #7eb2a3;
  z-index: 5;
}

.header-wrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 14.6111111111rem;
  top: 0;
  opacity: 0.84;
  background: linear-gradient(to bottom, #2e2958, rgba(0, 0, 0, 0));
  background-repeat: no-repeat;
}

.header-wrapper a {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.header-wrapper-top {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding-top: 1rem;
}

@media screen and (max-width: 1249px) {
  .header-wrapper-top {
    flex-wrap: wrap;
  }
}
.header-wrapper-top-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

@media screen and (max-width: 1249px) {
  .header-wrapper-top-container {
    flex-basis: 90%;
    padding: 0.5rem 0;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .header-wrapper-top-container {
    flex-basis: 84%;
    column-gap: 0.5rem;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 479px) {
  .header-wrapper-top-container {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header-wrapper-top .btn {
    font-size: 0.7777777778rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}
@media screen and (max-width: 479px) {
  .header-wrapper-top .btn {
    margin-top: 0.5rem;
  }
}
.header-wrapper-top::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.36);
  width: 5rem;
}

@media screen and (max-width: 1599px) {
  .header-wrapper-top::before {
    width: 1.3888888889rem;
  }
}
@media screen and (max-width: 1249px) {
  .header-wrapper-top::before {
    width: 0;
  }
}
.header-wrapper-top::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.36);
  width: calc(100% - 393px);
}

@media screen and (max-width: 1599px) {
  .header-wrapper-top::after {
    width: calc(100% - 320px);
  }
}
@media screen and (max-width: 1249px) {
  .header-wrapper-top::after {
    width: 100%;
  }
}
.header-wrapper-bottom {
  position: relative;
  z-index: 10;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.header-wrapper-social {
  display: flex;
}

@media screen and (max-width: 479px) {
  .header-wrapper-social {
    display: none;
  }
}
.header-wrapper-social a {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #fff;
  font-size: 0.7777777778rem;
}

@media screen and (max-width: 384px) {
  .header-wrapper-social a {
    padding-right: 0.3rem;
    padding-left: 0.3rem;
  }
}
.header-wrapper-phone {
  color: #fff;
  font-size: 0.7777777778rem;
  font-weight: 300;
  text-align: right;
  margin-right: 1rem;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 767px) {
  .header-wrapper-phone {
    margin-right: 0;
  }
}
.header-wrapper-phone i {
  font-size: 1.4444444444rem;
  color: #7eb2a3;
}

@media screen and (max-width: 767px) {
  .header-wrapper-phone i {
    font-size: 1.6666666667rem;
  }
}
.header-wrapper-phone span {
  font-size: 1.4444444444rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 1399px) {
  .header-wrapper-phone span {
    display: none;
  }
}
@media screen and (max-width: 1249px) {
  .header-wrapper-phone span {
    display: inline;
    font-size: 1.2777777778rem;
  }
}
@media screen and (max-width: 767px) {
  .header-wrapper-phone span {
    display: none;
  }
}
.header-wrapper-phone p {
  margin-bottom: 0;
}

@media screen and (max-width: 1399px) {
  .header-wrapper-phone p {
    display: none;
  }
}
@media screen and (max-width: 1249px) {
  .header-wrapper-phone p {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .header-wrapper-phone p {
    display: none;
  }
}
.header-wrapper-logo {
  padding-left: 6rem;
}

@media screen and (max-width: 1599px) {
  .header-wrapper-logo {
    padding-left: 2rem;
  }
}
@media screen and (max-width: 1249px) {
  .header-wrapper-logo {
    padding-left: 0;
  }
}
.header-wrapper-logo a {
  display: block;
}

.header-wrapper-logo a img {
  height: 3.7777777778rem;
}

@media screen and (max-width: 479px) {
  .header-wrapper-logo a img {
    height: 2.5rem;
  }
}
.header-wrapper nav {
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 1.0555555556rem;
}

@media screen and (max-width: 1599px) {
  .header-wrapper nav {
    font-size: 0.8888888889rem;
  }
}
@media screen and (max-width: 1399px) {
  .header-wrapper nav {
    font-size: 0.8333333333rem;
  }
}
.header-wrapper nav ul {
  display: flex;
  justify-content: center;
  margin: 0;
}

@media screen and (min-width: 1919px) {
  .header-wrapper nav ul {
    justify-content: space-between;
  }
}
.header-wrapper nav ul a {
  margin: 0.5rem;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-bottom: 3px solid transparent;
  border-radius: 1px;
}

.header-wrapper nav ul a:hover, .header-wrapper nav ul a.active {
  color: #d0685c;
  border-color: #e1f4ee;
}

@media screen and (max-width: 1399px) {
  .header-wrapper nav ul a {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}
@media screen and (max-width: 1249px) {
  .header-wrapper nav {
    flex: 1 1 100%;
    display: none;
    margin-left: calc(-15px - 1%);
    margin-right: calc(-15px - 1%);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 20;
    color: #3c3c3c;
  }
  .header-wrapper nav ul {
    min-height: calc(100vh - 141px);
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}
@media screen and (max-width: 1249px) and (max-width: 767px) {
  .header-wrapper nav ul {
    min-height: calc(100vh - 155px);
  }
}
@media screen and (max-width: 1249px) {
  .header-wrapper nav ul a {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
}
.header-wrapper-mobile {
  display: none;
  column-gap: 0.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.header-wrapper-mobile .btn {
  font-size: 0.6666666667rem;
}

@media screen and (max-width: 479px) {
  .header-wrapper-mobile {
    display: flex;
  }
}
.header-wrapper-hamburger {
  backface-visibility: hidden;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  margin-left: auto;
}

.header-wrapper-hamburger:focus {
  outline: none;
}

@media screen and (min-width: 1250px) {
  .header-wrapper-hamburger {
    display: none;
  }
}
.header-wrapper-hamburger svg {
  height: 52px;
  width: auto;
}

.header-wrapper-hamburger .line {
  fill: none;
  stroke: #e1f4ee;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.header-wrapper-hamburger .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.header-wrapper-hamburger .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

.header-wrapper-hamburger .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.header-wrapper-hamburger.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.header-wrapper-hamburger.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}

.header-wrapper-hamburger.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

footer {
  margin-top: 6rem;
}

@media screen and (max-width: 767px) {
  footer {
    margin-top: 0;
  }
}
footer.no-marg {
  margin-top: 0;
}

.footer-top {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  font-family: "Rubik", sans-serif;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1399px) {
  .footer-top {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.footer-top-date {
  max-width: 285px;
}

.footer-top-date-adress {
  font-size: 1.1111111111rem;
  letter-spacing: 0.01em;
  border-bottom: 3px solid rgba(112, 112, 112, 0.26);
}

.footer-top-date-geo {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  column-gap: 0.5rem;
  font-size: 0.9444444444rem;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1399px) {
  .footer-top-date {
    flex-basis: 45%;
    max-width: none;
  }
}
@media screen and (max-width: 767px) {
  .footer-top-date {
    flex-basis: 100%;
    max-width: 285px;
  }
}
.footer-top-menu {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
}

@media screen and (max-width: 1399px) {
  .footer-top-menu {
    flex-basis: 100%;
    max-width: none;
    order: 1;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 767px) {
  .footer-top-menu {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 384px) {
  .footer-top-menu {
    justify-content: flex-start;
  }
}
.footer-top-menu-main ul li {
  font-size: 0.7777777778rem;
  font-weight: 700;
}

.footer-top-menu-program ul li {
  font-size: 0.6666666667rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.footer-top-menu-donate {
  max-width: 300px;
}

.footer-top-menu .footer-top-date-geo i {
  color: #7f87ac;
}

@media screen and (max-width: 1399px) {
  .footer-top-subscribe {
    flex-basis: 45%;
    max-width: none;
  }
}
@media screen and (max-width: 767px) {
  .footer-top-subscribe {
    flex-basis: 100%;
    order: 2;
  }
}
.footer-top .help-emergency {
  justify-content: center;
}

.footer-bottom {
  display: flex;
}

.footer-bottom-wrapper {
  padding-bottom: 2.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-top-right-radius: 50px;
  margin-right: auto;
  flex-grow: 1;
}

@media screen and (max-width: 990px) {
  .footer-bottom-wrapper {
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}
.footer-bottom .container {
  display: flex;
  justify-content: space-between;
  font-family: "Rubik", sans-serif;
  font-size: 0.8888888889rem;
}

@media screen and (max-width: 1399px) {
  .footer-bottom .container {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 990px) {
  .footer-bottom .container {
    display: block;
    text-align: center;
  }
}
@media screen and (max-width: 479px) {
  .footer-bottom .container {
    padding-right: 4rem;
    font-size: 0.7777777778rem;
  }
}
.footer-bottom-social {
  transform: translateY(-35%);
}

@media screen and (max-width: 1399px) {
  .footer-bottom-social {
    flex-basis: 100%;
    transform: translateY(0);
    margin-top: 1rem;
    text-align: center;
  }
}
.footer-bottom-social a {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 990px) {
  .footer-bottom-social a {
    max-width: 2.2222222222rem;
  }
}
@media screen and (max-width: 479px) {
  .footer-bottom-social a {
    max-width: 1.9444444444rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}
.footer-bottom-copyright, .footer-bottom-terms {
  margin-top: 2.5rem;
}

@media screen and (max-width: 1399px) {
  .footer-bottom-copyright, .footer-bottom-terms {
    margin-top: 1rem;
  }
}
.footer-bottom-copyright ul, .footer-bottom-terms ul {
  display: flex;
  column-gap: 0.5rem;
  margin-bottom: 0;
}

@media screen and (max-width: 990px) {
  .footer-bottom-copyright ul, .footer-bottom-terms ul {
    justify-content: center;
  }
}
@media screen and (max-width: 479px) {
  .footer-bottom-copyright ul, .footer-bottom-terms ul {
    column-gap: 0.2rem;
  }
}
.footer-bottom-copyright ul li::after, .footer-bottom-terms ul li::after {
  content: "|";
  margin-left: 0.5rem;
}

@media screen and (max-width: 479px) {
  .footer-bottom-copyright ul li::after, .footer-bottom-terms ul li::after {
    margin-left: 0.2rem;
  }
}
.footer-bottom-copyright ul li:last-child::after, .footer-bottom-terms ul li:last-child::after {
  content: none;
}

.footer-bottom-arrow {
  position: relative;
  flex-basis: 7rem;
}

@media screen and (max-width: 767px) {
  .footer-bottom-arrow {
    flex-basis: 4rem;
  }
}
@media screen and (max-width: 479px) {
  .footer-bottom-arrow {
    flex-basis: 0;
  }
}
.footer-bottom-arrow::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 50%;
  background-color: #F9FAF0;
}

.footer-bottom-arrow a.smooth-to-top {
  position: fixed;
  right: 1.3rem;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7eb2a3;
  border-radius: 10px;
  height: 4rem;
  width: 4rem;
  pointer-events: none;
  text-decoration: none;
  transform: translateY(100px);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: 15;
}

@media screen and (max-width: 767px) {
  .footer-bottom-arrow a.smooth-to-top {
    height: 3.3333333333rem;
    width: 3.3333333333rem;
    right: 0.5rem;
  }
}
.footer-bottom-arrow a.smooth-to-top::after {
  content: url(../../images/icons/corner-right-up.svg);
  color: #fff;
}

.footer-bottom-arrow a.smooth-to-top.active {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

form {
  position: relative;
}

form input.has-danger,
form select.has-danger,
form textarea.has-danger {
  border-bottom: 1px solid #f00 !important;
}

form .input-wrapper.has-danger {
  border-color: #f00 !important;
}

form .input-wrapper.has-danger:before, form .input-wrapper.has-danger:after {
  background-color: #f00 !important;
}

form input[type=radio].has-danger + label:before,
form input[type=checkbox].has-danger + label:before {
  border-color: #f00 !important;
}

form ::placeholder {
  color: #505050;
}

form input,
form select,
form textarea {
  font-size: 1rem;
  color: #3c3c3c;
  font-family: inherit;
  padding: 1em 2em;
}

form input,
form textarea,
form select,
form .select-wrapper {
  width: 100%;
  border: 1px solid #505050;
}

form input {
  min-height: 3rem;
}

form .select-wrapper {
  min-height: 3rem;
  position: relative;
}

form .select-wrapper:before {
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5em;
  z-index: 10;
  color: #7f87ac;
  pointer-events: none;
}

form .select-wrapper:after {
  position: absolute;
  display: block;
  content: "";
  right: 0;
  top: 0;
  width: 1.6em;
  height: 100%;
  font-size: 2em;
  z-index: 5;
  pointer-events: none;
}

form select {
  min-height: 3rem;
  width: 100%;
  border-radius: 0;
}

form select:disabled {
  color: #505050;
}

form select option [disabled=disabled] {
  color: #505050;
}

form p.question {
  font-size: calc(24px + 0.45vw);
}

@media screen and (min-width: 1919px) {
  form p.question {
    font-size: 33px;
  }
}
@media screen and (max-width: 479px) {
  form p.question {
    font-size: 24px;
  }
}
form p.c-info {
  letter-spacing: 0.1em;
  font-size: calc(16px + 0.4vw);
}

@media screen and (min-width: 1919px) {
  form p.c-info {
    font-size: 24px;
  }
}
@media screen and (max-width: 479px) {
  form p.c-info {
    font-size: 16px;
  }
}
form label {
  text-align: left;
  display: block;
  padding-bottom: 0.2em;
}

form label.file_upload {
  display: inline-flex;
  align-items: flex-start;
}

form label.file_upload input[type=file] {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: none;
}

form label.file_upload mark {
  background-color: transparent;
  color: #fff;
  opacity: 1;
  font-size: 1rem;
  margin-left: 0.5em;
  transition: all 0.3s ease-in-out;
}

form label.file_upload .btn {
  cursor: pointer;
}

form label.file_upload .btn.empty {
  color: #fff;
}

form label.file_upload .btn:not(.empty):hover + mark {
  opacity: 0;
}

form .row {
  padding-top: 0px;
  padding-bottom: 0px;
}

form .row .col.file-upload-container {
  text-align: left;
}

form .row .col.file-upload-container.has-danger {
  border: 1px solid #f00;
}

form .row .col.file-upload-container.has-danger mark {
  color: #f00;
}

@media screen and (max-width: 990px) {
  form .row .col {
    margin-bottom: 15px;
  }
}
form .checkbox input {
  display: none;
}

form .checkbox label {
  position: relative;
  padding-left: 4em;
  line-height: 3em;
  cursor: pointer;
}

form .checkbox label:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 3em;
  height: 3em;
  background-color: #fff;
}

form .checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 0.4em;
  color: #7f87ac;
  font-size: calc(30px + 0.9vw);
}

@media screen and (min-width: 1919px) {
  form .checkbox label:after {
    font-size: 48px;
  }
}
@media screen and (max-width: 479px) {
  form .checkbox label:after {
    font-size: 30px;
  }
}
form .checkbox input:checked + label:after {
  content: "V";
}

form.newsletter-form {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 420px;
  position: relative;
  padding: 3rem 2rem;
  border-top-left-radius: 70px;
  background-color: #e1f4ee;
  box-shadow: 0 0 23px rgba(0, 0, 0, 0.15);
  transform: translateY(-30%);
}

form.newsletter-form::before {
  content: url(../../images/icons/plane.svg);
  position: absolute;
  left: 0.5rem;
  top: 2.5rem;
}

@media screen and (max-width: 767px) {
  form.newsletter-form {
    transform: translateY(0);
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}
form.newsletter-form input {
  position: relative;
  z-index: 5;
  border-radius: 5px;
  border-color: white;
  height: 2.9444444444rem;
}

form.newsletter-form ::placeholder {
  font-size: 0.8888888889rem;
}

form.mailSign-form {
  position: relative;
  padding: 2rem;
  max-width: 530px;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  form.mailSign-form {
    transform: translateY(0);
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}
form.mailSign-form input {
  position: relative;
  z-index: 5;
  border-radius: 5px;
  border-color: white;
  height: 2.9444444444rem;
}

form.mailSign-form ::placeholder {
  font-size: 0.8888888889rem;
}

form.champion-form {
  display: flex;
  column-gap: 1rem;
}

@media screen and (max-width: 767px) {
  form.champion-form {
    flex-direction: column;
  }
}
form.champion-form .champion-form-container {
  display: flex;
  flex-basis: 60%;
}

@media screen and (max-width: 767px) {
  form.champion-form .champion-form-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 479px) {
  form.champion-form .champion-form-container {
    display: block;
  }
}
form.champion-form .champion-form-container .champion-btn {
  font-family: "Jost", sans-serif;
  font-size: 1.5555555556rem;
  font-weight: 700;
  background-color: #7eb2a3;
  color: #fff;
  border: none;
  outline: none;
  flex-basis: 50%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 767px) {
  form.champion-form .champion-form-container .champion-btn {
    font-size: 1.1111111111rem;
  }
}
@media screen and (max-width: 479px) {
  form.champion-form .champion-form-container .champion-btn {
    width: 100%;
    min-height: 3rem;
    border-radius: 20px;
  }
}
form.champion-form ::placeholder {
  font-family: "Jost", sans-serif;
  font-size: 1.5555555556rem;
  font-weight: 700;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 767px) {
  form.champion-form ::placeholder {
    font-size: 1.1111111111rem;
  }
}
form.champion-form select {
  position: relative;
  font-family: "Jost", sans-serif;
  font-size: 1.5555555556rem;
  font-weight: 700;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 1rem;
  border: 1px solid #7eb2a3;
  appearance: none;
  height: 100%;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 767px) {
  form.champion-form select {
    font-size: 1.1111111111rem;
  }
}
@media screen and (max-width: 479px) {
  form.champion-form select {
    margin-bottom: 1rem;
    border-radius: 20px;
  }
}
form.champion-form select:focus {
  outline: none;
}

form.champion-form .select-wrapper {
  border: none;
  min-height: auto;
}

form.champion-form .select-wrapper::after {
  content: "\e913";
  font-family: "icomoon" !important;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-15%);
  pointer-events: none;
  font-size: 1.1111111111rem;
}

@media screen and (max-width: 479px) {
  form.champion-form .select-wrapper::after {
    font-size: 0.8888888889rem;
    right: 1.5rem;
    transform: translateY(-20%);
  }
}
form.champion-form input {
  flex-basis: 40%;
  padding: 1rem;
  border: 1px solid #7eb2a3;
  border-radius: 20px;
  font-family: "Jost", sans-serif;
  font-size: 1.5555555556rem;
  font-weight: 700;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 767px) {
  form.champion-form input {
    font-size: 1.1111111111rem;
  }
}
form.contact-form textarea {
  padding: 1.5rem 3rem 1.5rem 1.5rem;
  border-color: #c6c6c6;
}

form.contact-form textarea:focus {
  border-color: #d0685c;
}

form.contact-form .contact-form-wrapper {
  position: relative;
}

form.contact-form input {
  min-height: 3.8888888889rem;
  margin-bottom: 0;
  padding-right: 2.5rem;
  border-color: #c6c6c6;
}

form.contact-form input:focus {
  border-color: #d0685c;
}

form.contact-form .contact-form-icon {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

form.contact-form .contact-form-area {
  position: absolute;
  right: 2.5rem;
  top: 1.5rem;
}

form.news-form {
  display: flex;
}

form.news-form input {
  border: none;
  padding: 1rem;
}

form.news-form ::placeholder {
  color: rgba(71, 71, 71, 0.3);
}

form.news-form .news-form-btn {
  width: 3.6666666667rem;
  height: 3.3888888889rem;
  background-color: #F15B26;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

form.news-form .news-form-btn::after {
  content: "\e906";
  font-family: "icomoon" !important;
  color: #fff;
}

form.meeting-form {
  display: flex;
  max-width: 46.3888888889rem;
  margin-left: auto;
  margin-right: auto;
}

form.meeting-form input {
  border-color: #7eb2a3;
  padding: 1rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-family: "Jost", sans-serif;
  font-weight: 700;
  font-size: 1.5555555556rem;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 479px) {
  form.meeting-form input {
    font-size: 1.1111111111rem;
    height: 3.3333333333rem;
  }
}
form.meeting-form .meeting-form-btn {
  flex-shrink: 0;
  width: 12.1666666667rem;
  height: 4.6666666667rem;
  background-color: #7eb2a3;
  color: #fff;
  font-family: "Jost", sans-serif;
  font-weight: 700;
  font-size: 1.5555555556rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 767px) {
  form.meeting-form .meeting-form-btn {
    flex-shrink: 1;
  }
}
@media screen and (max-width: 479px) {
  form.meeting-form .meeting-form-btn {
    font-size: 1.1111111111rem;
    height: 3.3333333333rem;
  }
}
.thankyou-url {
  display: none;
}

.thankyou-url.submitted {
  display: block;
  max-height: 0.1px;
  overflow: hidden;
}

.slider-wrapper {
  position: relative !important;
}

.slider-wrapper.init {
  min-height: 200px;
  max-height: 30vh;
}

.slider-wrapper.init .slick-slider {
  opacity: 0;
}

.slider-wrapper .preloader-overlay {
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.slider-wrapper .preloader-overlay .preloader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  width: 6rem;
  height: 6rem;
  margin-top: -3rem;
  margin-left: -3rem;
  background-image: url(../../images/fw-slider/new-loading.gif);
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-wrapper .prev-arrow,
.slider-wrapper .next-arrow {
  position: absolute;
  text-decoration: none;
  transition: 300ms ease-in-out;
  z-index: 20;
}

.slider-wrapper .transp-prev-arrow {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-wrapper .transp-prev-arrow.icon-arrow-left:before {
  content: "\e904";
  font-family: "icomoon" !important;
  color: #7f87ac;
  font-size: 2rem;
  font-weight: 700;
}

.slider-wrapper .transp-prev-arrow:hover {
  filter: brightness(120%);
}

.slider-wrapper .transp-next-arrow {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-wrapper .transp-next-arrow.icon-arrow-right:before {
  content: "\e905";
  font-family: "icomoon" !important;
  color: #7f87ac;
  font-size: 2rem;
  font-weight: 700;
}

.slider-wrapper .transp-next-arrow:hover {
  filter: brightness(120%);
}

.slider-wrapper .round-prev-arrow {
  width: 2.7777777778rem;
  height: 2.7777777778rem;
  border: 1px solid #7f87ac;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-wrapper .round-prev-arrow.icon-arrow-left:before {
  content: "\e901";
  font-family: "icomoon" !important;
  color: #7f87ac;
  font-size: 1.3888888889rem;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.slider-wrapper .round-prev-arrow:hover {
  border-color: #7eb2a3;
}

.slider-wrapper .round-prev-arrow:hover.icon-arrow-left:before {
  color: #7eb2a3;
}

.slider-wrapper .round-next-arrow {
  width: 2.7777777778rem;
  height: 2.7777777778rem;
  border: 1px solid #7f87ac;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-wrapper .round-next-arrow.icon-arrow-right:before {
  content: "\e902";
  font-family: "icomoon" !important;
  color: #7f87ac;
  font-size: 1.3888888889rem;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.slider-wrapper .round-next-arrow:hover {
  border-color: #7eb2a3;
}

.slider-wrapper .round-next-arrow:hover.icon-arrow-right:before {
  color: #7eb2a3;
}

.fw-slider-large .slick-slide {
  min-height: 70vh;
  padding-top: 15rem;
  padding-bottom: 6rem;
}

@media screen and (max-width: 479px) {
  .fw-slider-large .slick-slide {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.fw-slider-middle .slick-slide {
  min-height: 50vh;
  padding-top: 10rem;
  padding-bottom: 4rem;
}

@media screen and (max-width: 1249px) {
  .fw-slider-middle .slick-slide {
    padding-top: 11rem;
    padding-bottom: 6rem;
  }
}
@media screen and (max-width: 479px) {
  .fw-slider-middle .slick-slide {
    padding-top: 8rem;
    padding-bottom: 6rem;
  }
}
.fw-slider-small .slick-slide {
  min-height: 40vh;
  padding-top: 8rem;
  padding-bottom: 4rem;
}

@media screen and (max-width: 1249px) {
  .fw-slider-small .slick-slide {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 479px) {
  .fw-slider-small .slick-slide {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
}
.fw-slider-supersmall .slick-slide {
  min-height: 10vh;
  padding-top: 8rem;
  padding-bottom: 3rem;
}

@media screen and (max-width: 479px) {
  .fw-slider-supersmall .slick-slide {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
}
.fw-slider .slick-track {
  display: flex;
}

.fw-slider .slick-track .slick-slide {
  position: relative;
  display: flex;
  align-items: center;
  height: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 200px;
  background-position: center;
}

@media screen and (max-width: 479px) {
  .fw-slider .slick-track .slick-slide {
    border-bottom-right-radius: 100px;
  }
}
.fw-slider .slick-track .slick-slide::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  opacity: 0.84;
  background: linear-gradient(to right, #2e2958, rgba(0, 0, 0, 0));
}

.fw-slider .slick-track .slick-slide img {
  object-fit: cover;
  width: 100%;
}

.fw-slider .slick-track .slick-slide .container {
  flex-grow: 1;
  z-index: 20;
  padding-left: 5rem;
  padding-right: 5rem;
}

@media screen and (max-width: 990px) {
  .fw-slider .slick-track .slick-slide .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (max-width: 479px) {
  .fw-slider .slick-track .slick-slide .container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.fw-slider .slick-track .slick-slide .slide-container {
  position: relative;
  margin-right: auto;
  max-width: 800px;
  color: #fff;
}

.fw-slider .transp-prev-arrow {
  left: 2rem;
  top: 50%;
  transform: translateY(50%);
}

@media screen and (max-width: 990px) {
  .fw-slider .transp-prev-arrow {
    left: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .fw-slider .transp-prev-arrow {
    left: 0.5rem;
  }
}
.fw-slider .transp-next-arrow {
  right: 2rem;
  top: 50%;
  transform: translateY(50%);
}

@media screen and (max-width: 990px) {
  .fw-slider .transp-next-arrow {
    right: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .fw-slider .transp-next-arrow {
    right: 0.5rem;
  }
}
.mid-slider .slick-track {
  display: flex;
}

.mid-slider .slick-track .slick-slide {
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
  display: flex;
  align-items: center;
  height: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 200px;
  overflow: hidden;
}

@media screen and (max-width: 479px) {
  .mid-slider .slick-track .slick-slide {
    border-bottom-left-radius: 100px;
  }
}
.mid-slider .slick-track .slick-slide::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.64;
  background-color: #443b5f;
}

.mid-slider .slick-track .slick-slide img {
  object-fit: cover;
  width: 100%;
}

.mid-slider .slick-track .slick-slide .container {
  flex-grow: 1;
  z-index: 20;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 990px) {
  .mid-slider .slick-track .slick-slide .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (max-width: 479px) {
  .mid-slider .slick-track .slick-slide .container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.mid-slider .slick-track .slick-slide .slide-container {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  color: #fff;
}

.mid-slider .slick-track .slick-slide .slide-container .mid-header {
  font-size: 8.0555555556rem;
  font-family: "Jost", sans-serif;
  line-height: 1;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 767px) {
  .mid-slider .slick-track .slick-slide .slide-container .mid-header {
    font-size: 5.5555555556rem;
  }
}
.mid-slider .round-prev-arrow {
  left: 2rem;
  top: 50%;
  transform: translateY(50%);
}

@media screen and (max-width: 990px) {
  .mid-slider .round-prev-arrow {
    left: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .mid-slider .round-prev-arrow {
    left: 0.5rem;
  }
}
.mid-slider .round-next-arrow {
  right: 2rem;
  top: 50%;
  transform: translateY(50%);
}

@media screen and (max-width: 990px) {
  .mid-slider .round-next-arrow {
    right: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .mid-slider .round-next-arrow {
    right: 0.5rem;
  }
}
.mid-slider ul.slick-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0;
}

.mid-slider ul.slick-dots > li:only-child {
  display: none;
}

.mid-slider ul.slick-dots li {
  list-style-type: none;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.mid-slider ul.slick-dots li a i {
  font-size: 0.6111111111rem;
  border-radius: 50%;
  color: #7eb2a3;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
}

.mid-slider ul.slick-dots li.slick-active a i {
  color: #7f87ac;
  border-color: #fff;
}

.story-slider {
  padding-left: 5rem;
  padding-right: 5rem;
}

@media screen and (max-width: 1399px) {
  .story-slider {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (max-width: 990px) {
  .story-slider {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.story-slider .slick-track {
  display: flex;
}

.story-slider .slick-track .slick-slide {
  position: relative;
  display: flex;
  align-items: center;
  height: inherit;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.story-slider .slick-track .slick-slide:nth-child(even) {
  margin-top: 4rem;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .story-slider .slick-track .slick-slide:nth-child(even) {
    margin-top: 0;
  }
}
.story-slider .slick-track .slick-slide:nth-child(odd) {
  margin-bottom: 4rem;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .story-slider .slick-track .slick-slide:nth-child(odd) {
    margin-bottom: 0;
  }
}
.story-slider-link {
  position: relative;
  padding-bottom: 3rem;
  padding-right: 3rem;
  max-width: 380px;
  display: flex;
  height: 100%;
}

@media screen and (max-width: 479px) {
  .story-slider-link {
    padding-bottom: 0;
    padding-right: 0;
  }
}
.story-slider-link img {
  flex-grow: 1;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 7px;
}

@media screen and (max-width: 479px) {
  .story-slider-link img {
    border-bottom-left-radius: 0;
  }
}
.story-slider-link-info {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  max-width: 17.6111111111rem;
  padding: 1rem;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-top-right-radius: 7px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 479px) {
  .story-slider-link-info {
    position: static;
    border-top-left-radius: 0;
    max-width: none;
  }
}
.story-slider-link-info-text {
  line-height: 1.6;
  font-family: "Rubik", sans-serif;
  font-size: 0.8888888889rem;
  font-weight: 400;
}

.story-slider-link-info-more {
  font-family: "Rubik", sans-serif;
  font-size: 0.8333333333rem;
  color: #7eb2a3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
}

.story-slider-link-info-more::before {
  content: "";
  height: 2px;
  max-width: 2.2222222222rem;
  background-color: #7f87ac;
  flex-grow: 1;
}

.story-slider .transp-prev-arrow {
  left: 2rem;
  top: 50%;
  transform: translateY(50%);
}

@media screen and (max-width: 990px) {
  .story-slider .transp-prev-arrow {
    left: 0;
  }
}
.story-slider .transp-next-arrow {
  right: 2rem;
  top: 50%;
  transform: translateY(50%);
}

@media screen and (max-width: 990px) {
  .story-slider .transp-next-arrow {
    right: 0;
  }
}
.sponsor-slider .slick-track {
  display: flex;
}

.sponsor-slider .slick-track .slick-slide {
  position: relative;
  display: flex;
  height: inherit;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  width: auto !important;
}

@media screen and (max-width: 767px) {
  .sponsor-slider .slick-track .slick-slide {
    width: 100% !important;
  }
}
.sponsor-slider .slick-track .slick-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sponsor-slider .transp-prev-arrow {
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.75);
  width: 2.6666666667rem;
  height: 2.6666666667rem;
}

@media screen and (max-width: 990px) {
  .sponsor-slider .transp-prev-arrow {
    left: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .sponsor-slider .transp-prev-arrow {
    left: 0.5rem;
  }
}
.sponsor-slider .transp-next-arrow {
  background-color: rgba(255, 255, 255, 0.75);
  width: 2.6666666667rem;
  height: 2.6666666667rem;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 990px) {
  .sponsor-slider .transp-next-arrow {
    right: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .sponsor-slider .transp-next-arrow {
    right: 0.5rem;
  }
}
.partner-slider .slick-track,
.architects-slider .slick-track {
  display: flex;
}

.partner-slider .slick-track .slick-slide,
.architects-slider .slick-track .slick-slide {
  position: relative;
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
}

.dots-container {
  position: relative;
}

.dots-container ul.slick-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2rem;
  left: 5rem;
  margin-bottom: 0;
}

@media screen and (max-width: 990px) {
  .dots-container ul.slick-dots {
    left: 3rem;
  }
}
@media screen and (max-width: 479px) {
  .dots-container ul.slick-dots {
    left: 2.5rem;
  }
}
.dots-container ul.slick-dots > li:only-child {
  display: none;
}

.dots-container ul.slick-dots li {
  list-style-type: none;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.dots-container ul.slick-dots li a i {
  font-size: 0.6111111111rem;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease-in-out;
}

.dots-container ul.slick-dots li.slick-active a i {
  color: #fff;
}

.header-container {
  max-width: 1100px;
  margin-bottom: 2rem;
}

.mixed-header span {
  color: #7eb2a3;
}

.mixed-header span:last-child {
  color: #443b5f;
}

.jost-header {
  font-size: 1.9444444444rem;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.no-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.no-pad-bot {
  padding-bottom: 0;
}

.no-pad-top {
  padding-top: 0;
}

.back-link {
  font-family: "Rubik", sans-serif;
  font-size: 0.8333333333rem;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  text-decoration: none;
}

.back-link::before {
  content: "\e90e";
  font-family: "icomoon" !important;
  color: #7eb2a3;
}

.next-link {
  font-family: "Rubik", sans-serif;
  font-size: 0.8333333333rem;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  text-decoration: none;
}

.next-link::after {
  content: "\e90f";
  font-family: "icomoon" !important;
  color: #7eb2a3;
}

.more-link {
  font-family: "Rubik", sans-serif;
  font-size: 0.8333333333rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  column-gap: 1rem;
}

.more-link::before {
  content: "";
  height: 0.1111111111rem;
  width: 2.2222222222rem;
  display: block;
  background-color: #7f87ac;
}

.top-row {
  position: relative;
  padding-top: 2rem;
}

.top-row::after {
  content: "";
  position: absolute;
  height: 0.2222222222rem;
  left: 1rem;
  right: 1rem;
  top: 0;
  background-color: rgba(127, 135, 172, 0.27);
}

.bottom-row {
  position: relative;
  padding-bottom: 1rem;
}

.bottom-row::after {
  content: "";
  position: absolute;
  height: 0.2222222222rem;
  left: 1rem;
  right: 1rem;
  bottom: 0;
  background-color: rgba(127, 135, 172, 0.27);
}

.main-slider {
  padding-top: 0;
  padding-bottom: 0;
}

.main-slider .num-wrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 2rem;
  margin-bottom: -3rem;
}

@media screen and (max-width: 990px) {
  .main-slider .num-wrapper {
    column-gap: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .main-slider .num-wrapper {
    display: block;
  }
}
.main-slider .num-wrapper .num-item {
  max-width: 23.3333333333rem;
  position: relative;
  transform: translateY(-50%);
  color: #fff;
  text-align: center;
  background-color: #7eb2a3;
  padding: 1rem 2rem;
  border-radius: 20px;
  box-shadow: 0 0 57px rgba(255, 255, 255, 0.32);
}

@media screen and (max-width: 990px) {
  .main-slider .num-wrapper .num-item {
    transform: translateY(-30%);
    padding: 1rem 1rem;
  }
}
@media screen and (max-width: 479px) {
  .main-slider .num-wrapper .num-item {
    transform: translateY(0);
    margin-bottom: 1rem;
    margin-top: 1rem;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}
.main-slider .num-wrapper .num-item-text {
  font-size: 1.1111111111rem;
}

.main-slider .num-wrapper .num-item .h2-style {
  font-weight: 700;
}

.main-slider .num-wrapper .num-item-subtext {
  font-size: 0.9444444444rem;
}

.main-slider .num-wrapper .num-item-mini {
  font-size: 0.7222222222rem;
  margin-top: 0.5rem;
}

.main-slider .num-wrapper .num-item-image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-slider .num-wrapper .num-item.heart-item {
  flex-grow: 1;
  padding-top: 2rem;
}

@media screen and (max-width: 479px) {
  .main-slider .num-wrapper .num-item.heart-item {
    margin-top: 3rem;
  }
}
.addiction .masonry {
  justify-content: center;
}

.addiction .masonry > div {
  align-items: center;
}

@media screen and (max-width: 1249px) {
  .addiction .masonry > div {
    flex-basis: auto;
  }
}
.addiction-program {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 10px;
  overflow: hidden;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 40px rgba(0, 0, 0, 0.15);
}

.addiction-program-info {
  background-color: #e1f4ee;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  flex-grow: 1;
}

.addiction-program-header {
  position: relative;
  padding-bottom: 0.7rem;
  margin-bottom: 0.5rem;
  color: #443b5f;
  transition: all 0.3s ease-in-out;
}

.addiction-program-header::after {
  content: "";
  position: absolute;
  height: 0.3888888889rem;
  width: 5.5555555556rem;
  background-color: #7eb2a3;
  opacity: 0.28;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.addiction-program ul {
  margin-bottom: 0;
  display: inline;
  font-family: "Rubik", sans-serif;
  font-size: 0.9444444444rem;
  letter-spacing: 0.01em;
  transition: all 0.3s ease-in-out;
  color: #505050;
}

.addiction-program ul li {
  margin-left: 0;
  display: inline;
  margin-right: 0.5rem;
  margin-left: 1rem;
  position: relative;
}

.addiction-program ul li::before {
  content: "";
  display: block;
  width: 0.3888888889rem;
  height: 0.3888888889rem;
  background-color: #7eb2a3;
  border-radius: 50%;
  position: absolute;
  top: 0.3888888889rem;
  left: -1rem;
}

.addiction-program ul li:first-child {
  margin-left: 0;
}

.addiction-program ul li:first-child::before {
  content: none;
}

.addiction-program-image {
  display: flex;
  position: relative;
}

.addiction-program-image::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #7f87ac;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.addiction-program-image img {
  width: 100%;
  object-fit: cover;
}

.addiction-program-image .btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "Jost", sans-serif;
  font-size: 0.8333333333rem;
  letter-spacing: 0.05em;
  opacity: 0;
}

.addiction-program-image .btn:hover {
  filter: brightness(100%);
}

.addiction-program:hover {
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
}

.addiction-program:hover .addiction-program-info {
  background-color: #443b5f;
}

.addiction-program:hover .addiction-program-header,
.addiction-program:hover ul {
  color: #fff;
}

.addiction-program:hover .addiction-program-header::after,
.addiction-program:hover ul::after {
  opacity: 1;
}

.addiction-program:hover .addiction-program-image::before {
  opacity: 0.6;
}

.addiction-program:hover .addiction-program-image .btn {
  opacity: 1;
}

.hotlink {
  border-bottom-right-radius: 152px;
  padding-bottom: 2rem;
  padding-top: 3rem;
}

@media screen and (max-width: 479px) {
  .hotlink {
    border-bottom-right-radius: 100px;
  }
}
.hotlink-wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  align-items: center;
}

@media screen and (max-width: 1399px) {
  .hotlink-wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.hotlink-link {
  display: flex;
  column-gap: 1rem;
  max-width: 390px;
  margin-bottom: 1rem;
  flex-basis: auto;
}

.hotlink-link img {
  min-width: 4.3333333333rem;
}

@media screen and (max-width: 479px) {
  .hotlink-link img {
    min-width: 2.7777777778rem;
    width: 2.7777777778rem;
  }
}
.hotlink-link p {
  margin-bottom: 0;
}

.hotlink-link-header {
  font-family: "Jost", sans-serif;
  font-size: 1.9444444444rem;
  letter-spacing: 0.01em;
}

.hotlink-link-text {
  font-size: 1.1111111111rem;
}

.featured {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 479px) {
  .featured {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.featured-line {
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 1rem;
}

.featured-line p {
  margin-bottom: 0;
}

.featured-line::after {
  content: "";
  height: 0.2222222222rem;
  flex-grow: 1;
  display: block;
  background-color: #7eb2a3;
  opacity: 0.39;
}

.featured-underline {
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 1rem;
}

.featured-underline::before {
  content: "";
  height: 0.2222222222rem;
  flex-grow: 1;
  display: block;
  background-color: #7eb2a3;
  opacity: 0.39;
}

.featured.bg-lightpurple .featured-line::after {
  background-color: #7f87ac;
  opacity: 0.27;
}

.featured.bg-lightpurple .featured-underline::before {
  background-color: #7f87ac;
  opacity: 0.27;
}

.featured iframe {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.featured-image {
  display: flex;
}

.featured-image img {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.featured-header {
  font-family: "Jost", sans-serif;
  font-size: 1.9444444444rem;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.featured-subcol {
  display: flex;
  column-gap: 1rem;
}

@media screen and (max-width: 1399px) {
  .featured-subcol {
    display: block;
  }
  .featured-subcol > div {
    max-width: 100%;
  }
}
@media screen and (max-width: 990px) {
  .featured-subcol {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .featured-subcol {
    display: block;
  }
}
.featured-subcol-left {
  flex-basis: 70%;
  flex-grow: 1;
}

.featured ul li {
  margin-bottom: 0.5rem;
}

.featured ul li::marker {
  color: #7eb2a3;
}

.featured ul li a {
  font-family: "Rubik", sans-serif;
  font-size: 0.8333333333rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-decoration: none;
}

.heart-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 1rem;
}

@media screen and (max-width: 1249px) {
  .heart-wrapper {
    display: block;
    text-align: center;
  }
}
.heart-wrapper-inside {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

@media screen and (max-width: 1249px) {
  .heart-wrapper-inside {
    justify-content: center;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .heart-wrapper-inside {
    display: block;
  }
}
.featured-events {
  border-top-right-radius: 152px;
}

@media screen and (max-width: 479px) {
  .featured-events {
    border-top-right-radius: 100px;
  }
}
.featured-events .masonry {
  justify-content: center;
}

.featured-events .masonry > div {
  align-items: center;
  flex-grow: 1;
}

.featured-events-link {
  border-top-right-radius: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 505px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}

.featured-events-link-image {
  display: flex;
}

.featured-events-link-image img {
  width: 100%;
  object-fit: cover;
}

.featured-events-link-info {
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.featured-events-link-info-geo {
  background-color: #7f87ac;
  font-size: 0.6666666667rem;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: flex-end;
  margin-left: auto;
  padding: 0.5rem;
  color: #fff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.featured-events-link-info-geo i {
  color: #db8443;
}

.featured-events-link-info-date {
  padding: 0.5rem;
  margin-left: 2rem;
  transform: translateY(-50%);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  width: 5.6666666667rem;
}

@media screen and (max-width: 767px) {
  .featured-events-link-info-date {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .featured-events-link-info-date {
    margin-left: 0.5rem;
  }
}
.featured-events-link-info-date .h3-style {
  font-family: "Krub", sans-serif;
}

.featured-events-link-info-date span {
  font-size: 0.7777777778rem;
  font-weight: 700;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.05em;
}

.featured-events-link-info-text {
  font-size: 0.8333333333rem;
}

.featured-news {
  border-bottom-right-radius: 152px;
}

@media screen and (max-width: 479px) {
  .featured-news {
    border-bottom-right-radius: 100px;
  }
}
.featured-news .masonry {
  justify-content: center;
}

.featured-news .masonry > div {
  align-items: center;
  flex-grow: 1;
}

.featured-news-link {
  display: flex;
  flex-direction: column;
  max-width: 483px;
}

.featured-news-link-image {
  display: flex;
}

.featured-news-link-image img {
  width: 100%;
  object-fit: cover;
}

.featured-news-link-info {
  padding: 1rem 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.featured-news-link-info-date {
  font-family: "Rubik", sans-serif;
}

.history-wrapper {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 2rem;
  row-gap: 1rem;
}

@media screen and (max-width: 990px) {
  .history-wrapper {
    grid-template-columns: 1fr;
  }
}
.history-hope-mission {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 1249px) {
  .history-hope-mission {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 990px) {
  .history-hope-mission {
    grid-template-columns: 1fr 4fr;
  }
}
@media screen and (max-width: 767px) {
  .history-hope-mission {
    grid-template-columns: 1fr;
  }
}
.history-hope-mission::after {
  content: "";
  position: absolute;
  height: 6px;
  width: 100%;
  background-color: rgba(126, 178, 163, 0.39);
  top: 0;
}

.history-hope-mission-header {
  font-family: "Jost", sans-serif;
  font-size: 1.9444444444rem;
  font-weight: 600;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 479px) {
  .history-hope-mission-header {
    font-size: 1.5555555556em;
  }
}
@media screen and (max-width: 990px) {
  .history-board.support-inside {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
  }
  .history-board.support-inside .h2-style {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
}
.history-board-image {
  display: flex;
  justify-content: center;
}

.history-board-image img {
  width: 100%;
  object-fit: cover;
}

.history-board-calendar {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 990px) {
  .history-board-calendar {
    flex-basis: 100%;
  }
}
.history-board-calendar-image {
  z-index: 5;
  display: flex;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.history-board-calendar-image img {
  border-radius: 50%;
}

.history-board-calendar-info {
  background-color: #7f87ac;
  color: #fff;
  max-width: 19.4444444444rem;
  padding-left: 3rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-size: 0.8888888889rem;
  font-family: "Rubik", sans-serif;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  margin-left: -2rem;
}

.history-board-calendar-info i {
  font-size: 1.7777777778rem;
}

@media screen and (max-width: 1399px) {
  .history-board-calendar-info {
    padding-left: 2.5rem;
    column-gap: 0.7rem;
    flex-basis: 85%;
  }
}
@media screen and (max-width: 990px) {
  .history-board-calendar-info {
    padding-left: 3rem;
    column-gap: 1rem;
    flex-basis: auto;
  }
}
@media screen and (max-width: 479px) {
  .history-board-calendar-info {
    padding-right: 0.5rem;
    padding-left: 2.2rem;
    font-size: 0.7777777778rem;
    column-gap: 0.7rem;
  }
}
.history-board-desc {
  position: relative;
  border-top-right-radius: 70px;
  padding: 2rem;
  max-width: 25rem;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.07);
  margin-bottom: 3rem;
}

.history-board-desc p {
  margin-bottom: 0;
}

.history-board-desc-type, .history-board-desc-loc {
  background-color: rgba(255, 255, 255, 0.54);
  border-radius: 20px;
  font-size: 0.6666666667rem;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  padding: 0.2rem 0.5rem;
}

.history-board-desc-loc {
  background-color: rgba(127, 135, 172, 0.32);
}

.history-board-desc-header {
  font-size: 1.1111111111rem;
  font-family: "Jost", sans-serif;
  margin-bottom: 0.5rem;
}

.history-board-desc-time {
  display: flex;
  column-gap: 0.5rem;
  font-family: "Rubik", sans-serif;
  font-size: 0.9444444444rem;
  color: #505050;
  border-top: 2px solid rgba(127, 135, 172, 0.21);
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.history-board-desc-time i {
  color: #7f87ac;
  font-size: 1rem;
}

.history-board-desc-border {
  border-top: 2px solid rgba(127, 135, 172, 0.21);
  padding-top: 0.5rem;
}

.history-board-desc-geo {
  display: flex;
  column-gap: 0.5rem;
  font-size: 0.8333333333rem;
  color: #505050;
  margin-bottom: 0.5rem;
}

.history-board-desc-geo i {
  color: #7f87ac;
  font-size: 1rem;
}

.history-board-desc-geo a {
  color: #d0685c;
}

.history-board-desc-btn {
  position: absolute;
  bottom: 0;
  right: 1rem;
  transform: translateY(50%);
}

.history-board-contact {
  max-width: 25rem;
  padding: 2rem;
}

.history-board-contact p {
  margin-bottom: 0;
}

.history-board-contact-date {
  display: flex;
  column-gap: 0.5rem;
  font-family: "Rubik", sans-serif;
  font-size: 0.9444444444rem;
  color: #505050;
  border-top: 2px solid rgba(127, 135, 172, 0.21);
  border-bottom: 2px solid rgba(127, 135, 172, 0.21);
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.history-board-contact-date i {
  color: #7eb2a3;
  font-size: 1rem;
}

.history-board-contact-mail {
  display: flex;
  column-gap: 0.5rem;
  font-size: 0.8333333333rem;
  color: #505050;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.history-board-contact-mail i {
  color: #7eb2a3;
  font-size: 1rem;
}

.history-board-contact-mail a {
  color: #d0685c;
}

.team.team-support {
  margin-bottom: -6rem;
}

@media screen and (max-width: 767px) {
  .team.team-support {
    margin-bottom: 0;
  }
}
.team .masonry {
  justify-content: center;
}

.team .masonry > div {
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1249px) {
  .team .masonry > div {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 767px) {
  .team .masonry > div {
    flex-basis: 100%;
  }
}
.team-card {
  width: 100%;
  max-width: 370px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.team-card-image {
  overflow: hidden;
  display: flex;
  border-top-left-radius: 42px;
}

.team-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-card .h3-style {
  margin-bottom: 0.5rem;
}

.team-card-info {
  flex-grow: 1;
  padding: 1rem 2rem;
  border-bottom-right-radius: 42px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}

.team-card-info-subheader {
  position: relative;
  font-family: "Rubik", sans-serif;
  font-size: 0.9444444444rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  letter-spacing: 0.01em;
}

.team-card-info-subheader::after {
  content: "";
  position: absolute;
  height: 0.3888888889rem;
  width: 5.7222222222rem;
  background-color: #7eb2a3;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.team-card-info h3,
.team-card-info .h3-style {
  font-size: 1.5em;
}

@media screen and (max-width: 479px) {
  .team-card-info h3,
  .team-card-info .h3-style {
    font-size: 1.2777777778em;
  }
}
.team-card-info-text {
  font-size: 0.8333333333rem;
}

.team-card-arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 2.7777777778rem;
  height: 2.7777777778rem;
  background-color: #d0685c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-card-arrow::after {
  content: "\e902";
  font-family: "icomoon" !important;
  color: #fff;
}

.mailSign .fg-1 {
  flex-grow: 1;
}

.mailSign-wrapper {
  display: flex;
}

@media screen and (max-width: 990px) {
  .mailSign-wrapper {
    display: block;
  }
}
.mailSign-wrapper-left {
  flex-basis: 50%;
  display: flex;
}

.mailSign-wrapper-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mailSign-wrapper-right {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 990px) {
  .mailSign-wrapper-right {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}
.mailSign-container {
  max-width: 37.2222222222rem;
  flex-grow: 1;
  position: relative;
}

.mailSign-container::before {
  content: url(../../images/icons/plane-2.svg);
  position: absolute;
  left: 3.5rem;
  top: 1rem;
}

@media screen and (max-width: 1249px) {
  .mailSign-container::before {
    left: 1rem;
  }
}
.inside.congrat .inside-card-info {
  color: #443b5f;
  background-color: #e1f4ee;
}

.inside.congrat .inside-card:hover .inside-card-info {
  background-color: #443b5f;
  color: #fff;
}

.inside.resources .inside-card .inside-card-info-header::after {
  content: none;
}

.inside.resources .inside-card:hover .inside-card-info {
  background-color: #7eb2a3;
}

.inside .masonry {
  justify-content: center;
}

.inside .masonry > div {
  align-items: center;
}

@media screen and (max-width: 1249px) {
  .inside .masonry > div {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 767px) {
  .inside .masonry > div {
    flex-basis: 100%;
  }
}
.inside-card {
  position: relative;
  max-width: 372px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.inside-card:hover .btn {
  opacity: 1;
}

@media screen and (min-width: 991px) {
  .inside-card .h3-style {
    font-size: 1.6111111111rem;
  }
}
.inside-card-info {
  flex-grow: 1;
  align-self: stretch;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  border-top-right-radius: 30px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.inside-card-info-header {
  position: relative;
  padding-bottom: 1rem;
}

.inside-card-info-header::after {
  content: "";
  position: absolute;
  height: 0.3888888889rem;
  width: 5.7222222222rem;
  background-color: #7eb2a3;
  bottom: 0;
  left: 0;
}

.inside-card-image {
  flex-grow: 1;
  display: flex;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.inside-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inside-card .btn {
  transform: translateY(-50%);
  margin-bottom: -3rem;
  font-size: 0.8333333333rem;
  font-family: "Jost", sans-serif;
  letter-spacing: 0.05em;
  opacity: 0;
}

.inside-card .btn:hover {
  filter: brightness(100%);
}

.asset-wrapper {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

@media screen and (max-width: 767px) {
  .asset-wrapper {
    grid-template-columns: 1fr;
  }
}
.champion-form-wrapper {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.champion-filter {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.champion-filter .btn {
  position: relative;
  margin-bottom: 2rem;
  outline: none;
}

@media screen and (max-width: 990px) {
  .champion-filter .btn {
    min-width: 140px;
  }
}
.champion-filter .btn::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  right: -1px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 26px 26px 0;
  border-color: transparent #7f87ac transparent transparent;
  transform: rotate(0deg);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.champion-filter .btn.inUse::after {
  opacity: 1;
}

.champion .masonry {
  justify-content: center;
}

.champion .masonry > div {
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1249px) {
  .champion .masonry > div {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 767px) {
  .champion .masonry > div {
    flex-basis: 100%;
  }
}
.champion-card {
  background-color: #443b5f;
  max-width: 330px;
  position: relative;
  border-top-right-radius: 70px;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.07);
  margin-bottom: 2rem;
  margin-left: 1rem;
}

.champion-card:hover {
  background-color: #7eb2a3;
}

.champion-card:hover .champion-card-geo {
  background-color: #7f87ac;
}

.champion-card:hover .champion-card-state {
  background-color: #e1f4ee;
  color: #443b5f;
}

.champion-card:hover .champion-card-city {
  color: #443b5f;
}

.champion-card:hover .champion-card-text {
  color: #2C2731;
}

.champion-card:hover .champion-card-btn {
  background-color: #d0685c;
  border-color: #d0685c;
}

.champion-card-geo {
  position: absolute;
  left: 0;
  top: 1rem;
  background-color: #d0685c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.3333333333rem;
  height: 3.3333333333rem;
  transform: translateX(-50%);
}

.champion-card-geo i {
  color: #fff;
  font-size: 1.6666666667rem;
}

.champion-card-state {
  color: #e1f4ee;
  font-size: 0.5555555556rem;
  font-family: "Rubik", sans-serif;
  background-color: rgba(127, 135, 172, 0.32);
  border-radius: 12.5px;
  display: inline-flex;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.champion-card-city {
  color: #e1f4ee;
  font-size: 1.2222222222rem;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.01em;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.champion-card-adress {
  color: #fff5ec;
  font-size: 1.1111111111rem;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.01em;
  margin-bottom: 0.5rem;
  position: relative;
}

.champion-card-adress::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #7f87ac;
  opacity: 0.63;
}

.champion-card-text {
  color: #fff;
  font-size: 0.9444444444rem;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.01em;
  transition: all 0.3s ease-in-out;
}

.champion-card-btn {
  position: absolute;
  right: 1rem;
  bottom: 0;
  font-size: 0.8333333333rem;
  transform: translateY(50%);
  color: #fff5ec;
}

.champion-card-btn:hover {
  filter: brightness(100%);
}

.location-line {
  font-family: "Jost", sans-serif;
  font-size: 1.9444444444rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  column-gap: 1rem;
}

.location-line::after {
  content: "";
  flex-grow: 1;
  height: 0.1111111111rem;
  display: block;
  background-color: #7eb2a3;
  opacity: 0.39;
}

.programs .masonry {
  justify-content: center;
}

.programs .masonry > div {
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1249px) {
  .programs .masonry > div {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 767px) {
  .programs .masonry > div {
    flex-basis: 100%;
  }
}
.programs-card {
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.programs-card:hover {
  box-shadow: 7px 7px 14px rgba(0, 0, 0, 0.16);
}

.programs-card:hover .programs-card-image::before, .programs-card:hover .programs-card-image::after {
  opacity: 1;
}

.programs-card:hover .programs-card-info {
  background-color: #7eb2a3;
}

.programs-card:hover .programs-card-header {
  color: #443b5f;
}

.programs-card:hover .programs-card-btn {
  opacity: 1;
}

.programs-card-image {
  position: relative;
  margin-bottom: -2.5rem;
  flex-grow: 1;
}

.programs-card-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: rgba(68, 59, 95, 0.83);
  transition: all 0.3s ease-in-out;
}

.programs-card-image::after {
  content: url(../../images/icons/FAN.svg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.programs-card-info {
  flex-grow: 1;
  padding: 2rem;
  position: relative;
  z-index: 5;
  background-color: #443b5f;
  border-top-right-radius: 50px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.programs-card-subheader {
  font-size: 0.8333333333rem;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.05em;
  color: #fff5ec;
}

.programs-card-header {
  position: relative;
  color: #e1f4ee;
  padding-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}

.programs-card-header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.3888888889rem;
  width: 5.7222222222rem;
  background-color: #7f87ac;
}

.programs-card-text {
  color: #fff;
  font-size: 0.8333333333rem;
  margin-bottom: 1rem;
}

.programs-card-hero {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.programs-card-btn {
  margin-top: 2rem;
  align-self: center;
  margin-bottom: -3rem;
  opacity: 0;
}

@media screen and (max-width: 990px) {
  .programs-card-btn {
    font-size: 0.8333333333rem;
  }
}
.programs-card-btn:hover {
  filter: brightness(100%);
}

.events.event-inside .events-item {
  padding: 3rem 2rem;
  margin-bottom: 2rem;
}

.events.event-inside .events-item.no-bg {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

@media screen and (max-width: 990px) {
  .events.event-inside .events-item {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .events.event-inside .events-item {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.events.event-inside .events-item-info {
  padding: 1rem 0 0 2rem;
}

@media screen and (max-width: 990px) {
  .events.event-inside .events-item-info {
    padding-top: 3rem;
    padding-left: 0;
  }
}
.events.event-inside .events-item-info-geo {
  top: -3rem;
  right: -2rem;
}

@media screen and (max-width: 990px) {
  .events.event-inside .events-item-info-geo {
    top: 0;
    right: 0;
  }
}
.events-item {
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  margin-bottom: 4rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}

.events-item.no-bg {
  background-color: transparent;
  box-shadow: none;
}

@media screen and (max-width: 990px) {
  .events-item {
    border-top-right-radius: 40px;
    display: block;
  }
}
.events-item-image {
  flex-basis: 50%;
  display: flex;
  border-top-right-radius: 40px;
  overflow: hidden;
}

.events-item-info {
  flex-basis: 50%;
  padding: 3rem 2rem;
  position: relative;
}

.events-item-info-geo {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #7f87ac;
  font-size: 0.7777777778rem;
  padding: 0.5rem 0.8rem;
  color: #fff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  letter-spacing: 0.01em;
}

.events-item-info-geo i {
  color: #ffe2be;
  margin-right: 0.5rem;
}

.events-item-info-day {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  column-gap: 1rem;
  margin-bottom: 0.5rem;
}

.events-item-info-day::after {
  content: "";
  flex-grow: 1;
  height: 0.2222222222rem;
  display: block;
  background-color: #7f87ac;
  opacity: 0.29;
}

.events-item-info-header span {
  position: relative;
  margin-right: 1rem;
  padding-right: 0.5rem;
}

.events-item-info-header span::after {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  background-color: #7f87ac;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.events-item-info-header span:last-child::after {
  content: none;
}

.events-item-info-btn {
  color: #fff5ec;
  position: absolute;
  right: 2rem;
  bottom: 0;
  transform: translateY(50%);
}

.events-item-info .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.contact .masonry > div {
  align-items: center;
}

.contact-item {
  width: 20.5555555556rem;
  border: 2px solid #fff;
  background-color: #fff;
  flex-grow: 1;
  border-radius: 20px;
  padding: 1rem 2rem 1rem 2rem;
  box-shadow: 20px 20px 50px rgba(0, 0, 1, 0.05);
}

@media screen and (max-width: 384px) {
  .contact-item {
    width: 100%;
  }
}
.contact-item-image {
  box-shadow: 0 10px 40px rgba(57, 68, 247, 0.2);
  width: 5.3888888889rem;
  height: 5.3888888889rem;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  font-size: 1.6666666667rem;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.contact-item-text a,
.contact-item-text p {
  margin-bottom: 0.5rem;
  display: block;
}

.touch {
  position: relative;
  padding-top: 5rem;
}

.touch::before {
  content: "";
  background-color: #F9FAF0;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
}

.touch-wrapper {
  position: relative;
  z-index: 5;
  box-shadow: 25px 25px 60px rgba(0, 0, 1, 0.1);
  padding: 2rem 4rem 4rem 4rem;
}

@media screen and (max-width: 767px) {
  .touch-wrapper {
    padding: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .touch-wrapper {
    padding: 2rem 1rem;
  }
}
.touch-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.help .masonry {
  justify-content: center;
}

.help .masonry > div {
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1249px) {
  .help .masonry > div {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 767px) {
  .help .masonry > div {
    flex-basis: 100%;
  }
}
.help-container {
  max-width: 1100px;
}

.help-item {
  position: relative;
  background-color: #e1f4ee;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
  max-width: 500px;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 40px;
  align-items: center;
}

.help-item-info {
  padding: 1rem;
  flex-grow: 1;
}

.help-item-info-option {
  font-family: "Jost", sans-serif;
  font-size: 1.1111111111rem;
  font-weight: 700;
  letter-spacing: 0.01em;
  margin-bottom: 0.5rem;
}

.help-item-info-header {
  position: relative;
  padding-bottom: 1rem;
}

.help-item-info-header::after {
  content: "";
  position: absolute;
  height: 0.3888888889rem;
  width: 5.5555555556rem;
  background-color: #7eb2a3;
  opacity: 0.28;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.help-item-image {
  display: flex;
}

.help-item .btn {
  transform: translateY(-50%);
  margin-bottom: -3.2rem;
}

.help-emergency {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}

.help-emergency-icon {
  border-radius: 50%;
  background-color: #e1f4ee;
  width: 4.3333333333rem;
  height: 4.3333333333rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .help-emergency-icon {
    width: 3.3333333333rem;
    height: 3.3333333333rem;
  }
}
.help-emergency-icon i {
  color: #7eb2a3;
  font-size: 1.5555555556rem;
}

@media screen and (max-width: 767px) {
  .help-emergency-icon i {
    font-size: 1.2222222222rem;
  }
}
.help-emergency-header {
  font-family: "Jost", sans-serif;
  font-size: 1.9444444444rem;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.present-wrapper {
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
}

@media screen and (max-width: 767px) {
  .present-wrapper {
    grid-template-columns: 1fr;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .present-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (min-width: 991px) {
  .present-wrapper.one-sponsor {
    max-width: 50% !important;
  }
}
.present-wrapper .img-cover {
  display: flex;
  align-items: center;
}

.present .platinum-wrapper {
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
}

@media screen and (min-width: 991px) {
  .present .platinum-wrapper.one-sponsor {
    max-width: 33.3% !important;
  }
}
@media screen and (max-width: 767px) {
  .present .platinum-wrapper.one-sponsor {
    max-width: 50% !important;
  }
}
@media screen and (max-width: 767px) {
  .present .platinum-wrapper {
    grid-template-columns: 1fr 1fr;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .present .platinum-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}
.present .gold-wrapper {
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
}

@media screen and (min-width: 991px) {
  .present .gold-wrapper.one-sponsor {
    max-width: 25% !important;
  }
}
@media screen and (max-width: 990px) {
  .present .gold-wrapper.one-sponsor {
    max-width: 33.3% !important;
  }
}
@media screen and (max-width: 767px) {
  .present .gold-wrapper.one-sponsor {
    max-width: 50% !important;
  }
}
@media screen and (max-width: 990px) {
  .present .gold-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .present .gold-wrapper {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media screen and (max-width: 479px) {
  .present .gold-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}
.present .one-sponsor {
  margin-left: auto !important;
  margin-right: auto !important;
  grid-template-columns: 1fr !important;
}

.present .sponsor-text-wrapper {
  margin: 2rem;
}

.present .sponsor-text-wrapper ul li {
  margin-left: 0;
  margin-bottom: 1rem;
}

.present .img-cover {
  display: flex;
  align-items: center;
}

.contact-social {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.contact-social a {
  display: block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 990px) {
  .contact-social a {
    max-width: 2.2222222222rem;
  }
}
@media screen and (max-width: 479px) {
  .contact-social a {
    max-width: 1.9444444444rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}
.news-wrapper {
  display: flex;
  column-gap: 1rem;
}

@media screen and (max-width: 1249px) {
  .news-wrapper {
    flex-direction: column-reverse;
  }
}
.news-wrapper-left {
  flex-basis: 65%;
}

.news-wrapper-right {
  flex-grow: 1;
}

@media screen and (max-width: 1249px) {
  .news-wrapper-right {
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    align-items: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .news-wrapper-right {
    display: block;
  }
}
.news-item {
  margin-bottom: 2rem;
}

.news-item-info {
  padding: 0 1rem 0 2rem;
}

@media screen and (max-width: 1249px) {
  .news-item-info {
    padding-left: 1rem;
  }
}
.news-item-info-date {
  font-family: "Rubik", sans-serif;
}

.news-item-info .more-link {
  justify-content: flex-start;
}

.news-archive {
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  max-width: 20.5555555556rem;
  padding: 2rem;
  font-size: 0.8888888889rem;
  font-family: "Rubik", sans-serif;
  color: #505050;
  box-shadow: 10px 10px 40px rgba(0, 0, 1, 0.07);
}

@media screen and (max-width: 767px) {
  .news-archive {
    margin-left: 0;
  }
}
@media screen and (max-width: 479px) {
  .news-archive {
    margin-left: auto;
  }
}
.news-archive ul li {
  margin-bottom: 1rem;
  margin-left: 0;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.news-archive ul li::before {
  content: "\e90d";
  font-family: "icomoon" !important;
  font-size: 0.5rem;
  color: #d0685c;
}

.news-archive ul li a.active {
  color: #d0685c;
}

.news-letter {
  flex-grow: 1;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 20.5555555556rem;
  padding: 2rem 1rem;
  box-shadow: 10px 10px 40px rgba(0, 0, 1, 0.07);
}

@media screen and (max-width: 767px) {
  .news-letter {
    margin-left: 0;
  }
}
@media screen and (max-width: 479px) {
  .news-letter {
    margin-left: auto;
  }
}
.news-letter-get {
  margin-bottom: 0.5rem;
  font-size: 0.8333333333rem;
  font-family: "Rubik", sans-serif;
}

.pagination {
  display: inline-flex;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  font-size: 1rem;
  box-shadow: 20px 30px 70px rgba(0, 0, 1, 0.1);
}

.pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  position: relative;
}

.pagination-item::before {
  content: "";
  width: 1px;
  height: 5px;
  right: 0;
  top: 49%;
  transform: translateY(-50%);
  position: absolute;
  background-color: #505050;
}

.pagination-back {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  position: relative;
}

.pagination-back::before {
  content: "\e910";
  font-family: "icomoon" !important;
  font-size: 0.6111111111rem;
}

.pagination-back::after {
  content: "";
  width: 1px;
  height: 5px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-color: #505050;
}

.pagination-forward {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
}

.pagination-forward::before {
  content: "\e90f";
  font-family: "icomoon" !important;
  font-size: 0.6111111111rem;
}

.pagination-active {
  color: #f15b26;
}

.pagination .disp-none {
  display: none !important;
}

.faq {
  border-top-right-radius: 152px;
}

@media screen and (max-width: 479px) {
  .faq {
    border-top-right-radius: 100px;
  }
}
.faq-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 1rem;
}

.faq-item:last-child {
  border-bottom: none;
}

@media screen and (max-width: 990px) {
  .faq-item:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.faq-item-question {
  position: relative;
  color: #7f87ac;
  font-family: "Rubik", sans-serif;
  font-size: 1.2222222222rem;
  padding-right: 2rem;
  cursor: pointer;
}

.faq-item-question::after {
  content: "+";
  width: 1.8333333333rem;
  height: 1.8333333333rem;
  border: 1px solid #505050;
  color: #7f87ac;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  transition: all 0.3s ease-in-out;
}

.faq-item-question.active::after {
  content: "-";
  border-color: #7eb2a3;
  color: #7eb2a3;
}

.faq-item-answer {
  font-size: 0.8333333333rem;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  display: none;
}

.description .masonry {
  justify-content: center;
}

.description .masonry > div {
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1249px) {
  .description .masonry > div {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 767px) {
  .description .masonry > div {
    flex-basis: 100%;
  }
}
.meetings .masonry {
  justify-content: center;
}

.meetings .masonry > div {
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1249px) {
  .meetings .masonry > div {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 767px) {
  .meetings .masonry > div {
    flex-basis: 100%;
  }
}
.meetings .history-board-desc {
  max-width: 19.4444444444rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.meetings .history-board-desc .btn {
  font-size: 0.8888888889rem;
}

.hope-header {
  align-items: center;
  column-gap: 1rem;
  display: flex;
}

.hope-header p {
  margin-bottom: 0;
}

.hope-header::after {
  content: "";
  height: 0.3333333333rem;
  flex-grow: 1;
  display: block;
  background-color: #7eb2a3;
  opacity: 0.39;
}

.drop-wrapper {
  display: flex;
  column-gap: 2rem;
}

.drop-wrapper .agent {
  max-width: none;
}

@media screen and (max-width: 767px) {
  .drop-wrapper {
    display: block;
    margin-bottom: 4rem;
    margin-left: 1rem;
  }
}
.agent {
  max-width: 27.7777777778rem;
  position: relative;
  flex-grow: 1;
  transition: all 0.3s ease-in-out;
}

.agent::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  top: 2.5rem;
  background-color: rgba(127, 135, 172, 0.27);
  width: 0;
}

.agent::after {
  content: "";
  position: absolute;
  left: 1.5rem;
  right: 2.5rem;
  top: 1rem;
  flex-grow: 1;
  background-color: rgba(127, 135, 172, 0.27);
  height: 0.2222222222rem;
  display: block;
}

.agent-circles {
  cursor: pointer;
}

.agent-circles:before {
  content: "\e902";
  position: absolute;
  top: 0;
  left: -1rem;
  width: 2.1111111111rem;
  height: 2.1111111111rem;
  font-size: 0.8888888889rem;
  font-family: "icomoon" !important;
  border-radius: 50%;
  background-color: #d0685c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.agent-circles::after {
  content: "-";
  position: absolute;
  font-size: 1.3888888889rem;
  line-height: normal;
  top: 0;
  right: 0;
  width: 2.1111111111rem;
  height: 2.1111111111rem;
  font-weight: 700;
  border-radius: 50%;
  background-color: rgba(60, 60, 60, 0.34);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.agent-info {
  display: none;
}

.agent-info ul {
  margin-left: 1rem !important;
}

.agent.active {
  padding: 3rem 1rem 2rem 2rem;
}

.agent.active::before {
  width: 0.2222222222rem;
}

.agent.active .agent-circles:before {
  transform: rotate(90deg);
  background-color: #7eb2a3;
}

.agent.active .agent-circles::after {
  opacity: 1;
}

.agent-name {
  cursor: pointer;
  flex-basis: 30%;
}

@media screen and (max-width: 767px) {
  .agent-name {
    margin-bottom: 0.5rem;
  }
}
.lost-one {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .lost-one {
    display: block;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
.lost-one-image {
  display: flex;
  flex-basis: 31%;
}

.lost-one-image img {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

@media screen and (max-width: 767px) {
  .lost-one-image img {
    border-bottom-left-radius: 0;
  }
}
.lost-one-info {
  flex-basis: 69%;
  flex-grow: 1;
  background-color: #fff;
  margin-top: 1rem;
  margin-left: -2rem;
  padding: 2rem;
  font-family: "Rubik", sans-serif;
  font-size: 0.8888888889rem;
  font-weight: 400;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 767px) {
  .lost-one-info {
    margin: auto;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 7px;
  }
}
@media screen and (max-width: 990px) {
  .builders-image {
    max-width: 27.7777777778rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 767px) {
  .architects .masonry {
    display: block;
  }
}
.architects-item {
  margin-bottom: 1rem;
}

@media screen and (max-width: 1399px) {
  .architects-item {
    flex-basis: 33%;
  }
}
@media screen and (max-width: 1249px) {
  .architects-item {
    flex-basis: 50%;
  }
}
.architects-item .masonry-inner {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .architects-item .masonry-inner {
    max-width: 27.7777777778rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.architects-item-header {
  padding: 1rem 2rem;
  border-top-right-radius: 30px;
}

@media screen and (max-width: 479px) {
  .architects-item-header {
    padding: 1rem;
  }
}
.architects-item-header-text {
  position: relative;
  font-size: 1.2222222222rem;
  font-family: "Rubik", sans-serif;
  color: #fff5ec;
  padding-bottom: 0.5rem;
}

.architects-item-header-text::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  height: 0.3888888889rem;
  width: 5.7222222222rem;
  background-color: rgba(255, 255, 255, 0.28);
}

.architects-item-info {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #f5f5f5;
  border-left: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
}

.architects-item-info ul {
  flex-grow: 1;
}

.architects-item-info ul li {
  border-bottom: 2px solid #efedef;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.architects-item-btn {
  transform: translateY(35%);
}

.architects-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}

@media screen and (max-width: 479px) {
  .architects-contact {
    column-gap: 0.5rem;
  }
}
.architects-contact-info {
  padding-left: 1rem;
  border-left: 4px solid rgba(127, 135, 172, 0.27);
}

@media screen and (max-width: 479px) {
  .architects-contact-info {
    padding-left: 0.5rem;
  }
}
.architects-contact-info-text {
  font-weight: 600;
  font-size: 1.2777777778rem;
}

@media screen and (max-width: 479px) {
  .architects-contact-info-text {
    font-size: 1rem;
  }
}
.architects-contact-phone {
  font-family: "Jost", sans-serif;
  font-size: 1.9444444444rem;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 479px) {
  .architects-contact-phone {
    font-size: 1.3888888889rem;
  }
}
.wysiwyg-base a {
  text-decoration: none;
}

.wysiwyg-base ul {
  margin-left: 2rem;
}

@media screen and (max-width: 479px) {
  .wysiwyg-base ul {
    margin-left: 1rem;
  }
}
.wysiwyg-base ul li {
  margin-bottom: 0.5rem;
}

.wysiwyg-base ul li::marker {
  color: #7f87ac;
}

.wysiwyg a {
  color: #7eb2a3;
}

.wysiwyg ul {
  margin-left: 2rem;
}

@media screen and (max-width: 479px) {
  .wysiwyg ul {
    margin-left: 1rem;
  }
}
.wysiwyg ul li {
  margin-bottom: 0.5rem;
}

.wysiwyg ul li::marker {
  color: #7f87ac;
}

.wysiwyg-options a {
  color: #d0685c;
}

.wysiwyg-options ul {
  margin-left: 2rem;
}

@media screen and (max-width: 479px) {
  .wysiwyg-options ul {
    margin-left: 1rem;
  }
}
.wysiwyg-options ul li {
  margin-bottom: 0.5rem;
}

.wysiwyg-options ul li::marker {
  color: #7f87ac;
}

.wysiwyg-red a {
  color: #d0685c;
}

.wysiwyg-small {
  font-size: 0.8333333333rem;
}

.dis-btn {
  opacity: 0;
  pointer-events: none;
}

.fit-content img {
  max-width: fit-content;
}

.media-wrapper.donate-wrapper {
  padding-bottom: 195%;
}

@media screen and (max-width: 1249px) {
  .media-wrapper.donate-wrapper {
    padding-bottom: 311%;
  }
}
@media screen and (max-width: 990px) {
  .media-wrapper.donate-wrapper {
    padding-bottom: 210%;
  }
}
@media screen and (max-width: 767px) {
  .media-wrapper.donate-wrapper {
    padding-bottom: 300%;
  }
}
@media screen and (max-width: 479px) {
  .media-wrapper.donate-wrapper {
    padding-bottom: 570%;
  }
}
.media-wrapper.resque-wrapper {
  padding-bottom: 120%;
}

@media screen and (max-width: 1249px) {
  .media-wrapper.resque-wrapper {
    padding-bottom: 155%;
  }
}
@media screen and (max-width: 990px) {
  .media-wrapper.resque-wrapper {
    padding-bottom: 110%;
  }
}