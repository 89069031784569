@use "../components/mixins";
@use "../components/variables";

.slider-wrapper {
	position: relative !important;

	&.init {
		min-height: 200px;
		max-height: 30vh;
		.slick-slider {
			opacity: 0;
		}
	}
	.preloader-overlay {
		position: absolute;
		background: transparent;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 10;

		.preloader {
			position: absolute !important;
			top: 50%;
			left: 50%;
			width: 6rem;
			height: 6rem;
			margin-top: -3rem;
			margin-left: -3rem;
			background-image: url(../../images/fw-slider/new-loading.gif);
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	.prev-arrow,
	.next-arrow {
		position: absolute;
		text-decoration: none;
		transition: 300ms ease-in-out;
		z-index: 20;
	}

	.transp-prev-arrow {
		width: mixins.rem(36);
		height: mixins.rem(36);
		display: flex;
		align-items: center;
		justify-content: center;
		&.icon-arrow-left:before {
			content: "\e904";
			font-family: variables.$icomoon;
			color: variables.$purple;
			font-size: mixins.rem(36);
			font-weight: 700;
		}
		&:hover {
			filter: brightness(120%);
		}
	}
	.transp-next-arrow {
		width: mixins.rem(36);
		height: mixins.rem(36);
		display: flex;
		align-items: center;
		justify-content: center;
		&.icon-arrow-right:before {
			content: "\e905";
			font-family: variables.$icomoon;
			color: variables.$purple;
			font-size: mixins.rem(36);
			font-weight: 700;
		}

		&:hover {
			filter: brightness(120%);
		}
	}
	.round-prev-arrow {
		width: mixins.rem(50);
		height: mixins.rem(50);
		border: 1px solid variables.$purple;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		&.icon-arrow-left:before {
			content: "\e901";
			font-family: variables.$icomoon;
			color: variables.$purple;
			font-size: mixins.rem(25);
			font-weight: 700;
			@include mixins.transition;
		}
		&:hover {
			border-color: variables.$green;
			&.icon-arrow-left:before {
				color: variables.$green;
			}
		}
	}
	.round-next-arrow {
		width: mixins.rem(50);
		height: mixins.rem(50);
		border: 1px solid variables.$purple;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		&.icon-arrow-right:before {
			content: "\e902";
			font-family: variables.$icomoon;
			color: variables.$purple;
			font-size: mixins.rem(25);
			font-weight: 700;
			@include mixins.transition;
		}

		&:hover {
			border-color: variables.$green;
			&.icon-arrow-right:before {
				color: variables.$green;
			}
		}
	}
}

.fw-slider-large {
	.slick-slide {
		min-height: 70vh;
		padding-top: 15rem;
		padding-bottom: 6rem;
		@include mixins.bdown(xs) {
			padding-top: 10rem;
			padding-bottom: 10rem;
		}
	}
}

.fw-slider-middle {
	.slick-slide {
		min-height: 50vh;
		padding-top: 10rem;
		padding-bottom: 4rem;
		@include mixins.bdown(nm) {
			padding-top: 11rem;
			padding-bottom: 6rem;
		}
		@include mixins.bdown(xs) {
			padding-top: 8rem;
			padding-bottom: 6rem;
		}
	}
}

.fw-slider-small {
	.slick-slide {
		min-height: 40vh;
		padding-top: 8rem;
		padding-bottom: 4rem;
		@include mixins.bdown(nm) {
			padding-top: 10rem;
			padding-bottom: 5rem;
		}
		@include mixins.bdown(xs) {
			padding-top: 6rem;
			padding-bottom: 5rem;
		}
	}
}

.fw-slider-supersmall {
    .slick-slide {
        min-height: 10vh;
        padding-top: 8rem;
        padding-bottom: 3rem;
        @include mixins.bdown(xs) {
            padding-top: 6rem;
            padding-bottom: 5rem;
        }
    }
}

.fw-slider {
	.slick-track {
		display: flex;
		.slick-slide {
			position: relative;
			display: flex;
			align-items: center;
			height: inherit;
			background-repeat: no-repeat;
			background-size: cover;
			border-bottom-right-radius: 200px;
			background-position: center;
			@include mixins.bdown(xs) {
				border-bottom-right-radius: 100px;
			}
			&::after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 50%;
				opacity: 0.84;
				background: linear-gradient(
					to right,
					#2e2958,
					rgba($color: variables.$black, $alpha: 0)
				);
			}

			img {
				object-fit: cover;
				width: 100%;
			}
			.container {
				flex-grow: 1;
				z-index: 20;
				padding-left: 5rem;
				padding-right: 5rem;
				@include mixins.bdown(md) {
					padding-left: 3rem;
					padding-right: 3rem;
				}
				@include mixins.bdown(xs) {
					padding-left: 2.5rem;
					padding-right: 2.5rem;
				}
			}
			.slide-container {
				position: relative;
				margin-right: auto;
				max-width: 800px;
				color: variables.$white;
			}
		}
	}
	.transp-prev-arrow {
		left: 2rem;
		top: 50%;
		transform: translateY(50%);
		@include mixins.bdown(md) {
			left: 1rem;
		}
		@include mixins.bdown(xs) {
			left: 0.5rem;
		}
	}

	.transp-next-arrow {
		right: 2rem;
		top: 50%;
		transform: translateY(50%);
		@include mixins.bdown(md) {
			right: 1rem;
		}
		@include mixins.bdown(xs) {
			right: 0.5rem;
		}
	}
}

.mid-slider {
	.slick-track {
		display: flex;
		.slick-slide {
			padding-top: 5rem;
			padding-bottom: 5rem;
			position: relative;
			display: flex;
			align-items: center;
			height: inherit;
			background-repeat: no-repeat;
			background-size: cover;
			border-bottom-left-radius: 200px;
			overflow: hidden;
			@include mixins.bdown(xs) {
				border-bottom-left-radius: 100px;
			}
			&::after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				opacity: 0.64;
				background-color: variables.$blue;
			}

			img {
				object-fit: cover;
				width: 100%;
			}
			.container {
				flex-grow: 1;
				z-index: 20;
				padding-left: 5rem;
				padding-right: 5rem;
				margin-bottom: 3rem;
				@include mixins.bdown(md) {
					padding-left: 3rem;
					padding-right: 3rem;
				}
				@include mixins.bdown(xs) {
					padding-left: 2.5rem;
					padding-right: 2.5rem;
				}
			}
			.slide-container {
				position: relative;
				margin-right: auto;
				margin-left: auto;
				max-width: 800px;
				color: variables.$white;
				.mid-header {
					font-size: mixins.rem(145);
					font-family: variables.$jost;
					line-height: 1;
					@include mixins.letter-spacing(10);
					@include mixins.bdown(sm) {
						font-size: mixins.rem(100);
					}
				}
			}
		}
	}
	.round-prev-arrow {
		left: 2rem;
		top: 50%;
		transform: translateY(50%);
		@include mixins.bdown(md) {
			left: 1rem;
		}
		@include mixins.bdown(xs) {
			left: 0.5rem;
		}
	}

	.round-next-arrow {
		right: 2rem;
		top: 50%;
		transform: translateY(50%);
		@include mixins.bdown(md) {
			right: 1rem;
		}
		@include mixins.bdown(xs) {
			right: 0.5rem;
		}
	}
	ul.slick-dots {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 3rem;
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: 0;
		> li:only-child {
			display: none;
		}
		li {
			list-style-type: none;
			margin-left: 0.4rem;
			margin-right: 0.4rem;
			a i {
				font-size: mixins.rem(11);
				border-radius: 50%;
				color: variables.$green;
				border: 1px solid transparent;
				@include mixins.transition;
			}
			&.slick-active {
				a i {
					color: variables.$purple;
					border-color: variables.$white;
				}
			}
		}
	}
}

.story-slider {
	padding-left: 5rem;
	padding-right: 5rem;
	@include mixins.bdown(lg) {
		padding-left: 4rem;
		padding-right: 4rem;
	}
	@include mixins.bdown(md) {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.slick-track {
		display: flex;
		.slick-slide {
			position: relative;
			display: flex;
			align-items: center;
			height: inherit;
			margin-left: 0.5rem;
			margin-right: 0.5rem;

			&:nth-child(even) {
				margin-top: 4rem;
				justify-content: center;
				@include mixins.bdown(sm) {
					margin-top: 0;
				}
			}
			&:nth-child(odd) {
				margin-bottom: 4rem;
				justify-content: center;
				@include mixins.bdown(sm) {
					margin-bottom: 0;
				}
			}
		}
	}
	&-link {
		position: relative;
		padding-bottom: 3rem;
		padding-right: 3rem;
		max-width: 380px;
		display: flex;
		height: 100%;
		@include mixins.bdown(xs) {
			padding-bottom: 0;
			padding-right: 0;
		}
		img {
			flex-grow: 1;
			width: 100%;
			object-fit: cover;
			border-top-left-radius: 7px;
			border-bottom-left-radius: 7px;
			border-top-right-radius: 7px;
			@include mixins.bdown(xs) {
				border-bottom-left-radius: 0;
			}
		}
		&-info {
			position: absolute;
			bottom: 0;
			right: 0;
			background-color: variables.$white;
			max-width: mixins.rem(317);
			padding: 1rem;
			border-top-left-radius: 7px;
			border-bottom-left-radius: 7px;
			border-top-right-radius: 7px;
			box-shadow: 0 0 30px rgba($color: variables.$black, $alpha: 0.15);
			@include mixins.bdown(xs) {
				position: static;
				border-top-left-radius: 0;
				max-width: none;
			}
			&-text {
				line-height: 1.6;
				font-family: variables.$rubik;
				font-size: mixins.rem(16);
				font-weight: 400;
			}
			&-more {
				font-family: variables.$rubik;
				font-size: mixins.rem(15);
				color: variables.$green;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				column-gap: 1rem;
				&::before {
					content: "";
					height: 2px;
					max-width: mixins.rem(40);
					background-color: variables.$purple;
					flex-grow: 1;
				}
			}
		}
	}

	.transp-prev-arrow {
		left: 2rem;
		top: 50%;
		transform: translateY(50%);
		@include mixins.bdown(md) {
			left: 0;
		}
	}

	.transp-next-arrow {
		right: 2rem;
		top: 50%;
		transform: translateY(50%);
		@include mixins.bdown(md) {
			right: 0;
		}
	}
}

.sponsor-slider {
	.slick-track {
		display: flex;
		.slick-slide {
			position: relative;
			display: flex;
			height: inherit;
			margin-right: 0.5rem;
			margin-left: 0.5rem;
			width: auto !important;
			@include mixins.bdown(sm) {
				width: 100% !important;
			}
			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}
	.transp-prev-arrow {
		left: 2rem;
		top: 50%;
		transform: translateY(-50%);
		background-color: rgba($color: variables.$white, $alpha: 0.75);
		width: mixins.rem(48);
		height: mixins.rem(48);
		@include mixins.bdown(md) {
			left: 1rem;
		}
		@include mixins.bdown(xs) {
			left: 0.5rem;
		}
	}

	.transp-next-arrow {
		background-color: rgba($color: variables.$white, $alpha: 0.75);
		width: mixins.rem(48);
		height: mixins.rem(48);
		right: 2rem;
		top: 50%;
		transform: translateY(-50%);
		@include mixins.bdown(md) {
			right: 1rem;
		}
		@include mixins.bdown(xs) {
			right: 0.5rem;
		}
	}
}

.partner-slider,
.architects-slider {
	.slick-track {
		display: flex;
		.slick-slide {
			position: relative;
			display: flex;
			height: inherit;
			align-items: center;
			justify-content: center;
		}
	}
}

.dots-container {
	position: relative;

	ul.slick-dots {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 2rem;
		left: 5rem;
		margin-bottom: 0;
		@include mixins.bdown(md) {
			left: 3rem;
		}
		@include mixins.bdown(xs) {
			left: 2.5rem;
		}
		> li:only-child {
			display: none;
		}
		li {
			list-style-type: none;
			margin-left: 0.4rem;
			margin-right: 0.4rem;
			a i {
				font-size: mixins.rem(11);
				border-radius: 50%;
				color: rgba($color: variables.$white, $alpha: 0.3);
				@include mixins.transition;
			}
			&.slick-active {
				a i {
					color: variables.$white;
				}
			}
		}
	}
}
